import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'

import { salesStore, Salesman, dealerStore, storeStore } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { DealerField, StoreField } from 'adminConsole/component'
import { checkTaiwanIndentity, checkUno } from 'share/util'
// import { Debug } from 'share/component/formik'

enum Status { Loading, Ready, Failed }
interface Params { id: string }

export const SalesEdit: React.FC<RouteComponentProps<Params>> = props => {
  const id = parseInt(props.match.params.id)
  const [status, setStatus] = React.useState(Status.Loading)
  const { goBack } = props.history

  React.useEffect(() => {
    Promise.all([
      dealerStore.loadAll(),
      storeStore.loadAll(),
      salesStore.loadOne(id),
    ]).then(() => setStatus(Status.Ready))
      .catch(({ message }) => {
        toastr.error(message)
        setStatus(Status.Failed)
      })
  }, [id])

  return (
    <>
      <TitleBar title="業務員編輯" isLoading={status === Status.Loading} />
      <main className="my-3 px-3">
        {
          status === Status.Failed ?
            <button type="button" className="btn btn-light" onClick={() => goBack()}>返回</button> :
            ''
        }
        {
          status === Status.Ready ?
            <Formik
              initialValues={salesStore.salesman}
              validationSchema={Yup.object().shape({
                dealerId: Yup.number().min(1, '必填欄位！'),
                storeId: Yup.number().min(1, '必填欄位！'),
                name: Yup.string().required('必填欄位！').matches(/^\D*$/, '姓名不可以有數字！'),
                nationId: Yup.string().required('必填欄位！').uppercase().test('身分證字號檢核', '不是有效的身分證字號！', checkTaiwanIndentity),
                uno: Yup.string().required('必填欄位！').uppercase().test('保險員證號檢核', '不是有效的保險員證號', checkUno),
                email: Yup.string().required('必填欄位！').email('email格式不正確！'),
                status: Yup.boolean().required('必填欄位！'),
              })}
              onSubmit={(values: Salesman, { setStatus, setSubmitting }: FormikActions<Salesman>) => {
                setStatus()
                salesStore.save(values)
                  .then(goBack)
                  .catch(({ message }) => {
                    setStatus(message)
                    setSubmitting(false)
                  })
              }}
              component={formikProps => SalesForm(formikProps, goBack)}
            /> : ''
        }
      </main>
    </>
  )
}

const SalesForm = (formikProps: FormikProps<Salesman>, goBack: Function) => {
  const { values, errors, touched, status, isSubmitting, setFieldValue } = formikProps
  const [dealerId, setDealerId] = React.useState(values.dealerId)

  const handleDealerChange = (dealerId: number) => {
    setDealerId(dealerId)
    setFieldValue('storeId', 0)
  }
  const { dealers } = dealerStore
  const stores = storeStore.findByDealerId(dealerId)

  return (
    <Form>
      <div className="row">
        <div className="col-12 col-sm-6">
          <DealerField id="dealerId" dealers={dealers} onChange={handleDealerChange} formikProps={formikProps} />
        </div>

        <div className="col-12 col-sm-6">
          <StoreField id="storeId" stores={stores} formikProps={formikProps} />
        </div>

        <div className="form-group col-12 col-sm-6">
          <label htmlFor="name">姓名</label>
          <Field type="text" name="name" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
          <ErrorMessage name="name" component="div" className="invalid-feedback" />
        </div>

        <div className="form-group col-12 col-sm-6">
          <label htmlFor="nationId">身分證字號</label>
          <Field type="text" name="nationId" className={'form-control' + (errors.nationId && touched.nationId ? ' is-invalid' : '')} />
          <ErrorMessage name="nationId" component="div" className="invalid-feedback" />
        </div>

        <div className="form-group col-12 col-sm-6">
          <label htmlFor="uno">保險員證號</label>
          <Field type="text" name="uno" className={'form-control' + (errors.uno && touched.uno ? ' is-invalid' : '')} />
          <ErrorMessage name="uno" component="div" className="invalid-feedback" />
        </div>

        <div className="form-group col-12 col-sm-6">
          <label htmlFor="email">Email</label>
          <Field type="email" name="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
          <ErrorMessage name="email" component="div" className="invalid-feedback" />
        </div>

        <div className="form-group col-12 col-sm-6">
          <label htmlFor="status">狀態</label>
          <Field component="select" name="status" className={'form-control' + (errors.status && touched.status ? ' is-invalid' : '')}>
            <option value="true">啟用</option>
            <option value="false">停用</option>
          </Field>
          <ErrorMessage name="status" component="div" className="invalid-feedback" />
        </div>

        <div className="col-12 text-danger">{status}</div>
      </div>

      <div className="row mt-3">
        <div className="col-12 d-flex justify-content-between">
          <button type="button" className="btn btn-light" onClick={() => goBack()}>取消</button>
          <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>儲存</button>
        </div>
      </div>
      {/* <Debug /> */}
    </Form>
  )
}