import { authStore, ApplicationInfo } from "."

const baseUrl = process.env.REACT_APP_SERVICE_URL

const url = {
  login: baseUrl + '/api/pub/app/login',
  token: baseUrl + '/api/jwt/app/newCase',
  resetPwd: baseUrl + '/api/pub/app/forget',

  chgPwd: baseUrl + '/api/jwt/app/chgPwd',
  pwdRule: baseUrl + '/api/jwt/app/chgPwd/rule',
  uploadScore: baseUrl + '/api/jwt/app/questionnaire',

  quote: baseUrl + '/api/jwt/app/quotes',
  app: baseUrl + '/api/jwt/app/application',
  appPng: baseUrl + '/api/jwt/app/application/png',
  appFinal: baseUrl + '/api/jwt/app/application/final',
  payByPrime: baseUrl + '/api/jwt/app/pay',

  cfSignature: baseUrl + '/api/jwt/app/signature/consent',
  phSignature: baseUrl + '/api/jwt/app/signature/policyholder',
  smSignature: baseUrl + '/api/jwt/app/signature/sales',
  chSignature: baseUrl + '/api/jwt/app/signature/creditCardAuth',

  subject: baseUrl + '/api/jwt/app/subjects',
}

enum Method {
  Get = 'GET',
  Post = 'POST',
}

const _genReqOpts = (method: Method, body?: string) => {
  const Authorization = 'Bearer ' + authStore.currUser.token
  return {
    method,
    headers: { 'Content-Type': 'application/json', Authorization },
    body,
  }
}

const _unauthorizedHandler = (response: Response) => {
  if (response.status === 401) authStore.logout()
  return response
}

// ------------------------------------
// Following are public APIs
// ------------------------------------
const login = (acc: string, pwd: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ acc, pwd }))
  return fetch(url.login, opts)
}

const resetPwd = (acc: string, token: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ acc, token }))
  return fetch(url.resetPwd, opts)
}

// ------------------------------------
// Following are JWT APIs
// ------------------------------------
const getNewToken = () => {
  const opts = _genReqOpts(Method.Get)
  return fetch(url.token, opts).then(_unauthorizedHandler)
}

const chgPwd = (oldPwd: string, newPwd: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ oldPwd, newPwd }))
  return fetch(url.chgPwd, opts).then(_unauthorizedHandler)
}

const getPwdRule = () => {
  const opts = _genReqOpts(Method.Get)
  return fetch(url.pwdRule, opts).then(_unauthorizedHandler)
}

const uploadScore = (score: number) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ score }))
  return fetch(url.uploadScore, opts).then(_unauthorizedHandler)
}

// 取得商品清單（phone／accessories/laptop）
const getSubjects = (productCategory: string) => {
  const opts = _genReqOpts(Method.Get)
  return fetch(`${url.subject}?productCategory=${productCategory}`, opts).then(_unauthorizedHandler)
}

// 取得保險級距（phone／accessories/laptop）
const getPremiums = (productCategory: string) => {
  const opts = _genReqOpts(Method.Get)
  return fetch(`${url.quote}?productCategory=${productCategory}`, opts).then(_unauthorizedHandler)
}

const uploadApp = (application: ApplicationInfo, productCategory: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify(application))

  return fetch(`${url.app}?productCategory=${productCategory}`, opts).then(_unauthorizedHandler)
}

const uploadFinalApp = (application: ApplicationInfo) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify(application))

  return fetch(url.appFinal, opts).then(_unauthorizedHandler)
}

const getAppImg = () => {
  const opts = _genReqOpts(Method.Get)
  return fetch(url.appPng, opts).then(_unauthorizedHandler)
}

const payByPrime = (prime: string, payName: string, payRelation: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ prime, payName, payRelation }))
  return fetch(url.payByPrime, opts).then(_unauthorizedHandler)
}

const uploadCfSignature = (signature: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ signature }))
  return fetch(url.cfSignature, opts).then(_unauthorizedHandler)
}

const uploadPhSignature = (signature: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ signature }))
  return fetch(url.phSignature, opts).then(_unauthorizedHandler)
}

const uploadSmSignature = (signature: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ signature }))
  return fetch(url.smSignature, opts).then(_unauthorizedHandler)
}

// 信用卡持卡人簽名
const uploadChSignature = (signature: string) => {
  const opts = _genReqOpts(Method.Post, JSON.stringify({ signature }))
  return fetch(url.chSignature, opts).then(_unauthorizedHandler)
}

export const service = {
  login, resetPwd, getNewToken, chgPwd, getPwdRule, uploadScore, 
  getAppImg, payByPrime,
  uploadCfSignature, uploadPhSignature, uploadSmSignature, uploadChSignature,
  uploadApp, uploadFinalApp,
  getSubjects, getPremiums
}