import { service } from '.'

export interface MonthlyReportItem {
  id: number,
  dealerId: number, dealerName: string,
  storeId: number, storeName: string,
  ym: string,
  caseCount: number,
  approveCount: number,
  unreviewedCount: number
}

class MonthlyReportStore {
  private _items: MonthlyReportItem[] = []

  get items() { return this._items }

  loadAll = async (month: string) => {
    const res = await service.getMonthlyReports(month)
    if (res.status === 200) {
      this._items = await res.json()
      return Promise.resolve()
    }
    else if(res.status === 401) { }
    else return Promise.reject({ message: `無法取得每月進件資訊！ 錯誤代碼：${res.status}` })
  }
}

export const monthlyReportStore = new MonthlyReportStore()