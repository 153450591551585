import React from 'react'
import { RouteComponentProps } from 'react-router';

export const UploadFail: React.FC<RouteComponentProps> = ({ history }) => (
  <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
    <div className="d-flex align-items-center mb-4 text-danger">
      <i className="material-icons mr-2 h2">error</i>
      <h4>無法上傳，請檢查網路！</h4>
    </div>
    <button className="btn btn-secondary" onClick={history.goBack}>返回</button>
  </div>
)