import * as Yup from 'yup'

import { SubjectData } from 'adminConsole/store'

let subjectSchema = Yup.object({
  supNo: Yup.string().required(),
  model: Yup.string().required(),
  capacity: Yup.string().required(),
  mara: Yup.string().required(),
  price: Yup.number().integer().positive().required(),
  productNo: Yup.string().when('productCategory', {
    is: 'laptop',
    then: Yup.string().required()
  }),
  productCategory: Yup.string().oneOf(['phone', 'accessories', 'laptop', 'airpods']).required(),
  launchDay: Yup.date().required()
})

export const validateSubjects = (subjects: SubjectData[]) => {
  let validSubjects: SubjectData[] = []
  let invalidSubjects: SubjectData[] = []

  subjects.forEach(subject => subjectSchema.isValidSync(subject) ? validSubjects.push(subject) : invalidSubjects.push(subject))

  return { validSubjects, invalidSubjects }
}