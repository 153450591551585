import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'
import $ from 'jquery'

import { policyStore, Review } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { PdfViewer } from 'share/component/pdf'

export const getQueryParam = (name: string) => {
  const result = (new URLSearchParams(window.location.search)).get(name)
  return result ? result : ''
}

enum Status { Loading, Ready, Failed }
interface Params { id: string }

export const PolicyReviewAudit: React.FC<RouteComponentProps<Params>> = props => {
  const id = parseInt(props.match.params.id)
  const policyNo = getQueryParam('policyNo')
  // const policyNo = (props.location.search).split('?')[1]
  const [status, setStatus] = React.useState(Status.Loading)

  React.useEffect(() => {
    Promise.all([
      policyStore.loadPDF(id),
      policyStore.loadReview(id),
    ]).then(() => setStatus(Status.Ready))
      .catch(({ message }) => {
        toastr.error(message)
        setStatus(Status.Failed)
      })
  }, [id])

  const { pdf } = policyStore
  const { goBack } = props.history
  const downloadPolicyConsentZip = () => policyStore.downloadPolicyConsentZip(id, policyNo).catch(({ message }) => toastr.error(message))
  const cancelPolicy = () => {
    policyStore.saveMemo(id, '保單已取消')
      .then(() => {
        const deleteModal: any = $('#cancel_modal')
        deleteModal.modal('hide')
        goBack()
      })
      .catch(({ message }) => toastr.error(message))
  }

  return (
    <>
      <TitleBar title="保單審核" isLoading={status === Status.Loading} />
      {status === Status.Failed ? <button type="button" className="btn btn-light mr-auto" onClick={goBack}>返回</button> : ''}
      {status === Status.Ready ? <PolicyPdf id={id} pdf={pdf} goBack={goBack} downloadPolicyConsentZip={downloadPolicyConsentZip} cancelPolicy={cancelPolicy} /> : ''}
    </>
  )
}

interface Props {
  id: number
  pdf: any
  goBack: () => void
  downloadPolicyConsentZip: () => void
  cancelPolicy: () => void
}

const PolicyPdf: React.FC<Props> = props => {
  const { id, pdf, goBack } = props
  return (
    <>
      <section className="row">
        <div className="col-12" id="pdf_container">
          <PdfViewer data={pdf} pageNo={1} />
        </div>
      </section>
      <Formik
        initialValues={policyStore.review}
        validationSchema={Yup.object().shape({
          memo: Yup.string(),
          approve: Yup.boolean()
        })}
        onSubmit={(values: Review, { setStatus, setSubmitting }: FormikActions<Review>) => {
          setStatus()
          policyStore.approvePolicy(id, values.memo)
            .then(() => {
              const modal: any = $('#approve_modal')
              modal.modal('hide')
              goBack()
            })
            .catch(({ message }) => {
              setStatus(message)
              setSubmitting(false)
            })
        }}
        component={formikProps => PolicyForm(formikProps, props)}
      />
    </>
  )
}

const PolicyForm = ({ values, errors, status, touched, isSubmitting, setStatus, setSubmitting }: FormikProps<Review>, { id, goBack, downloadPolicyConsentZip, cancelPolicy }: Props) => {
  const haveStCcAuth = getQueryParam('haveStCcAuth') === 'true'

  const saveMemo = () => {
    setStatus()
    setSubmitting(true)
    policyStore.saveMemo(id, values.memo)
      .then(() => {
        setStatus('已儲存')
        setSubmitting(false)
      })
      .catch(({ message }) => setStatus(message))
  }

  return (
    <>
      <Form>
        <div className="row my-3">
          <div className="form-group col-12">
            <label htmlFor="memo">備註</label>
            <Field component="textarea" name="memo" className={'form-control' + (errors.memo && touched.memo ? ' is-invalid' : '')} rows={3} />
            <ErrorMessage name="memo" component="div" className="invalid-feedback" />
          </div>
          <div className="col-12 text-danger">{status}&nbsp;</div>
        </div>

        {
          !haveStCcAuth ?
            (<div className="row text-danger">
            <i className="material-icons mr-2" style={{ fontSize: '20px', lineHeight: '1.2' }}>error_outline</i>
            <p className="font-weight-bold">缺乏信用卡授權書簽名，無法核准！</p>
             </div>) : null
        } 

        <div className="row py-4">
          <div className="col-12 d-flex">
            <button type="button" className="btn btn-light mr-auto" onClick={goBack}>返回</button>
            <button type="button" className="btn btn-danger mr-4" data-toggle="modal" data-target="#cancel_modal">取消保單</button>
            <button type="button" className="btn btn-light mr-4" onClick={downloadPolicyConsentZip}>下載</button>
            <button type="button" className="btn btn-primary mr-4" onClick={saveMemo} disabled={isSubmitting || values.memo === '保單已取消'}>儲存</button>

            {
              values.approve ?
                <button type="button" className="btn btn-light" disabled><i className="material-icons align-bottom">done</i></button> :
                <button type="button" className="btn btn-secondary" disabled={isSubmitting || values.memo === '保單已取消' || !haveStCcAuth} data-toggle="modal" data-target="#approve_modal">核准</button>
            }

          </div>
        </div>

        <div className="modal fade" id="cancel_modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">訊息</h5>
                <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
              </div>
              <div className="modal-body">
                <div>確定取消此筆保單嗎？</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">取消</button>
                <button type="button" className="btn btn-primary" onClick={cancelPolicy} >確定</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="approve_modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">訊息</h5>
              </div>
              <div className="modal-body">
                <div>{isSubmitting ? '保單審核中' : '確定核准此筆保單嗎？'}</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled={isSubmitting}>取消</button>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  {isSubmitting ? <div className="spinner-border spinner-border-sm" /> : '確定'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}