import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'

import { userStore, User } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
// import { Debug } from 'share/component/formik'

enum Status { Loading, Ready, Failed }
interface Params { id: string }

export const UserEdit: React.FC<RouteComponentProps<Params>> = props => {
  const id = parseInt(props.match.params.id)
  const [status, setStatus] = React.useState(Status.Loading)
  const { goBack } = props.history

  React.useEffect(() => {
    userStore.loadOne(id)
      .then(() => setStatus(Status.Ready))
      .catch(({ message }) => {
        toastr.error(message)
        setStatus(Status.Failed)
      })
  }, [id])

  return (
    <>
      <TitleBar title="審核員管理" isLoading={status === Status.Loading} />
      <main className="my-3 px-3">
        {
          status === Status.Failed ?
            <button type="button" className="btn btn-light" onClick={() => goBack()}>返回</button> :
            ''
        }
        {
          status === Status.Ready ?
            <Formik
              initialValues={userStore.user}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('必填欄位！'),
                acc: Yup.string().required('必填欄位！'),
                status: Yup.boolean().required('必填欄位！'),
              })}
              onSubmit={(values: User, { setStatus, setSubmitting }: FormikActions<User>) => {
                setStatus()
                userStore.save(values)
                  .then(goBack)
                  .catch(({ message }) => {
                    setStatus(message)
                    setSubmitting(false)
                  })
              }}
              component={formikProps => UserForm(formikProps, goBack)}
            /> : ''
        }
      </main>
    </>
  )
}


const UserForm = ({ errors, status, touched, isSubmitting }: FormikProps<User>, goBack: Function) => (
  <Form>
    <div className="row">
      <div className="form-group col-12 col-sm-6">
        <label htmlFor="name">姓名</label>
        <Field type="text" name="name" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
        <ErrorMessage name="name" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="acc">Email</label>
        <Field type="email" name="acc" className={'form-control' + (errors.acc && touched.acc ? ' is-invalid' : '')} />
        <ErrorMessage name="acc" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="status">狀態</label>
        <Field component="select" name="status" className={'form-control' + (errors.status && touched.status ? ' is-invalid' : '')}>
          <option value="true">啟用</option>
          <option value="false">停用</option>
        </Field>
        <ErrorMessage name="status" component="div" className="invalid-feedback" />
      </div>

      <div className="col-12 text-danger">{status}</div>
    </div>

    <div className="row mt-3">
      <div className="col-12 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={() => goBack()}>取消</button>
        <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>儲存</button>
      </div>
    </div>
    {/* <Debug /> */}
  </Form>
)