import React, { FC } from 'react'

type Props = { 
  handleReset: () => void
}

export const ImportSuccess: FC<Props> = ({ handleReset }) => {
  return (
    <div className="mx-auto">
      <div className="text-center" style={{ marginTop: '80px' }}>
        <h4>匯入成功</h4>
        <div className="mt-4">
          <button className="btn btn-link" onClick={handleReset}>返回</button>
        </div>
      </div>
    </div>
  )
}