import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import toastr from 'toastr'

import { SignatureCanvas, SignaturePad } from 'mobileApp/common/component/signature'
import { mdiStore, deviceStore, authStore } from 'mobileApp/store'
import { MdiLayout } from 'mobileApp/mdi/layout'

interface Props {
  title: string
  signature: any
  uploadFn: Function
  goBack?: boolean
}
const MobileSignaturePad: React.FC<Props> = observer((props) =>
  deviceStore.isPortrait ? <PortraitView {...props} /> : <LandscapeView {...props} />
)

const LandscapeView: React.FC<Props> = ({ title, signature, uploadFn, goBack = true }) => {
  const [disabled, setDisabled] = useState(false)
  const canvasRef = useRef<SignatureCanvas>(null)
  const padHeight = deviceStore.height - (58 + 49) + 'px'
  useEffect(() => {
    canvasRef.current!.fromDataURL(signature)
  }, [signature])

  const handleClear = () => canvasRef.current!.clear()
  const handleNext = () => {
    setDisabled(true)
    uploadFn(canvasRef.current!.toDataURL()).then((statusCode: any) => {
      if (statusCode === 200) mdiStore.navi.goNext()
      else {
        const errMsg = statusCode === 400 ? '簽名不可空白！' : `上傳簽名檔失敗！ 錯誤碼：${statusCode}`
        toastr.error(errMsg)
        setDisabled(false)
      }
    })
  }

  return (
    <>
      <SignaturePad title={title} canvasRef={canvasRef} height={padHeight} />

      <section className="my-2 d-flex justify-content-between">
        <div>
          {goBack ? (
            <button type="button" className="btn btn-light ml-3" onClick={mdiStore.navi.goBack}>
              上一步
            </button>
          ) : null}
          <button type="button" className="btn btn-light ml-3" onClick={handleClear}>
            清除
          </button>
        </div>
        <button type="button" className="btn btn-secondary mr-3" disabled={disabled} onClick={handleNext}>
          下一步
        </button>
      </section>
    </>
  )
}

const PortraitView: React.FC<Props> = ({ title }) => {
  return (
    <MdiLayout title={title} step={0}>
      <div className="text-center">
        <i className="material-icons display-4 mt-5">screen_rotation</i>
        <p className="my-5">請轉為「橫向」進行簽名作業</p>
        <section className="mb-3 mb-4 d-flex justify-content-between">
          <button type="button" className="btn btn-light" onClick={mdiStore.navi.goBack}>
            上一步
          </button>
          <div>&nbsp;</div>
        </section>
      </div>
    </MdiLayout>
  )
}

export const S21CfSignaturePad = () => (
  <MobileSignaturePad title="要保人簽署同意書" signature={mdiStore.cfSignature} uploadFn={mdiStore.uploadCfSignature} />
)
export const S22PhSignaturePad = () => (
  <MobileSignaturePad title="要保人簽名" signature={mdiStore.phSignature} uploadFn={mdiStore.uploadPhSignature} />
)
export const S23SmSignaturePad = () => (
  <MobileSignaturePad
    title={`業務員簽名（業務員姓名：${authStore.currUser.name}）`}
    signature={mdiStore.smSignature}
    uploadFn={mdiStore.uploadSmSignature}
  />
)
export const S24ChSignaturePad = () => (
  <MobileSignaturePad
    title="信用卡持卡人簽名"
    signature={mdiStore.chSignature}
    uploadFn={mdiStore.uploadChSignature}
    goBack={false}
  />
)
