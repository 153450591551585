import React from 'react'
import { RouteComponentProps } from 'react-router'
import toastr from 'toastr'

import { dealerStore } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { SearchBox, AddButton, ReloadButton } from 'adminConsole/component'
import { Path } from 'adminConsole'

interface Props extends RouteComponentProps {
  keyword: string
  keywordOnChange: Function
}

export const DealerList: React.FC<Props> = ({ history, keyword, keywordOnChange }) => {
  const [isLoading, setIsLoading] = React.useState(true)

  const reloadAll = () => {
    setIsLoading(true)
    dealerStore.loadAll()
      .then()
      .catch(({ message }) => toastr.error(message))
      .finally(() => setIsLoading(false))
  }

  React.useEffect(reloadAll, [])

  const dealers = dealerStore.dealers.filter(dealer => dealer.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1)

  const handleReloadClick = reloadAll
  const handleDealerClick = (id: number) => history.push(`${Path.DealerList}/${id}/store`)
  const handleDealerEditClick = (id: number) => history.push(`${Path.DealerList}/${id}`)

  return (
    <>
      <TitleBar title="經銷商管理" isLoading={isLoading} />

      <main className="my-3 px-3">
        <section className="row mb-4">
          <div className="col-4">
            <SearchBox value={keyword} onChange={keywordOnChange} />
          </div>

          <div className="col-8 d-flex flex-row-reverse align-items-center">
            <ReloadButton disabled={isLoading} onClick={handleReloadClick} />
            <AddButton to={`${Path.DealerList}/0`} className="mr-3" />
          </div>
        </section>

        <section>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">經銷商</th>
                <th scope="col">維修中心</th>
                <th scope="col">狀態</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {dealers.map(dealer => (
                <tr key={dealer.id} onClick={() => handleDealerClick(dealer.id)}>
                  <td>{dealer.name}</td>
                  <td>{dealer.maintainCenter}</td>
                  <td>{dealer.status ? '啟用' : '停用'}</td>
                  <td onClick={(e) => {
                    e.stopPropagation()
                    handleDealerEditClick(dealer.id)
                  }}>
                    <i className="material-icons text-primary">edit</i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </>
  )
}