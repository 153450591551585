import React from 'react'
import { RouteComponentProps } from 'react-router'
import toastr from 'toastr'

import { dealerStore, storeStore } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { SearchBox, AddButton, ReloadButton } from 'adminConsole/component'
import { Path } from 'adminConsole'

interface Params { dealerId: string }

interface Props extends RouteComponentProps<Params> {
  keyword: string
  keywordOnChange: Function
}

export const StoreList: React.FC<Props> = ({ match, history, keyword, keywordOnChange }) => {
  const dealerId = parseInt(match.params.dealerId)
  const [isLoading, setIsLoading] = React.useState(true)

  const reloadAll = () => {
    setIsLoading(true)
    Promise.all([
      dealerStore.loadOne(dealerId),
      storeStore.loadAll()
    ]).then()
      .catch(({ message }) => toastr.error(message))
      .finally(() => setIsLoading(false))
  }

  React.useEffect(reloadAll, [dealerId])

  const { dealer } = dealerStore
  const stores = storeStore.stores.filter(store =>
    store.dealerId === dealer.id &&
    store.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
  )

  const handleReloadClick = reloadAll
  const handleStoreClick = (storeId: number) => history.push(`${Path.DealerList}/${dealerId}/store/${storeId}`)
  const { goBack } = history

  return (
    <>
      <TitleBar title={`${dealer.name} 門市清單`} isLoading={isLoading} />

      <main className="my-3 px-3">
        <section className="row mb-4">
          <div className="col-1">
            {/* todo: 記憶查詢 dealer*/}
            <button className="btn btn-light p-1" onClick={() => goBack()}>
              <i className="material-icons h4 m-0 align-middle text-muted">keyboard_arrow_left</i>
            </button>
          </div>
          <div className="col-4">
            <SearchBox value={keyword} onChange={keywordOnChange} />
          </div>

          <div className="col-7 d-flex flex-row-reverse align-items-center">
            <ReloadButton disabled={isLoading} onClick={handleReloadClick} />
            <AddButton to={`${Path.DealerList}/${dealerId}/store/0`} className="mr-3" />
          </div>
        </section>

        <section>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">門市名稱</th>
                <th scope="col">門市代碼</th>
                <th scope="col">狀態</th>
              </tr>
            </thead>
            <tbody>
              {stores.map(store => (
                <tr key={store.id} onClick={() => handleStoreClick(store.id)}>
                  <td>{store.name}</td>
                  <td>{store.code}</td>
                  <td>{store.status ? '啟用' : '停用'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </>
  )
}