import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { authStore } from 'mobileApp/store'

interface HeaderProps extends RouteComponentProps {
  title: string
}

const Header = (props: HeaderProps) => {
  const { title } = props
  const { name } = authStore.currUser
  return (
    <TopHeader className="d-flex w-100 align-items-center justify-content-between" style={{ backgroundColor: '#6c757d' }}>
      <Title className="text-white">{title}</Title>
      <div className="dropdown mr-2">
        <button className="btn p-0 dropdown-toggle" data-toggle="dropdown">
          <i className="material-icons text-white align-middle">more_vert</i>
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="dropdown-item d-flex align-items-center">
            <span className="text-muted">{name}</span>
            <i className="material-icons text-muted ml-2">account_circle</i>
          </div>

          <Link to="/system/passwordChange" className="dropdown-item text-primary">
            變更密碼
          </Link>

          {/* eslint-disable-next-line */}
          <a className="dropdown-item text-primary" onClick={authStore.logout}>登出</a>

          {/* 行動投保App 相關資訊 */}
          <div className="dropdown-divider"></div>
          <div className="dropdown-item d-flex align-items-center">
            <span className="text-muted">行動投保</span>
            <i className="material-icons text-muted ml-2">phone_iphone</i>
          </div>
          <div className="dropdown-item text-muted">版本 {process.env.REACT_APP_VERSION}</div>
        </div>
      </div>
    </TopHeader>
  )
}

export default withRouter(Header)

const Title = styled.h5`
  padding-left: 24px;
  margin: 0;
`

const TopHeader = styled.header`
  position: sticky;
  top: 0px;
  height: 52px;
  z-index: 100;

  .dropdown-toggle::after {
    border: none;
  }
`