import { service } from '.'

export interface Dealer { id: number, name: string, status: boolean, no: string, maintainCenter: string }
export const emptyDealer = { id: 0, name: '', status: true, no: '', maintainCenter: '' }

class DealerStore {
  private _dealers: Dealer[] = []
  private _dealer: Dealer = { ...emptyDealer }
  private _dealerNoOptions = []

  get dealers() { return this._dealers }
  get dealer() { return this._dealer }
  get dealerNoOptions() { return this._dealerNoOptions }

  findByValid = () => this._dealers.filter(dealer => dealer.status === true)

  loadAll = async () => {
    const res = await service.getDealers()
    if (res.status === 200) {
      this._dealers = await res.json()
      return Promise.resolve()
    }
    else if (res.status === 401) { }
    else return Promise.reject({ message: `無法取得經銷商資訊！ 錯誤代碼：${res.status}` })
  }

  loadOne = async (id: number) => {
    if (id === 0) {
      this._dealer = { ...emptyDealer }
      return Promise.resolve()
    }

    const res = await service.getDealer(id)
    if (res.status === 200) {
      this._dealer = await res.json()
      return Promise.resolve()
    }

    else return Promise.reject({ message: `無法取得經銷商資訊！ 錯誤代碼：${res.status}` })
  }

  loadDealerNoOptions = async () => {
    const res = await service.getDealerNoOptions()
    if (res.status === 200) {
      this._dealerNoOptions = await res.json()
      return Promise.resolve()
    }
    else return Promise.reject({ message: `無法取得經銷商代碼！ 錯誤代碼：${res.status}` })
  }

  save = async (dealer: Dealer) => {
    const res = await (dealer.id === 0 ?
      service.addDealer(dealer.name, dealer.no, dealer.maintainCenter) :
      service.updateDealer(dealer.id, dealer.name, dealer.status, dealer.no, dealer.maintainCenter))

    if (res.status === 200) return Promise.resolve()
    else if (res.status === 400) return Promise.reject({ message: '無法儲存！ 請檢查「名稱」是否重複？' })
    else return Promise.reject({ message: `無法儲存！　錯誤代碼：${res.status}` })
  }
}

export const dealerStore = new DealerStore()