import React from 'react'

interface Props {
  id: string,
  title: string,
  contentFn: Function
}

export const Modal: React.FC<Props> = ({ id, title, contentFn }) => (
  <div className="modal fade" id={id} tabIndex={-1} role="dialog">
    <div className="modal-dialog modal-dialog-scrollable" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
        </div>
        <div className="modal-body">
          {contentFn()}
        </div>
      </div>
    </div>
  </div>
)