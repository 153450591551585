import districtJson from "./district.json";

class Store {
  private _cities: string[]

  constructor() { this._cities = Object.keys(districtJson) }

  get cities() { return this._cities }

  getDistrictsByCity = (city: string) => {
    const districts = (districtJson as any)[city]
    return districts ? Object.keys(districts) : []
  }
}

export const districtStore = new Store()