import React from 'react'
import styled from 'styled-components'

import { AdminHeader, SideNavbar } from '.'

interface Props { title: string }

const AdminLayout: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 text-right">
            <SideNavbar />
          </div>

          <div className="col-9 p-0">
            <main className="mx-auto mt-4">
              <TitleBar className="text-white" style={{ backgroundColor: '#6c757d' }}>{title}</TitleBar>
              <ContentSection>{children}</ContentSection>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminLayout

const TitleBar = styled.h5`
  padding: 12px 28px;
  margin: 20px 0px;
`

const ContentSection = styled.section`
  padding: 0px 28px;
`