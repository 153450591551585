import React from 'react'

import { wbeStore } from 'mobileApp/store'

enum Code { Loading, Failed }

export const S00CaseInit = () => {
  const [status, setStatus] = React.useState({ code: Code.Loading, msg: '' })
  React.useEffect(() => {
    wbeStore.initCase()
      .then(() => { wbeStore.navi.goNext() })
      .catch(msg => setStatus({ code: Code.Failed, msg }))
  }, [])

  return (
    <>
      {status.code !== Code.Loading ? <FailedMsg msg={status.msg} /> : <Loading />}
    </>
  )
}

const Loading: React.FC = () => (
  <div className="row">
    <div className="col-12 text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </div>
  </div>
)

const FailedMsg: React.FC<{ msg: String }> = ({ msg }) => (
  <div className="row"><div className="col-12 text-danger">{msg}</div></div>
)