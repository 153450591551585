import React from 'react'
import SignaturePad, { IPointGroup } from 'signature_pad'

export class SignatureCanvas extends React.Component {
  private _canvas = React.createRef<HTMLCanvasElement>()
  private _sigPad: SignaturePad | null = null

  componentDidMount = () => {
    this._sigPad = new SignaturePad(this._canvas.current!)
    this._canvas.current!.addEventListener('touchmove', this._preventTouchMove)
    window.addEventListener('resize', this._resizeCanvas)
    this._resizeCanvas()
  }

  componentWillUnmount = () => {
    this._canvas.current!.removeEventListener('touchmove', this._preventTouchMove)
    window.removeEventListener('resize', this._resizeCanvas)
  }

  render = () => (<canvas ref={this._canvas} style={{ width: '100%', height: '100%' }} />)

  _preventTouchMove = (e: Event) => e.preventDefault()

  _resizeCanvas = () => {
    const canvas = this._canvas.current!
    const ratio = Math.max(window.devicePixelRatio || 1, 1)

    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d')!.scale(ratio, ratio)

    this.clear()
  }

  undo = () => {
    const data = this._sigPad!.toData()
    data.pop()
    this._sigPad!.fromData(data)
  }

  /**
   * SignaturePad wrapper functions
   */

  toDataURL = (type = 'image/png', encoderOptions?: number) => this._sigPad!.toDataURL(type, encoderOptions)
  fromDataURL = (
    dataUrl: string,
    options: { ratio?: number; width?: number; height?: number } = {},
    callback?: (error?: ErrorEvent) => void,
  ) => this._sigPad!.fromDataURL(dataUrl, options, callback)

  toData = () => this._sigPad!.toData()
  fromData = (pointGroups: IPointGroup[]) => this._sigPad!.fromData(pointGroups)

  clear = () => this._sigPad!.clear()
  isEmpty = () => this._sigPad!.isEmpty()
}