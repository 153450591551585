import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { BasicLayout } from 'mobileApp/common/layout'
import { questionnaireStore } from 'mobileApp/store'
import { Path } from '.'
import { Path as SystemPath } from 'mobileApp/system/page'
// import { Debug } from "mobileApp/common/component/formik"

export const Exam: React.FC<RouteComponentProps> = (props) => {
  return (
    <Formik
      initialValues={{ q1: '', q2: '', q3: '', q4: '', q5: '', q6: '', q7: '', q8: '', q9: '', q10: '' }}
      onSubmit={(myAnswers, { setStatus, setSubmitting }) => {
        setStatus()
        questionnaireStore.markPaper(myAnswers)
          .then(() => props.history.push(Path.Score))
          .catch(() => props.history.push(SystemPath.UploadFail))
        setSubmitting(false)
      }}
      validationSchema={Yup.object().shape({
        q1: Yup.string().required('請作答！'),
        q2: Yup.string().required('請作答！'),
        q3: Yup.string().required('請作答！'),
        q4: Yup.string().required('請作答！'),
        q5: Yup.string().required('請作答！'),
        q6: Yup.string().required('請作答！'),
        q7: Yup.string().required('請作答！'),
        q8: Yup.string().required('請作答！'),
        q9: Yup.string().required('請作答！'),
        q10: Yup.string().required('請作答！'),
      })}
      render={({ values, isSubmitting, errors, touched }) => (
        <BasicLayout title="業務員測驗">
          <Form>
            <Question name="q1" no="1" content="使用行動投保與客戶簽訂要保書時，須使用下列何種裝置設備？"
              values={values} touched={touched} errors={errors}
              options={[
                '公司電腦',
                '筆記型電腦',
                '智慧型手機或平板電腦',
                '傳真機',
              ]}
            />

            <Question name="q2" no="2" content="行動投保的登入及使用，下列何者正確？"
              values={values} touched={touched} errors={errors}
              options={[
                '完成行動投保資格考試後，才能使用該功能。',
                '不需帳號及密碼，只要是公司業務員都可使用。',
                '與進入個人電子郵件信箱的帳號及密碼相同。',
                '取得行動投保使用資格後，以簡訊通知帳號及密碼。',
              ]}
            />

            <Question name="q3" no="3" content="進行行動投保時，系統如何發送簽名頁面的連結？"
              values={values} touched={touched} errors={errors}
              options={[
                'Email',
                '傳真',
                '直接簽名在行動裝置螢幕上',
                '紙本',
              ]}
            />

            <Question name="q4" no="4" content="「個人資料保護法告知說明書」、「行動投保確認同意書」需由何人閱讀確認點選？"
              values={values} touched={touched} errors={errors}
              options={[
                '要保人',
                '業務員',
                '被保險人',
                '受益人',
              ]}
            />

            <Question name="q5" no="5" content="「要保簽名頁面」的連結，須於何時簽名完成？"
              values={values} touched={touched} errors={errors}
              options={[
                '3日',
                '2日',
                '當下',
                '一週內',
              ]}
            />

            <Question name="q6" no="6" content="申辦行動裝置保險的資格為自然人且年齡不需滿 20 歲？"
              values={values} touched={touched} errors={errors}
              options={[
                '是',
                '否',
              ]}
            />

            <Question name="q7" no="7" content="要保人若為法人，應如何比照為自然人之後續出單流程辦理？"
              values={values} touched={touched} errors={errors}
              options={[
                '直接請負責人簽名',
                '請客戶提供營業登記',
                '不接受法人投保',
                '可以先行出單後再補用印',
              ]}
            />

            <Question name="q8" no="8" content="在公司推動行動投保後，業務同仁出單是否還需要繳交資料給窗口？"
              values={values} touched={touched} errors={errors}
              options={[
                '要，須繳交行動投保確認同意書及其他要保書文件。',
                '要，須提供行動投保確認資料影本。',
                '不需要，推行行動投保後，業務員僅需將投保申請送出即可。',
                '不需要，等公司通知需要補件資料才請窗口協助。',
              ]}
            />

            <Question name="q9" no="9" content="在行動投保填寫要保資料時，下列何者正確？"
              values={values} touched={touched} errors={errors}
              options={[
                '不需要到客戶那裡就可以完成。',
                '由客戶瀏覽並確認要保相關資料輸入內容後，於行動裝置上親自簽名。',
                '業務員可以幫忙簽名。',
                '可以等出單後再補簽。',
              ]}
            />

            <Question name="q10" no="10" content="爲確保客戶資料安全，關於行動投保裝置的設定，下列何者為非？"
              values={values} touched={touched} errors={errors}
              options={[
                '可以給客戶登入的帳號及密碼，讓他自己填寫。',
                '應妥善保管印出的資料給窗口。',
                '為確保作業安全，簡訊傳送的手機電話僅限業務員本人。',
                '不安全的 WIFI 分享環境，盡量不用。',
              ]}
            />

            {/* 9/3 finished */}

            <div className="w-100 text-right text-danger">{Object.keys(errors).length ? `尚有 ${Object.keys(errors).length} 題目未作答!!` : ''}</div>
            <section className="action">
              <button type="submit" disabled={isSubmitting} className="btn btn-secondary btn-next ml-auto">送出</button>
            </section>
          </Form>
          {/* <Debug /> */}
        </BasicLayout>
      )}
    />
  )
}

interface Props {
  no: string, name: string, content: string, options: string[],
  values: any, touched: any, errors: any,
}

const Question: React.FC<Props> = ({ no, name, content, options, values, touched, errors }) => (
  <section className={'row mb-4' + (errors[name] && touched[name] ? ' text-danger' : ' text-muted')}>
    <div className="col-1 pr-0 text-right">({no})</div>
    <div className="col-11">
      <div className="mb-1">{content}</div>
      <ErrorMessage name={name} component="div" className="mb-1" />
      <div>
        {options.map((option, index) => {
          const no: string = index + 1 + ''
          return (
            <div key={index} className="custom-control custom-radio mb-2">
              <Field type="radio" className="custom-control-input"
                name={name} id={`${name}_${no}`} value={no} checked={values[name] === no} />
              <label className="custom-control-label" htmlFor={`${name}_${no}`}>{option}</label>
            </div>
          )
        })}
      </div>
    </div>
  </section>
)