import React from 'react'
import { RouteProps, RouteComponentProps } from 'react-router'
import { Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react';

import { authStore } from 'mobileApp/store'
import { Path as SystemPath } from 'mobileApp/system/page'
import { Path as QuestionnairePath } from 'mobileApp/questionnaire/page'

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
}

@observer
class ProtectedRoute extends React.Component<ProtectedRouteProps> {
  render() {
    const { component: Component, ...rest } = this.props
    const isLogin = authStore.isLogin  // 讓mobx偵測用 ＆ 注意 render() 不可以是箭頭函式
    return (
      <Route {...rest} render={props => {
        const { path } = rest
        if (!isLogin) return <Redirect to={{ pathname: SystemPath.Login, state: { from: props.location } }} />
        else if (path === QuestionnairePath.Exam || path === QuestionnairePath.Score) return <Component {...props} />
        else if (!authStore.currUser.questionnaire) return <Redirect to={{ pathname: QuestionnairePath.Exam, state: { from: props.location } }} />
        else return <Component {...props} />
      }} />
    )
  }
}

export default ProtectedRoute