import React from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'

import { authStore } from 'mobileApp/store'

interface AuthRequest { account: string, password: string, isPersistAccount?: any }

export const Login: React.FC<RouteComponentProps> = props => {
  if (authStore.isLogin) props.history.push('/');
  return <LoginFormik {...props} />
}

const LoginFormik: React.FC<RouteComponentProps> = props => {
  const account = authStore.userAccount

  return (
    <Formik
      initialValues={{ account, password: '', isPersistAccount: false }}
      validationSchema={Yup.object().shape({
        account: Yup.string().email('請輸入 Email！').required('必填欄位！'),
        password: Yup.string().required('必填欄位！'),
      })}
      onSubmit={({ account, password, isPersistAccount }: AuthRequest, { setStatus, setSubmitting }: FormikActions<AuthRequest>) => {
        setStatus()
        authStore.logins(account, password, isPersistAccount)
          .then(() => props.history.push('/system/home'))
          .catch(({ message }) => {
            setStatus(message)
            setSubmitting(false)
          })
      }}
      component={LoginForm}
    />
  )
}

const LoginForm = ({ errors, status, touched, isSubmitting }: FormikProps<AuthRequest>) => {

  return (
    <div className="container">
      <div className="row vh-100 align-items-center justify-content-center align-content-center">
        <section className="col-10 col-md-8">
          <section className="d-flex justify-content-center">
            <h2 className="text-center font-weight-bold mr-2">安盛保代</h2>
            <h2 className="text-secondary">行動投保</h2>
          </section>

          <section className="mt-5 d-flex justify-content-center">
            <Form style={{ width: '360px' }}>
              <div className="form-group">
                <label htmlFor="account">帳號</label>
                <Field name="account" type="text" placeholder="請輸入登入 Email" className={'form-control' + (errors.account && touched.account ? ' is-invalid' : '')} />
                <ErrorMessage name="account" component="div" className="invalid-feedback" />
              </div>

              <div className="form-group">
                <label htmlFor="password">密碼</label>
                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </div>

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <Field type="checkbox" name="isPersistAccount" id="isPersistAccount" className={'custom-control-input'} />
                  <label htmlFor="isPersistAccount" className="custom-control-label">記住登入帳號</label>
                </div>
              </div>

              {status && <div className="text-danger">{status}</div>}

              <button type="submit" className="btn btn-secondary w-100 mt-4" disabled={isSubmitting}>登入</button>
              <Link to="/system/passwordReset" className="password_reset mt-4">忘記密碼？</Link>
            </Form>
          </section>
        </section>
      </div>
    </div>
  )
}