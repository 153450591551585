import React from 'react'
import { NavLink } from 'react-router-dom'

import { authStore, Role } from 'adminConsole/store'
import { Path } from 'adminConsole'

export const SideNavbar = () => {
  const { myRole } = authStore
  return (
    <nav className="side_navbar_admin">
      {[Role.Admin].includes(myRole) ? <AdminLinks /> : null}
      {[Role.SenYoung, Role.Chubb].includes(myRole) ? <UserLinks /> : null}
    </nav>
  )
}

// Links for Admin
const AdminLinks = () => (
  <>
    <NavLink to={Path.PolicySearchList}>
      <i className="material-icons">find_in_page</i>
      <span>保單查詢</span>
    </NavLink>

    <NavLink to={Path.SalesList}>
      <i className="material-icons">people_outline</i>
      <span>業務員管理</span>
    </NavLink>

    <NavLink to={Path.UserList}>
      <i className="material-icons">person_outline</i>
      <span>審核員管理</span>
    </NavLink>

    <NavLink to={Path.DealerList}>
      <i className="material-icons">business</i>
      <span>經銷商管理</span>
    </NavLink>

    <NavLink to={Path.Export}>
      <i className="material-icons">cloud_download</i>
      <span>資料匯出</span>
    </NavLink>

    <NavLink to={Path.Import}>
      <i className="material-icons">cloud_upload</i>
      <span>資料匯入</span>
    </NavLink>
  </>
)

// Links for 審核員
const UserLinks = () => (
  <>
    <NavLink to={Path.PolicyReviewList}>
      <i className="material-icons">find_in_page</i>
      <span>保單審核</span>
    </NavLink>

    <NavLink to={Path.ReportDaily}>
      <i className="material-icons">library_books</i>
      <span>每日進件</span>
    </NavLink>

    <NavLink to={Path.ReportMonthly}>
      <i className="material-icons">library_books</i>
      <span>每月進件</span>
    </NavLink>
  </>
)