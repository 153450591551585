import React, { FC, useState, MouseEventHandler } from 'react'

import { excelImportStore, PriceBandData } from 'adminConsole/store'
import { DragAndDropField } from 'adminConsole/component'
import { ImportSuccess, ImportFailed, InvalidPriceBandData, parseExcelToJSON, validatePriceBands, Notice } from '.'

enum Status { Idle, Validating, Validated, Importing, ImportSuccess, ImportFailed }
type ValidatedPriceBands = { validPriceBands: PriceBandData[], invalidPriceBands: PriceBandData[] }
type ValidatedProps = { priceBands: ValidatedPriceBands, handleSubmitPriceBands: MouseEventHandler, handleReset: () => void }

const priceBandNotices = [
  '資料匯入後會全部覆蓋原本的資料，請確認後再執行匯入。',
  '未通過檢核的資料如果未修正再次匯入，將排除在匯入資料清單。'
]

export const PriceBand = () => {
  const [status, setStatus] = useState<Status>(Status.Idle)
  const [priceBands, setPriceBands] = useState<ValidatedPriceBands | undefined>(undefined)
  const [errMsg, setErrMsg] = useState<string>('')

  const handleFileToData = async (file: File) => {
    if (!file) return 
    const priceBandList = await parseExcelToJSON(file)
    const validatedPriceBands = validatePriceBands(priceBandList)

    setPriceBands(validatedPriceBands)
    setStatus(Status.Validated)
  }

  const handleSubmitPriceBands = () => {
    const { validPriceBands } = priceBands!

    excelImportStore.importPriceBands(validPriceBands)
      .then(() => setStatus(Status.ImportSuccess))
      .catch(({ message }) => { 
        setErrMsg(message)
        setStatus(Status.ImportFailed)
      })
  }

  const handleReset = () => {
    setPriceBands(undefined)
    setStatus(Status.Idle)
  }

  return (
    <section className="mx-3" style={{ marginTop: '36px' }}>
      {
        status === Status.Idle ? <Idle handleFileToData={handleFileToData} /> :
        status === Status.Validated ? <Validated priceBands={priceBands!} handleSubmitPriceBands={handleSubmitPriceBands} handleReset={handleReset} /> :
        status === Status.ImportSuccess ? <ImportSuccess handleReset={handleReset} /> :
        status === Status.ImportFailed ? <ImportFailed handleReset={handleReset} errMsg={errMsg} /> : null
      }
    </section>
  )
}

type IdleProps = { handleFileToData: Function }
const Idle: FC<IdleProps> = ({ handleFileToData }) => {
  return (
    <div className="w-100 mx-auto mt-4">
      <DragAndDropField id='import_priceBands' handleFileToData={handleFileToData} />
    </div>
  )
}

const Validated: FC<ValidatedProps> = ({ priceBands, handleSubmitPriceBands, handleReset }) => {
  const { validPriceBands, invalidPriceBands } = priceBands
  const hasInvalid = invalidPriceBands.length > 0

  return (
    <section>
      <h6 className="mb-4">檢核結果</h6>
      <div className="d-flex">
        <div className="d-flex align-items-center col-3">
          <i className="material-icons mb-3 mr-2 text-primary" style={{ fontSize: '20px' }}>task_alt</i>
          <p>通過檢核：{validPriceBands.length} 筆</p> 
        </div>
        <div className="d-flex align-items-center col-3">
          <i className="material-icons mb-3 mr-2 text-secondary" style={{ fontSize: '20px' }}>help_outline</i>
          <p>未通過檢核：{invalidPriceBands.length} 筆</p>
        </div>
      </div>

      { hasInvalid && <InvalidPriceBandData  priceBands={invalidPriceBands} /> }

      <Notice notices={priceBandNotices} />

      <div className="mt-4 text-right mx-4">
        <button type="button" onClick={handleReset} className="btn btn-link text-muted mr-3">重新選擇檔案</button>
        <button type="button" onClick={handleSubmitPriceBands} className="btn btn-primary">確定匯入</button>
      </div>
    </section>
  )
}