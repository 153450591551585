import { service, authStore, premiumStore, Premium, initPolicyHolderInfo, ApplicationInfo } from '.'
import { tapPayTCMsgStatusCode } from 'mobileApp/common/component/tapPay'

export interface AccessoriesInfo { imei: string, supNo: string, subjectId: string, sellPrice: number | '', mbInvoiceNum: string, importSource: string }
const initAccessoriesInfo: AccessoriesInfo = { imei: '', supNo: '', subjectId: '', sellPrice: '', mbInvoiceNum: '', importSource: '' }
export const wbeProductCategory = 'accessories'

class WbeStore {
  navi = { goBack: () => { }, goNext: () => { } }

  private _cfSignature: any
  private _phSignature: any
  private _smSignature: any
  private _chSignature: any
  accessoriesInfo = initAccessoriesInfo
  policyHolderInfo = initPolicyHolderInfo
  app: any
  finalApp: any
  private _payment = {
    options: [] as Premium[],
    type: 'M',
    isSuccessful: false,
  }

  emptyData = () => {
    this._cfSignature = undefined
    this._phSignature = undefined
    this._smSignature = undefined
    this._chSignature = undefined
    this.accessoriesInfo = initAccessoriesInfo
    this.policyHolderInfo = initPolicyHolderInfo
    this.app = undefined
    this.finalApp = undefined
    this._payment = {
      options: [],
      type: 'M',
      isSuccessful: false,
    }
  }

  get cfSignature() { return this._cfSignature }
  get phSignature() { return this._phSignature }
  get smSignature() { return this._smSignature }
  get chSignature() { return this._chSignature }

  get paymentOptions() { return this._payment.options }
  get currentOption() {
    const option = this._payment.options.filter(option => option.paymentType === this._payment.type)[0]
    return option
  }
  get productType() { return this.currentOption.productType }
  get paymentType() { return this._payment.type }
  get paymentTypeDesc() { return this._payment.type === 'M' ? '月繳' : this._payment.type === '1Y' ? '年繳（一年期）' : this._payment.type === '2Y' ? '年繳（二年期）' :  this._payment.type === '3Y' ? '年繳（三年期）' : ''}
  get isPaymentSuccessful() { return this._payment.isSuccessful }

  set paymentType(type) { this._payment.type = type }

  initCase = async () => {
    const res = await service.getNewToken()
    if (res.status === 200) {
      // get new token
      const newToken: string = (await res.json()).token
      // update new token
      authStore.updateToken = newToken

      this._cfSignature = undefined
      this._phSignature = undefined
      this._smSignature = undefined
      this._chSignature = undefined
      this.accessoriesInfo = initAccessoriesInfo
      this.policyHolderInfo = initPolicyHolderInfo
      this.app = undefined
      this.finalApp = undefined
      this._payment = {
        options: [],
        type: 'M',
        isSuccessful: false,
      }

      return Promise.resolve()
    }
    return Promise.reject(`發生錯誤！ 錯誤代碼：${res.status}`)
  }

  quote = () => {
    if (this.accessoriesInfo.sellPrice === '') return Promise.reject({ message: '空機售價不可以空白！' })
    const matchedPremiums = premiumStore.getPremiumsByProductCategoryAndPrice(wbeProductCategory, this.accessoriesInfo.sellPrice)
    this._payment.options = matchedPremiums
    return Promise.resolve()
  }

  getApplicationInfo = () => {
    const { 
      name: phName, identity: phNationId, birthday: phBirthday, city: phCity, district: phDistrict, address: phAddress, nationType: phNationType, nationName: phNationName, 
      email: phEmail, phoneNo: phPhone, profession: phProfession, purpose: phPurpose, phAttribute, poProductNo, phSaleSalaryNo } = this.policyHolderInfo
    const { subjectId, imei: mbImei, sellPrice, mbInvoiceNum, importSource: subjectSource } = this.accessoriesInfo
    const poPayType = this.paymentType
    const poProductType = this.productType

    const application: ApplicationInfo = {
      phName, phNationId, phBirthday,
      phCity, phDistrict, phAddress, phNationType, phNationName,
      phEmail, phPhone, phProfession, phPurpose, phAttribute,
      mbId: Number(subjectId), mbImei, mbSellPrice: Number(sellPrice), poPayType, poProductNo, poProductType,
      phSaleSalaryNo, mbInvoiceNum, subjectSource, productCategory: wbeProductCategory
    }

    return application
  }

  getApp = async () => {
    if (this.accessoriesInfo.sellPrice === '') return Promise.reject({ message: '空機售價不可以空白！' })

    const res = await service.uploadApp(this.getApplicationInfo(), wbeProductCategory)

    if (res.status === 200) {
      const res = await service.getAppImg()
      if (res.status === 200) {
        this.app = window.URL.createObjectURL(await res.blob())
        return Promise.resolve()
      } else return Promise.reject({ message: `無法取得要保書圖檔！ 錯誤代碼：${res.status}` })
    } else return Promise.reject({ message: `無法上傳要保書資訊！ 錯誤代碼：${res.status}` })
  }

  getFinalApp = async () => {
    if (this.accessoriesInfo.sellPrice === '') return Promise.reject({ message: '空機售價不可以空白！' })

    const res = await service.uploadFinalApp(this.getApplicationInfo())

    if (res.status === 200) {
      const res = await service.getAppImg()
      if (res.status === 200) {
        this.finalApp = window.URL.createObjectURL(await res.blob())
        return Promise.resolve()
      } else return Promise.reject({ message: `無法取得要保書圖檔！ 錯誤代碼：${res.status}` })
    } else return Promise.reject({ message: `無法上傳要保書資訊！ 錯誤代碼：${res.status}` })
  }

  uploadCfSignature = async (signature: any) => {
    this._cfSignature = signature
    const res = await service.uploadCfSignature(signature)
    return res.status
  }

  uploadPhSignature = async (signature: any) => {
    this._phSignature = signature
    const res = await service.uploadPhSignature(signature)
    return res.status
  }

  uploadSmSignature = async (signature: any) => {
    this._smSignature = signature
    const res = await service.uploadSmSignature(signature)
    return res.status
  }

  uploadChSignature = async (signature: any) => {
    this._chSignature = signature
    const res = await service.uploadChSignature(signature)
    return res.status
  }

  payByPrime = async (prime: string, payName: string, payRelation: string) => {
    const res = await service.payByPrime(prime, payName, payRelation)

    if (res.status === 200) {
      this._payment.isSuccessful = true
      return Promise.resolve()
    } else if (res.status === 424) {
      const msg = (await res.json())?.message
      const msgStatusCode = msg?.split(' ')[0]
      return Promise.reject({ message: `無法完成付款交易！ 錯誤原因：${tapPayTCMsgStatusCode.get(msgStatusCode)}` })
    } else if (res.status === 417) {
      return Promise.reject({ message: '無法完成付款交易！請聯繫IT人員' })
    } else {
      const msg = (await res.json())?.message
      return Promise.reject({ message: `無法完成付款交易！ 錯誤原因：${msg}` })
    }
  }
}

export const wbeStore = new WbeStore()