import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage, FormikActions } from 'formik'
import * as Yup from 'yup'
import $ from 'jquery'

import { airpodsStore } from 'mobileApp/store'
import { TapPaySection } from 'mobileApp/common/component/tapPay'
import { AirpodsLayout } from '../layout'

declare var TPDirect: any

interface CardHolderInfo { payName: string, payRelation: string }

export const S10OnlinePayment: React.FC = () => {
  const [canGetPrime, setCanGetPrime] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [msg, setMsg] = useState('')
  const [consent, setConsent] = useState(false)  // 是否已閱讀過信用卡授權同意書
  const { name } = airpodsStore.policyHolderInfo

  return (
    <AirpodsLayout title="線上支付" step={4}>
      <Formik
        initialValues={{ payName: name, payRelation: 'A' }}
        validationSchema={Yup.object().shape({
          payName: Yup.string().required('必填欄位！').matches(/[\u4e00-\u9fa5]/, '姓名請輸入中文字'),
          payRelation: Yup.string().required('必填欄位！'),
        })}
        onSubmit={(values: CardHolderInfo, { setSubmitting }: FormikActions<CardHolderInfo>) => {
          setMsg('')
          if (!canGetPrime) return setMsg('請輸入信用卡資訊！')
          setIsProcessing(true)

          TPDirect.card.getPrime((result: any) => {
            // console.log(result)
            if (result.status !== 0) return setMsg('無法取得 Prime Code!')
            airpodsStore.payByPrime(result.card.prime, values.payName, values.payRelation)
              .then(() => {
                const powerOfAttorneyModal: any = $('#power_of_attorney')
                const successModal: any = $('#payment_success')
                // const successModal: any = $('#payment_fail')

                powerOfAttorneyModal.modal('hide')
                successModal.modal('show')
                setIsProcessing(false)
                // airpodsStore.navi.goNext
              })
              .catch(({ message }) => {
                const failModal: any = $('#payment_fail')
                failModal.modal('show')
                setMsg(message)
                setIsProcessing(false)
                setSubmitting(false)
              })
          })
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="form-group col-12 col-sm-6">
                <label htmlFor="payName" className="required">信用卡持卡人姓名</label>
                <Field type="text" name="payName" disabled className={'form-control' + (errors.payName && touched.payName ? ' is-invalid' : '')} />
                <ErrorMessage name="payName" component="div" className="invalid-feedback" />
              </div>

              <div className="form-group col-12 col-sm-6">
                <label htmlFor="payRelation" className="required">與要保人之關係</label>
                <Field component="select" name="payRelation" disabled className={'form-control' + (errors.payRelation && touched.payRelation ? ' is-invalid' : '')}>
                  <option value="A">本人</option>
                  {/* <option value="B">配偶</option>
                  <option value="C">子女</option>
                  <option value="D">父母</option>
                  <option value="E">兄弟姊妹</option> */}
                </Field>
              </div>
            </div>

            <TapPaySection className="my-3" onUpdate={setCanGetPrime} />

            <section className="mb-3">
              {msg ? <div className="text-danger">{msg}</div> : <div style={{ height: '24px' }}>&nbsp;</div>}    
            </section>

            <section className="mb-4">
              <Message message="您的信用卡將會刷2元並立即退2元做授權，並不會真的扣款" />
              <Message message="請先閱讀信用卡同意書並同意後，才能進行信用卡支付。" />
            </section> 

            {isProcessing ?
              <section className="progress col-8 offset-2 p-0">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                  aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '50%' }}></div>
              </section> :

              <section className="my-3 d-flex justify-content-between">
                <button type="button" className="btn btn-light btn_sm_compact" onClick={airpodsStore.navi.goBack}>上一步</button>
                <button type="button" className="btn btn-primary ml-auto mr-2 btn_sm_compact" data-toggle="modal" data-target="#power_of_attorney">授權書</button>
                <button type="submit" className="btn btn-secondary btn_sm_compact" disabled={(consent === false) || isSubmitting}>送出</button>
              </section>
            }

            <div className="modal fade" id="power_of_attorney" tabIndex={-1} role="dialog">
              <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">保險費信用卡付款授權約定條款</h5>
                  </div>
                  <div className="modal-body">
                    <section>
                      <p>１・授權人同意信用卡發卡機構按期自本授權書所指定信用卡（以下簡稱信用卡）帳戶內扣款，並代為支付指定保單應繳保險費(含首期/續期/續保)予美商安達產物保險股份有限公司台灣分公司（以下簡稱安達產險）。</p>
                      <p>２・若授權人以信用卡繳付續期/續保保險費，授權人應於續期/續保保險費應繳日前20天將本授權書送達安達產險，經審核後始生效力，逾期送達者延至次期(續期)或約定扣款日(續保)始生效力；若授權人欲變更繳費信用卡時，應重新填寫授權書，並於當期保險費應繳日前7天，將授權書送達安達產險，原授權書即告終止，逾期送達者延至次期(續期)或約定扣款日(續保)始生效力。</p>
                      <p>３・本授權書因填寫內容不全、錯誤或下列任一情形發生時，則自該事由發生日起失其效力(1)發卡機構不同意授權人依指定信用卡繳交保險費。(2)要保人無繳納保險費義務時。(3)要保人變更收費方式或終止保險契約之程序完成時。(4)授權人與發卡機構信用卡契約終止時。</p>
                      <p>４・保單之授權人因第2點情事致發卡機構無法扣款並繳付保險費予安達產險時，指定收費方式將自動轉換為「自行繳費」或安達產險指定之收費方式；如有寬限期間者(續期)，依照原保單條款約定事項處理。</p>
                      <p>５・請款結果若有信用卡之信用額度扣除未償金額後不足支付該期應繳保險費之情形時，則於保單有效期內，安達產險保有再次請款之權利。本授權書效力不因指定保單保險費發生變動而受影響。</p>
                      <p>６・授權人以信用卡代繳保險費後，該筆保險費將併入當月份之信用卡消費明細中，授權人於收到當月份之繳款通知單後，應依發卡機構信用卡契約之約定全數繳納，或繳納最低應繳金額，未繳清之餘額則依信用卡約定條款之利率加計循環利息。各筆循環信用利息以各發卡機構約定條款之起息日計算，與指定保單之寬限期無關。</p>
                      <p>７・授權人對安達產險之保險費計算、退補保險費或授權內容有所疑義，應自行與安達產險洽詢，概與發卡機構無涉。</p>
                      <p>８・若安達產險經由此項服務業務所收取保險費有退還之必要時，除另有約定外，安達產險得逕行退還該款項予持卡人。</p>
                      <p>９・授權人指定繳付之信用卡如有卡號或有效期變動等情事時，授權人應主動通知安達產險並重新填具授權書，依第2點辦理生效。授權人不為或怠於前述通知及辦理相關程序，致安達產險無法以本授權書之信用卡自發卡機構取得各期保險費之信用卡授權時，不生繳付保險費之效力。原授權書於新授權書生效後即行終止。</p>
                      <p>１０・授權人重填授權書前，安達產險就其指定之信用卡卡號不變之年度續卡，仍得依本授權書向發卡機構請求信用卡授權繳付應繳保險費。</p>
                      <p>１１・授權書終止前應繳付予安達產險之保險費，授權人仍應依發卡機構所發之繳款通知書向發卡機構支付。</p>
                      <p>１２・指定保單之被保險人身故或完全失能時，如發卡機構已將身故或完全失能後原應繳之保險費支付予安達產險者，授權人仍應依發卡機構所發之繳款通知書向發卡機構支付；倘有應退補款項，依第8點規定辦理。</p>
                      <p>１３・授權人以同一張信用卡同時授權安達產險代繳二張以上保單之保險費時，代繳順序由安達產險衡量授權人之信用卡信用額度餘額與保單狀況權衡處理，要保人及授權人均無異議。</p>
                      <p>１４・授權人應確實填寫本授權書各項資料，如有冒用他人帳戶使用者，須自負法律責任。</p>
                      <p>１５・安達產險應依個人資料保護法等相關法令，就本授權書填載資料負保密義務，不得任意洩漏予非執行本授權書業務之第三人，亦不得作本授權書目的範圍以外之使用。如欲對您所提供之個人資料，為查詢、閱覽、補充、更正或請求製作副本、停止處理、利用或請求刪除等，依個人資料保護法第三條規定行使各項權利，請洽客服專線。</p>
                      <p>１６・上列約定條款如有未盡事宜，授權人得授權安達產險與發卡機構隨時協商修改之。</p>
                    </section>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setConsent(true)} data-dismiss="modal">我同意</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="payment_success" tabIndex={-1} role="dialog">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="my-5 text-center">
                      <i className="material-icons text-secondary" style={{ fontSize: '100px' }}>check_circle_outline</i>
                      <h4 className="font-weight-normal mt-3">交易成功</h4>
                    </div>
                    <div className="mx-4 mb-4">
                      <Message message="保費將於核保後以您的信用卡支付" />
                      <Message message="請注意：您的投保程序尚未完成，請於下一步親簽信用卡授權書，否則將無法完成投保！" warning={true} />
                    </div>
                    <div className="text-right">
                      <button type="button" className="btn btn-secondary" onClick={airpodsStore.navi.goNext} data-dismiss="modal">信用卡持卡人簽名</button>
                    </div>         
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="payment_fail" tabIndex={-1} role="dialog">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="my-5 text-center">
                      <i className="material-icons text-muted" style={{ fontSize: '100px' }}>error_outline</i>
                      <h4 className="font-weight-normal my-3">交易失敗</h4>
                    </div>
                    <div className="mx-4 mb-4">
                      <Message message="請使用要保人本人信用卡！" warning={true} />
                    </div>
                    <div className="text-right">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">關閉</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AirpodsLayout>
  )
}

const Message: React.FC<{ message: string, warning?: boolean }> = ({ message, warning = false }) => {
  const msgClass = `d-flex ` + (warning ? 'text-danger' : 'text-primary')
  return (
    <div className={msgClass}>
      <i className="material-icons mr-2" style={{ fontSize: '20px', lineHeight: '1.2' }}>error_outline</i>
      <p className="font-weight-bold">{message}</p>
    </div>
  )
}