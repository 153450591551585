import React, { FC, useState, MouseEventHandler } from 'react'

import { excelImportStore, SubjectData } from 'adminConsole/store'
import { DragAndDropField } from 'adminConsole/component'
import { ImportSuccess, ImportFailed, InvalidSubjectData, parseExcelToJSON, validateSubjects, Notice } from '.'

enum Status { Idle, Validating, Validated, Importing, ImportSuccess, ImportFailed }
type ValidatedSubjects = { validSubjects: SubjectData[], invalidSubjects: SubjectData[] }
type ValidatedProps = { subjects: ValidatedSubjects, handleSubmitSubjects: MouseEventHandler, handleReset: () => void }

const subjectNotices = [
  '資料匯入後會全部覆蓋原本的資料，請確認後再執行匯入。',
  '未通過檢核的資料如果未修正再次匯入，將排除在匯入資料清單。'
]

export const Subject = () => {
  const [status, setStatus] = useState<Status>(Status.Idle)
  const [subjects, setSubjects] = useState<ValidatedSubjects | undefined>(undefined)
  const [errMsg, setErrMsg] = useState<string>('')

  const handleFileToData = async (file: File) => {
    if (!file) return 
    const subjectList = await parseExcelToJSON(file)
    const validatedSubjects = validateSubjects(subjectList)

    setSubjects(validatedSubjects)
    setStatus(Status.Validated)
  }

  const handleSubmitSubjects = () => {
    const { validSubjects } = subjects!

    excelImportStore.importSubjects(validSubjects)
      .then(() => setStatus(Status.ImportSuccess))
      .catch(({ message }) => { 
        setErrMsg(message)
        setStatus(Status.ImportFailed)
      })
  }

  const handleReset = () => {
    setSubjects(undefined)
    setStatus(Status.Idle)
  }

  return (
    <section className="mx-3" style={{ marginTop: '36px' }}>
      {
        status === Status.Idle ? <Idle handleFileToData={handleFileToData} /> :
        status === Status.Validated ? <Validated subjects={subjects!} handleSubmitSubjects={handleSubmitSubjects} handleReset={handleReset} /> :
        status === Status.ImportSuccess ? <ImportSuccess handleReset={handleReset} /> :
        status === Status.ImportFailed ? <ImportFailed handleReset={handleReset} errMsg={errMsg} /> : null
      }
    </section>
  )
}

type IdleProps = { handleFileToData: Function }
const Idle: FC<IdleProps> = ({ handleFileToData }) => {
  return (
    <div className="w-100 mx-auto mt-4">
      <DragAndDropField id='import_subjects' handleFileToData={handleFileToData} />
    </div>
  )
}

const Validated: FC<ValidatedProps> = ({ subjects, handleSubmitSubjects, handleReset }) => {
  const { validSubjects, invalidSubjects } = subjects
  const hasInvalid = invalidSubjects.length > 0

  return (
    <section>
      <h6 className="mb-4">檢核結果</h6>
      <div className="d-flex">
        <div className="d-flex align-items-center col-3">
          <i className="material-icons mb-3 mr-2 text-primary" style={{ fontSize: '20px' }}>task_alt</i>
          <p>通過檢核：{validSubjects.length} 筆</p> 
        </div>
        <div className="d-flex align-items-center col-3">
          <i className="material-icons mb-3 mr-2 text-secondary" style={{ fontSize: '20px' }}>help_outline</i>
          <p>未通過檢核：{invalidSubjects.length} 筆</p>
        </div>
      </div>

      { hasInvalid && <InvalidSubjectData subjects={invalidSubjects} /> }

      <Notice notices={subjectNotices} />

      <div className="mt-4 text-right mx-4">
        <button type="button" onClick={handleReset} className="btn btn-link text-muted mr-3">重新選擇檔案</button>
        <button type="button" onClick={handleSubmitSubjects} className="btn btn-primary">確定匯入</button>
      </div>
    </section>
  )
}
