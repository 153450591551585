import React from 'react'
import { RouteComponentProps } from 'react-router'
import toastr from 'toastr'

import { salesStore, dealerStore, storeStore } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { DealerSelector, StoreSelector, SearchBox, Pagination, AddButton, ReloadButton } from 'adminConsole/component'
import { Path } from 'adminConsole'

interface Props extends RouteComponentProps {
  dealerId: number
  dealerOnChange: Function
  storeId: number
  storeOnChange: Function
  keyword: string
  keywordOnChange: Function
  currPage: number
  currPageOnChange: Function
}

export const SalesList: React.FC<Props> = ({ dealerId, dealerOnChange, storeId, storeOnChange,
  keyword, keywordOnChange, currPage, currPageOnChange, history }) => {
  const [isLoading, setIsLoading] = React.useState(true)

  const reloadAll = () => {
    setIsLoading(true)
    Promise.all([
      dealerStore.loadAll(),
      storeStore.loadAll(),
      salesStore.loadAll(),
    ]).then()
      .catch(({ message }) => toastr.error(message))
      .finally(() => setIsLoading(false))
  }

  React.useEffect(reloadAll, [])

  const { dealers } = dealerStore
  const stores = storeStore.findByDealerId(dealerId)
  const totalSalesmen = salesStore.salesmen
    .filter(salesman => dealerId === 0 ? true : salesman.dealerId === dealerId)
    .filter(salesman => storeId === 0 ? true : salesman.storeId === storeId)
    .filter(salesman =>
      salesman.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      salesman.uno.toLowerCase().indexOf(keyword.toLowerCase()) > -1
    )

  const pageSize = 10
  const salesmen = totalSalesmen.filter((p, i) => i + 1 > pageSize * (currPage - 1) && i + 1 <= pageSize * currPage)

  const handleReloadClick = reloadAll
  const handleSalesClick = (id: number) => history.push(`${Path.SalesList}/${id}`)

  return (
    <>
      <TitleBar title="業務員管理" isLoading={isLoading} />

      <main className="my-3 px-3">
        <section className="form-row mb-4">
          <div className="col-3">
            <DealerSelector dealers={dealers} value={dealerId} onChange={dealerOnChange} />
          </div>
          <div className="col-3">
            <StoreSelector stores={stores} value={storeId} onChange={storeOnChange} />
          </div>
          <div className="col-4">
            <SearchBox value={keyword} onChange={keywordOnChange} />
          </div>
          <div className="col-2 d-flex flex-row-reverse align-items-center">
            <ReloadButton disabled={isLoading} onClick={handleReloadClick} />
            <AddButton to={`${Path.SalesList}/0`} className="mr-3" />
          </div>
        </section>

        <section>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">經銷商名稱</th>
                <th scope="col">門市名稱</th>
                <th scope="col">姓名</th>
                <th scope="col">保險證號</th>
                <th scope="col">狀態</th>
              </tr>
            </thead>
            <tbody>
              {salesmen.map(salesman => (
                <tr key={salesman.id} onClick={() => handleSalesClick(salesman.id)}>
                  <td>{salesman.dealerName}</td>
                  <td>{salesman.storeName}</td>
                  <td>{salesman.name}</td>
                  <td>{salesman.uno}</td>
                  <td>{salesman.status ? '啟用 ' : '停用'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className="mt-4">
          <Pagination totalElements={totalSalesmen.length} pageSize={pageSize} currPage={currPage} onChange={currPageOnChange} />
        </section>
      </main>
    </>
  )
}