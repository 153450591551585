import React from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'

import { authStore } from 'adminConsole/store'
import { Path } from 'adminConsole'

interface AuthRequest { account: string, password: string, isPersistAccount?: any }

export const Login: React.FC<RouteComponentProps> = props => {
  if (authStore.isLogin) props.history.push('/')
  const account = authStore.userAccount

  return (
    <div className="container">
      <div className="row vh-100 align-items-center justify-content-center align-content-center">
        <section className="col-10 col-md-8">
          <section className="">
            <h2 className="font-weight-bold text-center">安盛保代</h2>
            <h3 className="text-secondary text-center mt-3">行動投保管理後台</h3>
          </section>

          <section className="mt-5 d-flex justify-content-center">
            <Formik
              initialValues={{ account, password: '', isPersistAccount: false }}
              validationSchema={Yup.object().shape({
                account: Yup.string().email('請輸入 Email！').required('必填欄位！'),
                password: Yup.string().required('必填欄位！'),
              })}
              onSubmit={({ account, password, isPersistAccount }: AuthRequest, { setStatus, setSubmitting }: FormikActions<AuthRequest>) => {
                setStatus()
                authStore.login(account, password, isPersistAccount)
                  .catch(({ message }) => {
                    setStatus(message)
                    setSubmitting(false)
                  })
              }}
              component={LoginForm}
            />
          </section>
        </section>
      </div>
    </div>
  )
}

const LoginForm = ({ errors, status, touched, isSubmitting }: FormikProps<AuthRequest>) => (
  <Form style={{ width: '360px' }}>
    <div className="form-group">
      <label htmlFor="account">帳號</label>
      <Field name="account" type="text" placeholder="請輸入登入 Email" className={'form-control' + (errors.account && touched.account ? ' is-invalid' : '')} />
      <ErrorMessage name="account" component="div" className="invalid-feedback" />
    </div>

    <div className="form-group">
      <label htmlFor="password">密碼</label>
      <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
      <ErrorMessage name="password" component="div" className="invalid-feedback" />
    </div>

    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <Field type="checkbox" name="isPersistAccount" id="isPersistAccount" className={'custom-control-input'} />
        <label htmlFor="isPersistAccount" className="custom-control-label">記住登入帳號</label>
      </div>
    </div>

    {status && <div className="text-danger">{status}</div>}

    <button type="submit" className="btn btn-secondary w-100 mt-4" disabled={isSubmitting}>登入</button>
    <Link to={Path.PasswordReset} className="password_reset mt-4">忘記密碼？</Link>
  </Form>
)