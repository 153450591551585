import React from 'react'
import { RouteComponentProps } from 'react-router'
import moment from 'moment'
import toastr from 'toastr'

import { dealerStore, storeStore, policyStore } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { DealerSelector, StoreSelector, Pagination, SearchBox } from 'adminConsole/component'
import { Path } from 'adminConsole'

interface Props extends RouteComponentProps {
  // reportDate: Moment | null
  // reportDateOnChange: Function
  dealerId: number
  dealerOnChange: Function
  storeId: number
  storeOnChange: Function
  keyword: string
  keywordOnChange: Function
  currPage: number
  currPageOnChange: Function
}

export const PolicySearchList: React.FC<Props> = ({ dealerId, dealerOnChange,
  storeId, storeOnChange, keyword, keywordOnChange, currPage, currPageOnChange, history }) => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(true)
    Promise.all([
      dealerStore.loadAll(),
      storeStore.loadAll(),
      policyStore.loadAll(),
    ]).then()
      .catch(({ message }) => toastr.error(message))
      .finally(() => setIsLoading(false))
  }, [])

  const dealers = dealerStore.findByValid()
  const stores = storeStore.findByDealerIdAndValid(dealerId)
  const totalPolicies = policyStore.policies
    // .filter(policy => reportDate === null ? true : policy.stPay === reportDate.format('YYYYMMDD'))
    .filter(policy => dealerId === 0 ? true : policy.dealerId === dealerId)
    .filter(policy => storeId === 0 ? true : policy.storeId === storeId)
    .filter(policy =>
      policy.salesName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.no.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.phName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.syApproveName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.stSyApprove.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.stPay.toLowerCase().indexOf(keyword.toLowerCase()) > -1
    )
  const pageSize = 10
  const policies = totalPolicies.filter((p, i) => i + 1 > pageSize * (currPage - 1) && i + 1 <= pageSize * currPage)

  const handlePolicyClick = (policyId: number, policyNo: string) => history.push(`${Path.PolicySearchList}/${policyId}?${policyNo}`)

  return (
    <>
      <TitleBar title="保單查詢" isLoading={isLoading} />
      <section className="form-row mb-4">
        {/* <div className="col-3">
          <SingleDatePicker date={reportDate} onChange={reportDateOnChange} isClearable={true} />
        </div> */}
        <div className="col-3">
          <DealerSelector dealers={dealers} value={dealerId} onChange={dealerOnChange} />
        </div>
        <div className="col-3">
          <StoreSelector stores={stores} value={storeId} onChange={storeOnChange} />
        </div>
        <div className="col-3">
          <SearchBox value={keyword} onChange={keywordOnChange} />
        </div>
      </section>

      <section>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">日期</th>
              <th scope="col">業務員姓名</th>
              <th scope="col">保單編號</th>
              <th scope="col">客戶姓名</th>
              <th scope="col">保費</th>
              <th scope="col">審核人員</th>
              <th scope="col">審核日期</th>
            </tr>
          </thead>
          <tbody>
            {policies.map(policy => {
              const formatDateYMD = (date: string) => moment(date, 'YYYYMMDD').format('YYYY/MM/DD')
              return (
                <tr key={policy.id} onClick={() => handlePolicyClick(policy.id, policy.no)}>
                  <td>{formatDateYMD(policy.stPay)}</td>
                  <td>{policy.salesName}</td>
                  <td>{policy.no}</td>
                  <td>{policy.phName}</td>
                  <td>{policy.poPremium}</td>
                  <td>{policy.syApproveName}</td>
                  <td>{policy.stSyApprove ? formatDateYMD(policy.stSyApprove) : ''}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </section>

      <section className="mt-4">
        <Pagination totalElements={totalPolicies.length} pageSize={pageSize} currPage={currPage} onChange={currPageOnChange} />
      </section>
    </>
  )
}