import React, { Component } from 'react'
import styled from 'styled-components'

const Quagga = require('quagga')
const $ = require('jquery')

export enum BarcodeType {
  Code128 = 'code_128_reader',
  Ean = 'ean_reader',
  Ean8 = 'ean_8_reader',
  Code39 = 'code_39_reader',
  Code39Vin = 'code_39_vin_reader',
  Codabar = 'codabar_reader',
  Upc = 'upc_reader',
  UpcE = 'upc_e_reader',
  I2Of5 = 'i2of5_reader',
  Code93 = 'code_93_reader'
}

enum StatusType {
  Initial,
  InitFail,
  Detecting,
  CodeNotValid,
  Detected
}

interface ReaderProps {
  id: string
  readers: BarcodeType[]
  validator?(code: string): boolean
  cb(code: string): void
}

export class FixedBarcodeReader extends Component<ReaderProps> {
  state = { status: StatusType.Initial, code: '', detectedTime: 0 }

  componentDidMount = () => {
    const { id } = this.props
    Quagga.onProcessed(this.processHandler)
    Quagga.onDetected(this.detectHandler)
    $(`#${id}`).on('show.bs.modal', this.turnOn)
    $(`#${id}`).on('hide.bs.modal', this.turnOff)
  }

  componentWillUnmount = () => {
    const { id } = this.props
    Quagga.offProcessed(this.processHandler)
    Quagga.offDetected(this.detectHandler)
    $(`#${id}`).off('show.bs.modal', this.turnOn)
    $(`#${id}`).off('hide.bs.modal', this.turnOff)
  }

  detectHandler = (result: any) => {
    const { id, validator, cb } = this.props
    const code = result.codeResult.code
    const detectedTime = Date.now()

    if (validator && !validator(code)) {
      this.setState({ status: StatusType.CodeNotValid, code, detectedTime })
    } else {
      this.setState({ status: StatusType.Detected, code, detectedTime })
      setTimeout(() => {
        $(`#${id}`).modal('hide')
        cb(code)
      }, 1200)
    }
  }

  render = () => {
    const { id } = this.props
    const { status, code } = this.state
    return (
      <div className="modal fade" id={id} tabIndex={-1}>
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header">
              {
                status !== StatusType.Initial ? status !== StatusType.InitFail ? status !== StatusType.Detecting ? status !== StatusType.CodeNotValid ?
                  <div><i className="fas fa-check-circle mr-2"></i>發現 {code}</div> :
                  <div><i className="fas fa-exclamation-triangle mr-2"></i>發現 {code}，但格式不符!</div> :
                  <div><span className="spinner-grow spinner-grow-sm mr-2" />條碼偵測中...</div> :
                  <div><i className="fas fa-exclamation-triangle mr-2"></i>掃瞄器啟動失敗!</div> :
                  <div><span className="spinner-border spinner-border-sm mr-2" />掃瞄器啟動中...</div>
              }
            </div>

            <div className="modal-body p-0" style={{ height: '300px', overflow: 'hidden' }}>
              <Viewport id="interactive" className="viewport" />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>

          </div>
        </div>
      </div >
    )
  }

  turnOn = () => {
    const { readers } = this.props
    Quagga.init({
      numOfWorkers: 4,       // default is 4
      locate: false,         // default is true
      inputStream: {
        type: 'LiveStream',
        constraints: {
          width: { min: 1080 },
          height: { min: 720 },
          facingMode: "environment",
        },
        area: {
          top: '20%',
          right: '10%',
          left: '10%',
          bottom: '70',
        },
      },
      frequency: 5,          // default is 10
      decoder: {
        readers,
        multiple: false,
      },
      locator: {
        patchSize: "large",
        halfSample: true,
      },
    }, this.initCb)
  }

  initCb = (err: Error) => {
    if (err) {
      this.setState({ status: StatusType.InitFail })
      return
    } else {
      Quagga.start()
      this.setState({ status: StatusType.Detecting })
    }
  }

  turnOff = Quagga.stop

  processHandler = (result: any) => {
    const drawingCtx = Quagga.canvas.ctx.overlay
    const drawingCanvas = Quagga.canvas.dom.overlay

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        result.boxes.filter((box: any) => {
          return box !== result.box;
        }).forEach((box: any) => {
          Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "#4caf50", lineWidth: 4 });
        });
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 5 });
      }
    }

    if ((Date.now() - this.state.detectedTime) > 2000) this.setState({ status: StatusType.Detecting })
  }
}

const Viewport = styled.div`
  video, canvas {
    width: 100%;
  }

  canvas.drawingBuffer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`