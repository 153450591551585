import React from 'react'
import { RouteComponentProps } from 'react-router'
import toastr from 'toastr'

import { policyStore } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { PdfViewer } from 'share/component/pdf'

enum Status { Loading, Ready, Failed }
interface Params { id: string }

export const PolicySearchView: React.FC<RouteComponentProps<Params>> = props => {
  const id = parseInt(props.match.params.id)
  const policyNo = (props.location.search).split('?')[1]
  const [status, setStatus] = React.useState(Status.Loading)

  React.useEffect(() => {
    policyStore.loadPDF(id)
      .then(() => setStatus(Status.Ready))
      .catch(({ message }) => {
        toastr.error(message)
        setStatus(Status.Failed)
      })
  }, [id])

  const { pdf } = policyStore
  const { goBack } = props.history
  const downloadPolicyConsentZip = () => policyStore.downloadPolicyConsentZip(id, policyNo).catch(({ message }) => toastr.error(message))

  return (
    <>
      <TitleBar title="保單查詢" isLoading={status === Status.Loading} />
      {status === Status.Failed ? <button type="button" className="btn btn-light mr-auto" onClick={goBack}>返回</button> : ''}
      {status === Status.Ready ? <PolicyPdf pdf={pdf} goBack={goBack} downloadPolicyConsentZip={downloadPolicyConsentZip} /> : ''}
    </>
  )
}

interface Props {
  pdf: any
  goBack: () => void
  downloadPolicyConsentZip: () => void
}

const PolicyPdf: React.FC<Props> = ({ pdf, goBack, downloadPolicyConsentZip }) => (
  <>
    <section className="row">
      <div className="col-12" id="pdf_container">
        <PdfViewer data={pdf} pageNo={1} />
      </div>
    </section>
    <div className="row mt-3 mb-5">
      <div className="col-12 d-flex">
        <button type="button" className="btn btn-light mr-auto" onClick={goBack}>返回</button>
        <button type="button" className="btn btn-light" onClick={downloadPolicyConsentZip}>下載</button>
      </div>
    </div>
  </>
)