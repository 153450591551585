import React from 'react'

import { Store } from 'adminConsole/store'

interface Props {
  stores: Store[]
  value: number
  onChange: Function
}

export const StoreSelector: React.FC<Props> = ({ stores, value, onChange }) => {

  return (
    <select className="custom-select" value={value} onChange={e => onChange(parseInt(e.target.value))}>
      <option value="0">請選擇門市</option>
      {stores.map(store => <option key={store.id} value={store.id}>{store.name}</option>)}
    </select>
  )
}