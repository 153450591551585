import React, { useEffect } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { deviceStore } from 'mobileApp/store'
import { AirpodsModule } from './airpods'
import { LtcModule } from './ltc'
import { MdiModule } from './mdi'
import { QuestionnaireModule } from './questionnaire'
import { SystemModule } from './system'
import { WbeModule } from './wbe'

export const MobileApp: React.FC = () => {
  // 當畫面大小改變的時候，呼叫 deviceStore.detectDevice 來更新裝置資訊
  useEffect(() => {
    window.addEventListener('resize', deviceStore.detectDevice)
    return () => window.removeEventListener('resize', deviceStore.detectDevice)
  }, [])

  return (
    <Router basename="/app">
      <Switch>
        <Route path="/system" component={SystemModule} />
        <Route path="/questionnaire" component={QuestionnaireModule} />
        <Route path="/mdi" component={MdiModule} />
        <Route path="/wbe" component={WbeModule} />
        <Route path="/ltc" component={LtcModule} />
        <Route path="/airpods" component={AirpodsModule} />

        <Redirect to="/system/home" />
      </Switch>
    </Router>
  )
}