import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'

import { BasicLayout } from 'mobileApp/common/layout'

export const Home: FC<RouteComponentProps> = (props) => {
  return (
    <BasicLayout title="首頁">
      <div className="row justify-content-around">
        <div className="col-10 col-sm-5 shadow-sm p-3 mx-4 mr-sm-4 ml-sm-0 mb-4 bg-white rounded text-center" onClick={() => props.history.push('/mdi')}>
          <div className="my-4" >
            <div className="mx-auto" style={{ height: '92pt', width: '92pt' }}>
              <img src="/img/mdi.png" style={{ width: '100%' }} alt="project_logo" />
            </div>
          </div>
          <h5 className="text-primary">
            <div className="mb-1">JustCare</div>
            <div>行動裝置保險</div>
          </h5>
          <div className="text-muted mt-3">行動電話</div>
        </div>

        <div className="col-10 col-sm-5 shadow-sm p-3 mx-4 mr-sm-0 ml-sm-4 mb-4 bg-white rounded text-center" onClick={() => props.history.push('/wbe')}>
          <div className="my-4" >
            <div className="mx-auto" style={{ height: '92pt', width: '92pt' }}>
              <img src="/img/wbe.png" style={{ width: '100%' }} alt="project_logo" />
            </div>
          </div>
          <h5 className="text-primary">
            <div className="mb-1">JustCare</div>
            <div>行動裝置保險</div>
          </h5>
          <div className="text-muted mt-3">平板電腦、智慧型手錶／手環</div>
        </div>

        <div className="col-10 col-sm-5 shadow-sm p-3 mx-4 mr-sm-4 ml-sm-0 mb-4 bg-white rounded text-center" onClick={() => props.history.push('/ltc')}>
          <div className="my-4" >
            <div className="mx-auto pt-4" style={{ height: '92pt', width: '92pt', paddingTop: '28px' }}>
              <img src="/img/laptop.png" style={{ width: '100%' }} alt="project_logo" />
            </div>
          </div>
          <h5 className="text-primary">
            <div className="mb-1">JustCare</div>
            <div>行動裝置保險</div>
          </h5>
          <div className="text-muted mt-3">筆記型電腦</div>
        </div>

        <div className="col-10 col-sm-5 shadow-sm p-3 mx-4 mr-sm-0 ml-sm-4 mb-4 bg-white rounded text-center" onClick={() => props.history.push('/airpods')}>
          <div className="my-4 pb-4" >
            <div className="mx-auto pt-4 pb-6" style={{ height: '72pt', width: '72pt', paddingTop: '28px' }}>
              <img src="/img/airpods.svg" style={{ width: '100%' }} alt="project_logo" />
            </div>
          </div>
          <h5 className="text-primary">
            <div className="mb-1">JustCare</div>
            <div>行動裝置保險</div>
          </h5>
          <div className="text-muted mt-3">AirPods</div>
        </div>
      </div>
    </BasicLayout>
  )
}