import React from 'react'

import { FixedBarcodeReader, BarcodeType } from '.'

const readers = [BarcodeType.Code128]

interface ReaderProps {
  id: string,
  cb(code: string): void
}

export const GeneralBarcodeReader: React.FC<ReaderProps> = ({ id, cb }) => {
  return (
    <FixedBarcodeReader id={id} readers={readers} cb={cb} />
  )
}