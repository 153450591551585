import React from 'react'
import styled from 'styled-components'

interface Props {
  titles: Array<string>
  step: number
}

const Indicator: React.FC<Props> = ({ titles, step }) => (
  <List>
    {titles.map((title, index) => (
      <Item className={index < step ? 'complete' : index === step ? 'processing' : 'incomplete'} key={index}>{title}</Item>
    ))}
  </List>
)

export default Indicator

const List = styled.ul`
  position: sticky;
  top: 180px;
  padding: 0;
  margin: 0;
`

const Item = styled.li`
  position: relative;
  height: 48px;
  margin-bottom: 20px;
  padding-right: 18px;
  text-align: right;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 6px;
    height: 100%;
    border-radius: 3px;
  }

  &.complete::after { background-color: #008FCB }
  &.processing::after { background-color: #fbc700 }
  &.incomplete::after { background-color: #4a4a4a } 
  /* &.complete::after { background-color: #0088CB } */
  /* &.processing::after { background-color: #C1CE01 } */
  /* &.incomplete::after { background-color: #6C757D }  */
`