import React, { useEffect, useRef, FC, useState } from 'react'
import { observer } from 'mobx-react'
import toastr from 'toastr'

import { SignatureCanvas, SignaturePad } from 'mobileApp/common/component/signature'
import { wbeStore, deviceStore } from 'mobileApp/store'
import { WbeLayout } from 'mobileApp/wbe/layout'

export const S06PhSignature = observer(() => {
  const [disabled, setDisabled] = useState(false)
  const canvasRef = useRef<SignatureCanvas>(null)
  useEffect(() => {
    wbeStore.phSignature && canvasRef.current && canvasRef.current.fromDataURL(wbeStore.phSignature)
  }, [])

  const handleClear = () => canvasRef.current!.clear()
  const handleNext = () => {
    setDisabled(true)
    if (deviceStore.isPhone) wbeStore.navi.goNext()
    else
      wbeStore.uploadPhSignature(canvasRef.current!.toDataURL()).then((statusCode) => {
        if (statusCode === 200) wbeStore.navi.goNext()
        else {
          const errMsg = statusCode === 400 ? '簽名不可空白！' : `上傳簽名檔失敗！ 錯誤碼：${statusCode}`
          toastr.error(errMsg)
          setDisabled(false)
        }
      })
  }

  return (
    <WbeLayout title="要保人簽名" step={3}>
      <section>
        <Article no="（１）" content="本人同意設定電子保單（保險公司將寄送手機簡訊通知要保人，請進行電子保單下載。）" />
      </section>

      {deviceStore.isPhone ? null : <SignaturePad title="要保人簽名" canvasRef={canvasRef} height="240px" />}

      <section className="my-3 d-flex justify-content-between">
        <div>
          <button type="button" className="btn btn-light" onClick={wbeStore.navi.goBack}>
            上一步
          </button>
          {deviceStore.isPhone ? null : (
            <button type="button" className="btn btn-light ml-3" onClick={handleClear}>
              清除
            </button>
          )}
        </div>
        <button type="button" className="btn btn-secondary" disabled={disabled} onClick={handleNext}>
          下一步
        </button>
      </section>
    </WbeLayout>
  )
})

const Article: FC<{ no: string; content: string }> = ({ no, content }) => (
  <p className="d-flex fs_caption">
    <span>{no}</span>
    <span>{content}</span>
  </p>
)
