import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'

import { airpodsStore, subjectStore } from 'mobileApp/store'
import { AirpodsLayout } from '../layout'

export const S12Confirmation: FC<RouteComponentProps> = props => {
  const { paymentType, paymentTypeDesc } = airpodsStore
  const { premium } = airpodsStore.currentOption
  const { name } = airpodsStore.policyHolderInfo
  const { supNo, imei, subjectId } = airpodsStore.airpodsInfo
  const { mara } = subjectStore.getSubjectById(subjectId)[0]
  const paymentStatus = airpodsStore.isPaymentSuccessful ? '繳款成功' : '繳款失敗'

  return (
    <AirpodsLayout title="投保完成" step={5}>
      <section className="text-center">
        <h4 className="font-weight-normal my-3">您已完成投保！</h4>
        <h5 className="font-weight-normal mb-4">將於核保後 10個工作天 內寄發電子保單簡訊至您的手機號碼！</h5>
      </section>
      <section className="row">
        <div className="col-4 text-muted mb-3">投保險種</div>
        { 
          paymentType === 'M' ? <div className="col-8 text-primary">安達產物行動裝置保險（分期交付申型）</div> :
          paymentType === '1Y' ? <div className="col-8 text-primary">安達產物行動裝置保險（一次交付寅型）</div> :
          paymentType === '2Y' ? <div className="col-8 text-primary">安達產物行動裝置保險（一次交付未型）</div> :
          paymentType === '3Y' ? <div className="col-8 text-primary">安達產物行動裝置保險（一次交付亥型）</div> : null
        }

        <div className="col-4 text-muted mb-3">繳款方式</div>
        <div className="col-8 text-primary">{paymentTypeDesc}</div>

        <div className="col-4 text-muted mb-3">保險費</div>
        <div className="col-8 text-primary">新台幣 {premium} 元</div>

        <div className="col-4 text-muted mb-3">要保人姓名</div>
        <div className="col-8 text-primary">{name}</div>

        <div className="col-4 text-muted mb-3">廠牌型號</div>
        <div className="col-8 text-primary">{`${supNo} - ${mara}`}</div>

        <div className="col-4 text-muted mb-3">IMEI / Serial No.</div>
        <div className="col-8 text-primary">{imei}</div>

        <div className="col-4 text-muted mb-3">繳款結果</div>
        <div className="col-8 text-primary">{paymentStatus}</div>
      </section>

      <section className="my-3 d-flex justify-content-between">
        <span>&nbsp;</span>
        <button type="button" className="btn btn-secondary" onClick={() => props.history.push('/system/home')}>投保完成</button>
      </section>
    </AirpodsLayout>
  )
}