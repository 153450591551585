import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import toastr from 'toastr'

import { SignatureCanvas, SignaturePad } from 'mobileApp/common/component/signature'
import { ltcStore, deviceStore, authStore } from 'mobileApp/store'
import { LtcLayout } from 'mobileApp/ltc/layout'

export const S07SmSignature = observer(() => {
  const { name } = authStore.currUser
  const [disabled, setDisabled] = useState(false)
  const canvasRef = useRef<SignatureCanvas>(null)
  useEffect(() => {
    ltcStore.smSignature && canvasRef.current && canvasRef.current.fromDataURL(ltcStore.smSignature)
  }, [])

  const handleClear = () => canvasRef.current!.clear()
  const handleNext = () => {
    setDisabled(true)
    if (deviceStore.isPhone) ltcStore.navi.goNext()
    else
      ltcStore.uploadSmSignature(canvasRef.current!.toDataURL()).then((statusCode) => {
        if (statusCode === 200) ltcStore.navi.goNext()
        else {
          const errMsg = statusCode === 400 ? '簽名不可空白！' : `上傳簽名檔失敗！ 錯誤碼：${statusCode}`
          toastr.error(errMsg)
          setDisabled(false)
        }
      })
  }

  return (
    <LtcLayout title={`業務員簽名（業務員姓名：${name}）`} step={3}>
      {deviceStore.isPhone ? null : (
        <SignaturePad title={`業務員簽名（業務員姓名：${name}）`} canvasRef={canvasRef} height="240px" />
      )}

      <section className="my-3 d-flex justify-content-between">
        <div>
          <button type="button" className="btn btn-light" onClick={ltcStore.navi.goBack}>
            上一步
          </button>
          {deviceStore.isPhone ? null : (
            <button type="button" className="btn btn-light ml-3" onClick={handleClear}>
              清除
            </button>
          )}
        </div>
        <button type="button" className="btn btn-secondary" disabled={disabled} onClick={handleNext}>
          下一步
        </button>
      </section>
    </LtcLayout>
  )
})
