import React, { useState, FC, ChangeEvent, ChangeEventHandler } from 'react'

import { Subject, PriceBand } from './excel'
import { TitleBar } from 'adminConsole/layout'

enum ImportType { Subject = 'SUBJECT', PriceBand = 'PRICEBAND' }

export const ExcelImport = () => {
  const [importType, setImportType] = useState<ImportType>(ImportType.Subject)

  return (
    <>
      <TitleBar title="資料匯入" />
      <section className="mb-4">
        <ImportTypeForm importType={importType} setImportType={setImportType} />
        {importType === ImportType.Subject ? <Subject /> : <PriceBand />}
      </section>
    </>
  )
}

type FormProps = { importType: ImportType, setImportType: Function }
const ImportTypeForm: FC<FormProps> = ({ importType, setImportType }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setImportType(e.target.value)

  return (
    <section className="mx-3 mt-5">
      <h6 className="mb-4">資料匯入類型</h6>
      <div className="d-flex">
        <div className="col-3">
          <RadioButtonField name="importType" id={ImportType.Subject} value={ImportType.Subject} label="商品清單" checked={importType === ImportType.Subject} onChange={handleChange} />
        </div>
        <div className="col-3">
          <RadioButtonField name="importType" id={ImportType.PriceBand} value={ImportType.PriceBand} label="保險級距" checked={importType === ImportType.PriceBand} onChange={handleChange}  />
        </div> 
      </div>
    </section>
  )
}

type RadioButtonProps = { name: string, id: ImportType, value: ImportType, label: string, checked: boolean, onChange: ChangeEventHandler }
const RadioButtonField: FC<RadioButtonProps> = ({ id, label, checked, ...props }) => {
  const labelClass = 'form-check-label' + (checked ? ' text-primary' : '')

  return (
    <div className="form-check" style={{ width: '200px' }}>
      <input type="radio" id={id} checked={checked} {...props} className="form-check-input d-none" />
      <label htmlFor={id} className={labelClass}>{label}</label>
    </div>
  )
}

