import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import toastr from 'toastr'

import { SignatureCanvas, SignaturePad } from 'mobileApp/common/component/signature'
import { mdiStore, deviceStore } from 'mobileApp/store'
import { MdiLayout } from 'mobileApp/mdi/layout'

export const S11ChSignature = observer(() => {
  const [disabled, setDisabled] = useState(false)
  const canvasRef = useRef<SignatureCanvas>(null)
  useEffect(() => {
    mdiStore.chSignature && canvasRef.current && canvasRef.current.fromDataURL(mdiStore.chSignature)
  }, [])

  const handleClear = () => canvasRef.current!.clear()
  const handleNext = () => {
    setDisabled(true)
    if (deviceStore.isPhone) mdiStore.navi.goNext()
    else
      mdiStore.uploadChSignature(canvasRef.current!.toDataURL()).then((statusCode) => {
        if (statusCode === 200) mdiStore.navi.goNext()
        else {
          const errMsg = statusCode === 400 ? '簽名不可空白！' : `上傳簽名檔失敗！ 錯誤碼：${statusCode}`
          toastr.error(errMsg)
          setDisabled(false)
        }
      })
  }

  return (
    <MdiLayout title="持卡人簽名" step={4}>
      <section>
        <Article no="注意事項：" content="本人確實收受安達產險所提供之「產險業履行個人資料保護法告知義務內容」" />
      </section>

      {deviceStore.isPhone ? null : <SignaturePad title="持卡人簽名" canvasRef={canvasRef} height="240px" />}

      <section className="my-3 d-flex justify-content-between">
        <div>
          {deviceStore.isPhone ? null : (
            <button type="button" className="btn btn-light" onClick={handleClear}>
              清除
            </button>
          )}
        </div>
        <button type="button" className="btn btn-secondary" disabled={disabled} onClick={handleNext}>
          下一步
        </button>
      </section>
    </MdiLayout>
  )
})

const Article: React.FC<{ no: string; content: string }> = ({ no, content }) => (
  <p className="d-flex fs_caption">
    <span>{no}</span>
    <span>{content}</span>
  </p>
)
