import { service } from '.'
import moment from 'moment'

export interface SubjectData { supNo: string, model: string, capacity: string, mara: string, price: string, productNo: string, productCategory: string, launchDay: string }
export interface PriceBandData { dealerNo: string, productCategory: string, paymentType: string, scopeType: string, productType: string, premium: string, priceMin: string, priceMax: string, chgDeductible: string, fixDeductible: string }

class ExcelImportStore {

  importSubjects = async (data: SubjectData[]) => {
    const importData = data.map(({ launchDay, ...item }) => ({ ...item, launchDay: moment(launchDay).format('YYYY-MM-DDTHH:mm:ss')}))
    const res = await service.importSubjects(importData)

    return res.ok ? 
      Promise.resolve() :
      Promise.reject({ message: `商品清單匯入失敗！ 錯誤代碼：${res.status}` })
  }

  importPriceBands = async (data: PriceBandData[]) => {
    const res = await service.importPriceBands(data)

    return res.ok ? 
      Promise.resolve() :
      Promise.reject({ message: `保險級距匯入失敗！ 錯誤代碼：${res.status}`})
  }
}

export const excelImportStore = new ExcelImportStore()