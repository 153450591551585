import React from 'react'
import { FormikProps, Field, ErrorMessage } from 'formik'

import { Dealer } from 'adminConsole/store'

interface Props { id: string, dealers: Dealer[], onChange?: Function, formikProps: FormikProps<any> }

export const DealerField: React.FC<Props> = ({ id, dealers, onChange, formikProps }) => {
  const { errors, touched } = formikProps
  const handleChange = (e: any) => {
    onChange && onChange(parseInt(e.target.value))
    formikProps.handleChange(e)
  }

  return (
    <div className="form-group">
      <label htmlFor={id}>經銷商</label>
      <Field component="select" name={id} onChange={handleChange} className={'form-control' + (errors[id] && touched[id] ? ' is-invalid' : '')}>
        <option value="0">請選擇</option>
        {dealers.map(dealer => <option key={dealer.id} value={dealer.id}>{dealer.name}</option>)}
      </Field>
      <ErrorMessage name={id} component="div" className="invalid-feedback" />
    </div>
  )
}