import { service } from '.'

export interface Subject { id: number | string, importSource: string, supNo: string, model: string, mara: string, capacity: string, price: number, productNo: string, productCategory: string, launchDay: string }
// const initSubject: Subject = { id: 0, importSource: 'Manual', supNo: '', model: '', mara: '', capacity: '', price: 0, productNo: '', productCategory: '', launchDay: '' }

// interface BasicSubject { supNo: string, model: string, mara: string, capacity: string, price: number, productNo: string, productCategory: string, launchDay: string, test: string }
// interface SubjectData extends BasicSubject { pk: { id: number, importSource: string }}

interface SubjectData { pk: { id: number, importSource: string }, supNo: string, model: string, mara: string, capacity: string, price: number, productNo: string, productCategory: string, launchDay: string }

// productType - phone / accessories / laptop

class SubjectStore { 
  private _subjects: Subject[] = []
  private _supNos: string[] = []

  getSubjects = async (productCategory: string) => {
    const res = await service.getSubjects(productCategory)

    if (res.status === 200) { 
      const subjectList: SubjectData[] = await res.json()
      this._subjects = subjectList.map(({ pk: { id, importSource }, ...subject }) => ({ ...subject, id, importSource }))

      const supNoSet = new Set<string>()
      this._subjects.forEach(subject => supNoSet.add(subject.supNo))
      this._supNos = Array.from(supNoSet)
      return Promise.resolve()
    }
    else return Promise.reject({ message: `無法取得商品資訊！  錯誤代碼：${res.status}`})
  }

  get supNos() { return this._supNos }
  getSubjectsBySupNo = (supNo: string) => this._subjects.filter(subject => subject.supNo === supNo)

  getSubjectById = (id: number | string) => {
    const result = this._subjects.filter(subject => subject.id === Number(id))
    return result
  }

  getSubjectByProductNo = (productNo: string) => {
    const result = this._subjects.filter(subject => subject.productNo === productNo)
    return result
  }
}

export const subjectStore = new SubjectStore()