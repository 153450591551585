import React from 'react'

// 月繳（36M）
// 安達產物行動裝置保險（分期交付巳型）
export const Clause1_36M = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">113.07.11安達商字第1130492號函備查</p>

      <section>
        <h6>第一條  保險契約之構成與解釋</h6>
        <p>本保險契約所載之條款及其他附加條款、批單或批註及與本保險契約有關之文件，均為本保險契約之構成部分。</p>
        <p>本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋為原則。</p>
      </section>

      <section>
        <h6>第二條  用詞定義</h6>
        <div>本保險契約用詞定義如下：</div>
        <p className='="d-flex'>
          <span>一、</span>
          <span>要保人：係指向保險人洽訂保險契約有繳付保險費義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。</span>
        </p>
        <p className='="d-flex'>
          <span>二、</span>
          <span>被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。</span>
        </p>
        <p className='="d-flex'>
          <span>三、</span>
          <span>行動裝置：係指要保人所有且經本公司同意承保之可攜式電子產品；經指定維修中心原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。</span>
        </p>
        <p className='="d-flex'>
          <span>四、</span>
          <span>原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。</span>
        </p>
        <p className='="d-flex'>
          <span>五、</span>
          <span>置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。</span>
        </p>
        <p className='="d-flex'>
          <span>六、</span>
          <span>產品識別碼：係指承保行動裝置之本體所載之IMEI碼或S/N序號。</span>
        </p>
        <p className='="d-flex'>
          <span>七、</span>
          <span>指定維修中心：係指本保險契約所載明之電信業者或通路商門市或維修廠商(限台澎金馬地區)。</span>
        </p>
      </section>

      <section>
        <h6>第三條  承保範圍</h6>
        <p>對於承保之行動裝置於保險期間內發生承保危險事故，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱「承保危險事故」，依承保行動裝置類別約定如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>行動電話、平板電腦、穿戴裝置：無法正常使用或因不可預料之事故所致毀損。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>筆記型電腦：因不可預料之事故所致毀損。</span>
        </p>
      </section>

      <section>
        <h6>第四條  保險期間</h6>
        <p>本保險契約之保險期間以本保險契約所載之日時為準；所載日時以中原標準時間為準。</p>
      </section>

      <section>
        <h6>第五條  不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人及被保險人故意行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>戰爭、類似戰爭(不論宣戰與否)、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於恐懼狀態。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>罷工、暴動、民眾騷擾。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。</span>
        </p>
        <p className="d-flex">
          <span>八、</span>
          <span>一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。</span>
        </p>
        <p className="d-flex">
          <span>九、</span>
          <span>因電池蝕漏、電力不足或遽增或不適當電壓或電流。</span>
        </p>
        <p className="d-flex">
          <span>十、</span>
          <span>承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。</span>
        </p>
        <p className="d-flex">
          <span>十一、</span>
          <span>資料遺失、軟體無法使用、軟體(包括操作系統與任何儲存資料)使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。</span>
        </p>
        <p className="d-flex">
          <span>十二、</span>
          <span>傳輸線、觸控筆、電線、連接器、SIM卡、記憶卡、充電器(原廠盒內配件)、耳機(原廠盒內配件)、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞(例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損)。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。</span>
        </p>
        <p className="d-flex">
          <span>十三、</span>
          <span>承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。</span>
        </p>
        <p className="d-flex">
          <span>十四、</span>
          <span>承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。</span>
        </p>
        <p className="d-flex">
          <span>十五、</span>
          <span>任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。</span>
        </p>
        <p className="d-flex">
          <span>十六、</span>
          <span>一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。</span>
        </p>
        <p className="d-flex">
          <span>十七、</span>
          <span>承保行動裝置之遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。</span>
        </p>
      </section>

      <section>
        <h6>第六條  告知義務</h6>
        <p>訂立本保險契約時，要保人對於本公司之書面(或投保網頁)詢問，應據實說明。</p>
        <p>要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。</p>
        <p>前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。</p>
      </section>

      <section>
        <h6>第七條  保險費之計收與交付</h6>
        <p>保險契約之保險費採分期繳納，並依照本保險契約所載之方式繳付。保費週期如不足一個月時，本公司按日數比例計收保險費。</p>
        <p>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付者外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。</p>
        <p>要保人就分期交付之保險費逾期未交付者，本保險契約經本公司依第九條第一項約定通知後終止。</p>
      </section>

      <section>
        <h6>第八條  契約內容之變更</h6>
        <p>本保險契約之變更申請，除另有約定外得以電話、書面、行動應用程式(APP)或網路為之，惟經本公司同意後始生效力。</p>
        <p>要保人申請終止本保險契約時，本公司應發給批單提供證明。</p>
      </section>

      <section>
        <h6>第九條  保險契約終止</h6>
        <p>本保險契約依下列情形終止時，除另有約定外，本公司就終止前已繳付之未到期保險費，應按日數比例計算無息退還予要保人：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>本公司終止保險契約時，於終止日前十五日通知要保人後終止之。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>若本公司已依本契約約定進行理賠後，已繳付之未到期保險費不予退還。</span>
        </p>
        <p>本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。</p>
      </section>

      <section>
        <h6>第十條 保險事故之發生、理賠申請與履行義務</h6>
        <p>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
        <p>被保險人申請理賠時應按下約定辦理：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>申請理賠時，若有應繳之分期保費尚未繳付，應先交付所欠繳之保險費。</span>
        </p>
      </section>

      <section>
        <h6>第十一條 理賠方式</h6>
        <p>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。</p>
      </section>

      <section>
        <h6>第十二條 自負額</h6>
        <p>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </section>

      <section>
        <h6>第十三條  代位</h6>
        <p>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。</p>
        <p>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。</p>
      </section>

      <section>
        <h6>第十四條  申訴、調解或仲裁</h6>
        <p>本公司與要保人或被保險人對於因本保險契約所生爭議時，得提出申訴或提交調解或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。</p>
      </section>

      <section>
        <h6>第十五條  管轄法院</h6>
        <p>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。</p>
      </section>

      <section>
        <h6>第十六條  法令適用</h6>
        <p>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。</p>
      </section>
    </>
  )
}

// 月繳（36M）
// 安達產物行動裝置保險（分期交付申型）
export const Clause1_36M2 = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">112.08.16安達商字第1110490號函備查</p>

      <div>
        <h6>第一條  保險契約之構成與解釋</h6>
        <p>本保險契約所載之條款及其他附加條款、批單或批註及與本保險契約有關之文件，均為本保險契約之構成部分。</p>
        <p>本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋為原則。</p>
      </div>

      <div>
        <h6>第二條  用詞定義</h6>
        <p>本保險契約用詞定義如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人：係指向保險人洽訂保險契約有繳付保險費義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>行動裝置：係指要保人向本公司所約定之電信業者或通路商購買全新之行動電話、平板電腦、穿戴裝置、AirPods、筆記型電腦，或其他經本公司同意承保之可攜式電子產品；經指定維修中心原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>產品識別碼：係指承保行動裝置之本體所載之IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>指定維修中心：係指本保險契約所載明之電信業者或通路商門市或維修廠商(限台澎金馬地區)。</span>
        </p>
      </div>

      <div>
        <h6>第三條  承保範圍</h6>
        <p>對於承保之行動裝置於保險期間內發生承保危險事故，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱「承保危險事故」，依承保行動裝置類別約定如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>行動電話、平板電腦、穿戴裝置、AirPods：無法正常使用或因不可預料之事故所致毀損。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>筆記型電腦：因不可預料之事故所致毀損。</span>
        </p>
      </div>

      <div>
        <h6>第四條  保險期間</h6>
        <p>本保險契約之保險期間以本保險契約所載之日時為準；所載日時以中原標準時間為準。</p>
      </div>

      <div>
        <h6>第五條  不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人及被保險人故意行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>戰爭、類似戰爭(不論宣戰與否)、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於恐懼狀態。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>罷工、暴動、民眾騷擾。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。</span>
        </p>
        <p className="d-flex">
          <span>八、</span>
          <span>一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。</span>
        </p>
        <p className="d-flex">
          <span>九、</span>
          <span>因電池蝕漏、電力不足或遽增或不適當電壓或電流。</span>
        </p>
        <p className="d-flex">
          <span>十、</span>
          <span>承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。</span>
        </p>
        <p className="d-flex">
          <span>十一、</span>
          <span>資料遺失、軟體無法使用、軟體(包括操作系統與任何儲存資料)使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。</span>
        </p>
        <p className="d-flex">
          <span>十二、</span>
          <span>傳輸線、觸控筆、電線、連接器、SIM卡、記憶卡、充電器(原廠盒內配件)、耳機(原廠盒內配件)、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞(例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損)。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。</span>
        </p>
        <p className="d-flex">
          <span>十三、</span>
          <span>承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。</span>
        </p>
        <p className="d-flex">
          <span>十四、</span>
          <span>承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。</span>
        </p>
        <p className="d-flex">
          <span>十五、</span>
          <span>任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。</span>
        </p>
        <p className="d-flex">
          <span>十六、</span>
          <span>一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。</span>
        </p>
        <p className="d-flex">
          <span>十七、</span>
          <span>承保行動裝置之遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。</span>
        </p>
      </div>

      <div>
        <h6>第六條  告知義務</h6>
        <p>訂立本保險契約時，要保人對於本公司之書面(或投保網頁)詢問，應據實說明。</p>
        <p>要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。</p>
        <p>前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。</p>
      </div>

      <div>
        <h6>第七條  保險費之計收與交付</h6>
        <p>保險契約之保險費採分期繳納，並依照本保險契約所載之方式繳付。保費週期如不足一個月時，本公司按日數比例計收保險費。</p>
        <p>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付者外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。</p>
        <p>要保人就分期交付之保險費逾期未交付者，本保險契約經本公司依第九條第一項約定通知後終止。</p>
      </div>

      <div>
        <h6>第八條  契約內容之變更</h6>
        <p>本保險契約之變更申請，除另有約定外得以電話、行動應用程式(APP)或網路為之，惟經本公司同意後始生效力。</p>
        <p>要保人申請終止本保險契約時，本公司應發給批單提供證明。</p>
      </div>

      <div>
        <h6>第九條  保險契約終止</h6>
        <p>本保險契約依下列情形終止時，除另有約定外，本公司就終止前已繳付之未到期保險費，應按日數比例計算無息退還予要保人：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>本公司終止保險契約時，於終止日前十五日通知要保人後終止之。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>若本公司已依本契約約定進行理賠後，已繳付之未到期保險費不予退還。</span>
        </p>
        <p>本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。</p>
      </div>

      <div>
        <h6>第十條  保險事故之發生、理賠申請與履行義務</h6>
        <p>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
        <p>被保險人申請理賠時應按下約定辦理：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>申請理賠時，若有應繳之分期保費尚未繳付，應先交付所欠繳之保險費。</span>
        </p>
      </div>

      <div>
        <h6>第十一條  理賠方式</h6>
        <p>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。</p>
      </div>

      <div>
        <h6>第十二條 自負額</h6>
        <p>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </div>

      <div>
        <h6>第十三條  代位</h6>
        <p>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。</p>
        <p>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。</p>
      </div>

      <div>
        <h6>第十四條  申訴、調解或仲裁</h6>
        <p>本公司與要保人或被保險人對於因本保險契約所生爭議時，得提出申訴或提交調解或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。</p>
      </div>

      <div>
        <h6>第十五條  管轄法院</h6>
        <p>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。</p>
      </div>

      <div>
        <h6>第十六條  法令適用</h6>
        <p>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。</p>
      </div>
    </>
  )
}

// 年繳（1Y）
// 安達產物行動裝置保險（一次交付寅型）
export const Clause1_1Y = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">113.07.11安達商字第1130494號函備查</p>

      <section>
        <h6>第一條  保險契約之構成與解釋</h6>
        <p>本保險契約所載之條款及其他附加條款、批單或批註及與本保險契約有關之文件，均為本保險契約之構成部分。</p>
        <p>本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋為原則。</p>
      </section>

      <section>
        <h6>第二條  用詞定義</h6>
        <p>本保險契約用詞定義如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人：係指向保險人洽訂保險契約有繳付保險費義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>行動裝置：係指要保人所有且經本公司同意承保之可攜式電子產品；經指定維修中心原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>產品識別碼：係指承保行動裝置之本體所載之IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>指定維修中心：係指本保險契約所載明之電信業者或通路商門市或維修廠商(限台澎金馬地區)。</span>
        </p>
      </section>

      <section>
        <h6>第三條  承保範圍</h6>
        <p>對於承保之行動裝置於保險期間內因不可預料之事故所致毀損，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。</p>
      </section>

      <section>
        <h6>第四條  保險期間</h6>
        <p>本保險契約之保險期間以本保險契約所載之日時為準；所載日時以中原標準時間為準。</p>
      </section>

      <section>
        <h6>第五條  不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人及被保險人故意行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>戰爭、類似戰爭(不論宣戰與否)、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於恐懼狀態。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>罷工、暴動、民眾騷擾。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。</span>
        </p>
        <p className="d-flex">
          <span>八、</span>
          <span>一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。</span>
        </p>
        <p className="d-flex">
          <span>九、</span>
          <span>因電池蝕漏、電力不足或遽增或不適當電壓或電流。</span>
        </p>
        <p className="d-flex">
          <span>十、</span>
          <span>承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。</span>
        </p>
        <p className="d-flex">
          <span>十一、</span>
          <span>資料遺失、軟體無法使用、軟體(包括操作系統與任何儲存資料)使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。</span>
        </p>
        <p className="d-flex">
          <span>十二、</span>
          <span>傳輸線、觸控筆、電線、連接器、SIM卡、記憶卡、充電器(原廠盒內配件)、耳機(原廠盒內配件)、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞(例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損)。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。</span>
        </p>
        <p className="d-flex">
          <span>十三、</span>
          <span>承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。</span>
        </p>
        <p className="d-flex">
          <span>十四、</span>
          <span>承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。</span>
        </p>
        <p className="d-flex">
          <span>十五、</span>
          <span>任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。</span>
        </p>
        <p className="d-flex">
          <span>十六、</span>
          <span>一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。</span>
        </p>
        <p className="d-flex">
          <span>十七、</span>
          <span>承保行動裝置之遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。</span>
        </p>
      </section>

      <section>
        <h6>第六條  告知義務</h6>
        <p>訂立本保險契約時，要保人對於本公司之書面詢問(或投保網頁)，應據實說明。</p>
        <p>要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。</p>
        <p>前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。</p>
      </section>

      <section>
        <h6>第七條  保險費之交付</h6>
        <p>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付者外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。</p>
      </section>

      <section>
        <h6>第八條  契約內容之變更</h6>
        <p>本保險契約之變更申請，除另有約定外得以電話、書面、行動應用程式(APP)或網路為之，惟經本公司同意後始生效力。</p>
        <p>要保人申請終止本保險契約時，本公司應發給批單提供證明。</p>
      </section>

      <section>
        <h6>第九條  保險契約終止與保險費之返還</h6>
        <p>本保險契約依下列情形終止時，除另有約定外，本公司就終止前已繳付之未到期保險費，應按下列方式計算無息退還予要保人：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止，本公司按短期費率計算返還未到期保險費。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>本公司終止保險契約時，於終止日前十五日通知要保人後終止之，本公司按日數比例計算返還未到期保險費。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>若本公司已依本契約約定進行理賠後，已繳付之未到期保險費不予退還。</span>
        </p>
        <p>本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。</p>
      </section>

      <section>
        <h6>第十條  保險事故之發生、理賠申請與履行義務</h6>
        <p>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
        <p>被保險人申請理賠時應按下約定辦理：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。</span>
        </p>
      </section>

      <section>
        <h6>第十一條  理賠方式</h6>
        <p>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。</p>
      </section>

      <section>
        <h6>第十二條 自負額</h6>
        <p>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </section>

      <section>
        <h6>第十三條  代位</h6>
        <p>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。</p>
        <p>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。</p>
      </section>

      <section>
        <h6>第十四條  申訴、調解或仲裁</h6>
        <p>本公司與要保人或被保險人對於因本保險契約所生爭議時，得提出申訴或提交調解或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。</p>
      </section>

      <section>
        <h6>第十五條  管轄法院</h6>
        <p>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。</p>
      </section>

      <section>
        <h6>第十六條  法令適用</h6>
        <p>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。</p>
      </section>

      <section>
        <h6>附表：短期費率表</h6>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>保險有效期間</th>
              <th>應收全年保險費之百分比</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>一個月或以下者期間對年繳保險費比</td>
              <td>15%</td>
            </tr>
            <tr>
              <td>一個月以上至二個月者期間對年繳保險費比</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>二個月以上至三個月者期間對年繳保險費比</td>
              <td>35%</td>
            </tr>
            <tr>
              <td>三個月以上至四個月者期間對年繳保險費比</td>
              <td>45%</td>
            </tr>
            <tr>
              <td>四個月以上至五個月者期間對年繳保險費比</td>
              <td>55%</td>
            </tr>
            <tr>
              <td>五個月以上至六個月者期間對年繳保險費比</td>
              <td>65%</td>
            </tr>
            <tr>
              <td>六個月以上至七個月者期間對年繳保險費比</td>
              <td>75%</td>
            </tr>
            <tr>
              <td>七個月以上至八個月者期間對年繳保險費比</td>
              <td>80%</td>
            </tr>
            <tr>
              <td>八個月以上至九個月者期間對年繳保險費比</td>
              <td>85%</td>
            </tr>
            <tr>
              <td>九個月以上至十個月者期間對年繳保險費比</td>
              <td>90%</td>
            </tr>
            <tr>
              <td>十個月以上至十一個月者期間對年繳保險費比</td>
              <td>95%</td>
            </tr>
            <tr>
              <td>十一個月以上者期間對年繳保險費比</td>
              <td>100%</td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  )
}

// 年繳（1Y）
// 安達產物行動裝置保險（一次交付申型）
export const Clause1_1Y2 = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">113.07.10安達商字第1130509號函備查</p>

      <section>
        <h6>第一條  保險契約之構成與解釋</h6>
        <p>本保險契約所載之條款及其他附加條款、批單或批註及與本保險契約有關之文件，均為本保險契約之構成部分。</p>
        <p>本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋為原則。</p>
      </section>

      <section>
        <h6>第二條  用詞定義</h6>
        <p>本保險契約用詞定義如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人：係指向保險人洽訂保險契約有繳付保險費義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>行動裝置：係指要保人所有且經本公司同意承保之可攜式電子產品，經指定維修中心原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>產品識別碼：係指承保行動裝置之本體所載之IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>指定維修中心：係指本保險契約所載明之電信業者或通路商門市或維修廠商(限台澎金馬地區)。</span>
        </p>
      </section>

      <section>
        <h6>第三條  承保範圍</h6>
        <p>對於承保之行動裝置於保險期間內因不可預料之事故所致毀損，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。</p>
      </section>

      <section>
        <h6>第四條  保險期間</h6>
        <p>本保險契約之保險期間以本保險契約所載之日時為準；所載日時以中原標準時間為準。</p>
      </section>

      <section>
        <h6>第五條  不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人及被保險人故意行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>戰爭、類似戰爭(不論宣戰與否)、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於恐懼狀態。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>罷工、暴動、民眾騷擾。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。</span>
        </p>
        <p className="d-flex">
          <span>八、</span>
          <span>一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。</span>
        </p>
        <p className="d-flex">
          <span>九、</span>
          <span>因電池蝕漏、電力不足或遽增或不適當電壓或電流。</span>
        </p>
        <p className="d-flex">
          <span>十、</span>
          <span>承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。</span>
        </p>
        <p className="d-flex">
          <span>十一、</span>
          <span>資料遺失、軟體無法使用、軟體(包括操作系統與任何儲存資料)使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。</span>
        </p>
        <p className="d-flex">
          <span>十二、</span>
          <span>傳輸線、觸控筆、電線、連接器、SIM卡、記憶卡、充電器、耳機、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞(例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損)。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。</span>
        </p>
        <p className="d-flex">
          <span>十三、</span>
          <span>承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。</span>
        </p>
        <p className="d-flex">
          <span>十四、</span>
          <span>承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。</span>
        </p>
        <p className="d-flex">
          <span>十五、</span>
          <span>任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。</span>
        </p>
        <p className="d-flex">
          <span>十六、</span>
          <span>一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。</span>
        </p>
        <p className="d-flex">
          <span>十七、</span>
          <span>承保行動裝置之遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。</span>
        </p>
      </section>

      <section>
        <h6>第六條  告知義務</h6>
        <p>訂立本保險契約時，要保人對於本公司之書面(或投保網頁)詢問，應據實說明。</p>
        <p>要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。</p>
        <p>前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。</p>
      </section>

      <section>
        <h6>第七條  保險費之交付</h6>
        <p>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付者外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。</p>
      </section>

      <section>
        <h6>第八條  契約內容之變更</h6>
        <p>本保險契約之變更申請，除另有約定外得以電話、書面、行動應用程式(APP)或網路為之，惟經本公司同意後始生效力。</p>
        <p>要保人申請終止本保險契約時，本公司應發給批單提供證明。</p>
      </section>

      <section>
        <h6>第九條  保險契約終止與保險費之返還</h6>
        <p>本保險契約依下列情形終止時，除另有約定外，本公司就終止前已繳付之未到期保險費，應按下列方式計算無息退還予要保人：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止，本公司按短期費率計算返還未到期保險費。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>本公司終止保險契約時，於終止日前十五日通知要保人後終止之，本公司按日數比例計算返還未到期保險費。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>若本公司已依本契約約定進行理賠後，已繳付之未到期保險費不予退還。</span>
        </p>
        <p>本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。</p>
      </section>

      <section>
        <h6>第十條  保險事故之發生、理賠申請與履行義務</h6>
        <p>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
        <p>被保險人申請理賠時應按下約定辦理：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。</span>
        </p>
      </section>

      <section>
        <h6>第十一條  理賠方式</h6>
        <p>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。</p>
      </section>

      <section>
        <h6>第十二條 自負額</h6>
        <p>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </section>

      <section>
        <h6>第十三條  代位</h6>
        <p>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。</p>
        <p>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。</p>
      </section>

      <section>
        <h6>第十四條  申訴、調解或仲裁</h6>
        <p>本公司與要保人或被保險人對於因本保險契約所生爭議時，得提出申訴或提交調解或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。</p>
      </section>

      <section>
        <h6>第十五條  管轄法院</h6>
        <p>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。</p>
      </section>

      <section>
        <h6>第十六條  法令適用</h6>
        <p>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。</p>
      </section>

      <section>
        <h6>附表：短期費率表</h6>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>保險有效期間</th>
              <th>應收全年保險費之百分比</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>一個月或以下者期間對年繳保險費比</td>
              <td>15%</td>
            </tr>
            <tr>
              <td>一個月以上至二個月者期間對年繳保險費比</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>二個月以上至三個月者期間對年繳保險費比</td>
              <td>35%</td>
            </tr>
            <tr>
              <td>三個月以上至四個月者期間對年繳保險費比</td>
              <td>45%</td>
            </tr>
            <tr>
              <td>四個月以上至五個月者期間對年繳保險費比</td>
              <td>55%</td>
            </tr>
            <tr>
              <td>五個月以上至六個月者期間對年繳保險費比</td>
              <td>65%</td>
            </tr>
            <tr>
              <td>六個月以上至七個月者期間對年繳保險費比</td>
              <td>75%</td>
            </tr>
            <tr>
              <td>七個月以上至八個月者期間對年繳保險費比</td>
              <td>80%</td>
            </tr>
            <tr>
              <td>八個月以上至九個月者期間對年繳保險費比</td>
              <td>85%</td>
            </tr>
            <tr>
              <td>九個月以上至十個月者期間對年繳保險費比</td>
              <td>90%</td>
            </tr>
            <tr>
              <td>十個月以上至十一個月者期間對年繳保險費比</td>
              <td>95%</td>
            </tr>
            <tr>
              <td>十一個月以上者期間對年繳保險費比</td>
              <td>100%</td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  )
}

// 年繳（2Y）
// 安達產物行動裝置保險（一次交付卯型）
export const Clause1_2Y = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">113.07.11安達商字第1130496號函備查</p>

      <div>
        <h6>第一條  保險契約之構成與解釋</h6>
        <p>本保險契約所載之條款及其他附加條款、批單或批註及與本保險契約有關之文件，均為本保險契約之構成部分。</p>
        <p>本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋為原則。</p>
      </div>

      <div>
        <h6>第二條  用詞定義</h6>
        <p>本保險契約用詞定義如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人：係指向保險人洽訂保險契約有繳付保險費義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>行動裝置：係指要保所有且經本公司同意承保之可攜式電子產品；經指定維修中心原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>產品識別碼：係指承保行動裝置之本體所載之IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>指定維修中心：係指本保險契約所載明之電信業者或通路商門市或維修廠商(限台澎金馬地區)。</span>
        </p>
      </div>

      <div>
        <h6>第三條  承保範圍</h6>
        <p>對於承保之行動裝置於保險期間內發生承保危險事故，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱「承保危險事故」，依承保行動裝置類別約定如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>行動電話、平板電腦、穿戴裝置：無法正常使用或因不可預料之事故所致毀損。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>筆記型電腦：因不可預料之事故所致毀損。</span>
        </p>
      </div>

      <div>
        <h6>第四條  保險期間</h6>
        <p>本保險契約之保險期間以本保險契約所載之日時為準；所載日時以中原標準時間為準。</p>
      </div>

      <div>
        <h6>第五條  不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人及被保險人故意行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>戰爭、類似戰爭(不論宣戰與否)、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於恐懼狀態。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>罷工、暴動、民眾騷擾。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。</span>
        </p>
        <p className="d-flex">
          <span>八、</span>
          <span>一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。</span>
        </p>
        <p className="d-flex">
          <span>九、</span>
          <span>因電池蝕漏、電力不足或遽增或不適當電壓或電流。</span>
        </p>
        <p className="d-flex">
          <span>十、</span>
          <span>承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。</span>
        </p>
        <p className="d-flex">
          <span>十一、</span>
          <span>資料遺失、軟體無法使用、軟體(包括操作系統與任何儲存資料)使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。</span>
        </p>
        <p className="d-flex">
          <span>十二、</span>
          <span>傳輸線、觸控筆、電線、連接器、SIM卡、記憶卡、充電器(原廠盒內配件)、耳機(原廠盒內配件)、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞(例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損)。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。</span>
        </p>
        <p className="d-flex">
          <span>十三、</span>
          <span>承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。</span>
        </p>
        <p className="d-flex">
          <span>十四、</span>
          <span>承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。</span>
        </p>
        <p className="d-flex">
          <span>十五、</span>
          <span>任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。</span>
        </p>
        <p className="d-flex">
          <span>十六、</span>
          <span>一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。</span>
        </p>
        <p className="d-flex">
          <span>十七、</span>
          <span>承保行動裝置之遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。</span>
        </p>
      </div>

      <div>
        <h6>第六條  告知義務</h6>
        <p>訂立本保險契約時，要保人對於本公司之書面詢問(或投保網頁)，應據實說明。</p>
        <p>要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。</p>
        <p>前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。</p>
      </div>

      <div>
        <h6>第七條  保險費之交付</h6>
        <p>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付者外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。</p>
      </div>

      <div>
        <h6>第八條  契約內容之變更</h6>
        <p>本保險契約之變更申請，除另有約定外得以電話、書面、行動應用程式(APP)或網路為之，惟經本公司同意後始生效力。</p>
        <p>要保人申請終止本保險契約時，本公司應發給批單提供證明。</p>
      </div>

      <div>
        <h6>第九條  保險契約終止與保險費之返還</h6>
        <p>本保險契約依下列情形終止時，除另有約定外，本公司就終止前已繳付之未到期保險費，應按日數比例計算扣除已發生之理賠費用後無息退還予要保人：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>本公司終止保險契約時，於終止日前十五日通知要保人後終止之。</span>
        </p>
        <p>本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。</p>
      </div>

      <div>
        <h6>第十條  保險事故之發生、理賠申請與履行義務</h6>
        <p>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
        <p>被保險人申請理賠時應按下約定辦理：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。</span>
        </p>
      </div>

      <div>
        <h6>第十一條  理賠方式</h6>
        <p>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。</p>
      </div>

      <div>
        <h6>第十二條 自負額</h6>
        <p>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </div>

      <div>
        <h6>第十三條  代位</h6>
        <p>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。</p>
        <p>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。</p>
      </div>

      <div>
        <h6>第十四條  申訴、調解或仲裁</h6>
        <p>本公司與要保人或被保險人對於因本保險契約所生爭議時，得提出申訴或提交調解或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。</p>
      </div>

      <div>
        <h6>第十五條  管轄法院</h6>
        <p>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。</p>
      </div>

      <div>
        <h6>第十六條  法令適用</h6>
        <p>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。</p>
      </div>
    </>
  )
}

// 年繳（2Y）
// 安達產物行動裝置保險（一次交付未型）
export const Clause1_2Y2 = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">112.08.16安達商字第1120489號函備查</p>

      <div>
        <h6>第一條  保險契約之構成與解釋</h6>
        <p>本保險契約所載之條款及其他附加條款、批單或批註及與本保險契約有關之文件，均為本保險契約之構成部分。</p>
        <p>本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋為原則。</p>
      </div>

      <div>
        <h6>第二條  用詞定義</h6>
        <p>本保險契約用詞定義如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人：係指向保險人洽訂保險契約有繳付保險費義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>行動裝置：係指要保人向本公司所約定之電信業者或通路商購買全新之行動電話、平板電腦、穿戴裝置、AirPods、筆記型電腦，或其他經本公司同意承保之可攜式電子產品；經指定維修中心原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>產品識別碼：係指承保行動裝置之本體所載之IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>指定維修中心：係指本保險契約所載明之電信業者或通路商門市或維修廠商(限台澎金馬地區)。</span>
        </p>
      </div>

      <div>
        <h6>第三條  承保範圍</h6>
        <p>對於承保之行動裝置於保險期間內發生承保危險事故，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱「承保危險事故」，依承保行動裝置類別約定如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>行動電話、平板電腦、穿戴裝置、AirPods：無法正常使用或因不可預料之事故所致毀損。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>筆記型電腦：因不可預料之事故所致毀損。</span>
        </p>
      </div>

      <div>
        <h6>第四條  保險期間</h6>
        <p>本保險契約之保險期間以本保險契約所載之日時為準；所載日時以中原標準時間為準。</p>
      </div>

      <div>
        <h6>第五條  不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人及被保險人故意行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>戰爭、類似戰爭(不論宣戰與否)、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於恐懼狀態。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>罷工、暴動、民眾騷擾。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。</span>
        </p>
        <p className="d-flex">
          <span>八、</span>
          <span>一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。</span>
        </p>
        <p className="d-flex">
          <span>九、</span>
          <span>因電池蝕漏、電力不足或遽增或不適當電壓或電流。</span>
        </p>
        <p className="d-flex">
          <span>十、</span>
          <span>承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。</span>
        </p>
        <p className="d-flex">
          <span>十一、</span>
          <span>資料遺失、軟體無法使用、軟體(包括操作系統與任何儲存資料)使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。</span>
        </p>
        <p className="d-flex">
          <span>十二、</span>
          <span>傳輸線、觸控筆、電線、連接器、SIM卡、記憶卡、充電器(原廠盒內配件)、耳機(原廠盒內配件)、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞(例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損)。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。</span>
        </p>
        <p className="d-flex">
          <span>十三、</span>
          <span>承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。</span>
        </p>
        <p className="d-flex">
          <span>十四、</span>
          <span>承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。</span>
        </p>
        <p className="d-flex">
          <span>十五、</span>
          <span>任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。</span>
        </p>
        <p className="d-flex">
          <span>十六、</span>
          <span>一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。</span>
        </p>
        <p className="d-flex">
          <span>十七、</span>
          <span>承保行動裝置之遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。</span>
        </p>
      </div>

      <div>
        <h6>第六條  告知義務</h6>
        <p>訂立本保險契約時，要保人對於本公司之書面詢問(或投保網頁)，應據實說明。</p>
        <p>要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。</p>
        <p>前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。</p>
      </div>

      <div>
        <h6>第七條  保險費之交付</h6>
        <p>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付者外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。</p>
      </div>

      <div>
        <h6>第八條  契約內容之變更</h6>
        <p>本保險契約之變更申請，除另有約定外得以電話、行動應用程式(APP)或網路為之，惟經本公司同意後始生效力。</p>
        <p>要保人申請終止本保險契約時，本公司應發給批單提供證明。</p>
      </div>

      <div>
        <h6>第九條  保險契約終止與保險費之返還</h6>
        <p>本保險契約依下列情形終止時，除另有約定外，本公司就終止前已繳付之未到期保險費，應按日數比例計算扣除已發生之理賠費用後無息退還予要保人：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>本公司終止保險契約時，於終止日前十五日通知要保人後終止之。</span>
        </p>
        <p>本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。</p>
      </div>

      <div>
        <h6>第十條  保險事故之發生、理賠申請與履行義務</h6>
        <p>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
        <p>被保險人申請理賠時應按下約定辦理：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。</span>
        </p>
      </div>

      <div>
        <h6>第十一條  理賠方式</h6>
        <p>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。</p>
      </div>

      <div>
        <h6>第十二條 自負額</h6>
        <p>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </div>

      <div>
        <h6>第十三條  代位</h6>
        <p>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。</p>
        <p>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。</p>
      </div>

      <div>
        <h6>第十四條  申訴、調解或仲裁</h6>
        <p>本公司與要保人或被保險人對於因本保險契約所生爭議時，得提出申訴或提交調解或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。</p>
      </div>

      <div>
        <h6>第十五條  管轄法院</h6>
        <p>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。</p>
      </div>

      <div>
        <h6>第十六條  法令適用</h6>
        <p>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。</p>
      </div>
    </>
  )
}

// 年繳（3Y）
// 安達產物行動裝置保險（一次交付亥型）
export const Clause1_3Y = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">113.09.04安達商字第1130682號函備查</p>

      <div>
        <h6>第一條  保險契約之構成與解釋</h6>
        <p>本保險契約所載之條款及其他附加條款、批單或批註及與本保險契約有關之文件，均為本保險契約之構成部分。</p>
        <p>本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋為原則。</p>
      </div>

      <div>
        <h6>第二條  用詞定義</h6>
        <p>本保險契約用詞定義如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人：係指向保險人洽訂保險契約有繳付保險費義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>行動裝置：係指要保人所有且經本公司同意承保之可攜式電子產品；經指定維修中心原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>產品識別碼：係指承保行動裝置之本體所載之IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>指定維修中心：係指本保險契約所載明之電信業者或通路商門市或維修廠商(限台澎金馬地區)。</span>
        </p>
      </div>

      <div>
        <h6>第三條  承保範圍</h6>
        <p>對於承保之行動裝置於保險期間內發生承保危險事故，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱「承保危險事故」，依承保行動裝置類別約定如下：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>行動電話、平板電腦、穿戴裝置、AirPods：無法正常使用或因不可預料之事故所致毀損。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>筆記型電腦：因不可預料之事故所致毀損。</span>
        </p>
      </div>

      <div>
        <h6>第四條  保險期間</h6>
        <p>本保險契約之保險期間以本保險契約所載之日時為準；所載日時以中原標準時間為準。</p>
      </div>

      <div>
        <h6>第五條  不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人及被保險人故意行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>戰爭、類似戰爭(不論宣戰與否)、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於恐懼狀態。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>罷工、暴動、民眾騷擾。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。</span>
        </p>
        <p className="d-flex">
          <span>六、</span>
          <span>任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。</span>
        </p>
        <p className="d-flex">
          <span>七、</span>
          <span>原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。</span>
        </p>
        <p className="d-flex">
          <span>八、</span>
          <span>一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。</span>
        </p>
        <p className="d-flex">
          <span>九、</span>
          <span>因電池蝕漏、電力不足或遽增或不適當電壓或電流。</span>
        </p>
        <p className="d-flex">
          <span>十、</span>
          <span>承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。</span>
        </p>
        <p className="d-flex">
          <span>十一、</span>
          <span>資料遺失、軟體無法使用、軟體(包括操作系統與任何儲存資料)使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。</span>
        </p>
        <p className="d-flex">
          <span>十二、</span>
          <span>傳輸線、觸控筆、電線、連接器、SIM卡、記憶卡、充電器(原廠盒內配件)、耳機(原廠盒內配件)、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞(例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損)。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。</span>
        </p>
        <p className="d-flex">
          <span>十三、</span>
          <span>承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。</span>
        </p>
        <p className="d-flex">
          <span>十四、</span>
          <span>承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。</span>
        </p>
        <p className="d-flex">
          <span>十五、</span>
          <span>任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。</span>
        </p>
        <p className="d-flex">
          <span>十六、</span>
          <span>一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。</span>
        </p>
        <p className="d-flex">
          <span>十七、</span>
          <span>承保行動裝置之遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。</span>
        </p>
      </div>

      <div>
        <h6>第六條  告知義務</h6>
        <p>訂立本保險契約時，要保人對於本公司之書面詢問(或投保網頁)，應據實說明。</p>
        <p>要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。</p>
        <p>前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。</p>
      </div>

      <div>
        <h6>第七條  保險費之交付</h6>
        <p>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付者外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。</p>
      </div>

      <div>
        <h6>第八條  契約內容之變更</h6>
        <p>本保險契約之變更申請，除另有約定外得以電話、書面、行動應用程式(APP)或網路為之，惟經本公司同意後始生效力。</p>
        <p>要保人申請終止本保險契約時，本公司應發給批單提供證明。</p>
      </div>

      <div>
        <h6>第九條  保險契約終止與保險費之返還</h6>
        <p>本保險契約依下列情形終止時，除另有約定外，本公司就終止前已繳付之未到期保險費，應按日數比例計算扣除已發生之理賠金額後無息退還予要保人：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>本公司終止保險契約時，於終止日前十五日通知要保人後終止之。</span>
        </p>
        <p>本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。</p>
      </div>

      <div>
        <h6>第十條  保險事故之發生、理賠申請與履行義務</h6>
        <p>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
        <p>被保險人申請理賠時應按下約定辦理：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。</span>
        </p>
      </div>

      <div>
        <h6>第十一條  理賠方式</h6>
        <p>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。</p>
      </div>

      <div>
        <h6>第十二條 自負額</h6>
        <p>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </div>

      <div>
        <h6>第十三條  代位</h6>
        <p>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。</p>
        <p>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。</p>
      </div>

      <div>
        <h6>第十四條  申訴、調解或仲裁</h6>
        <p>本公司與要保人或被保險人對於因本保險契約所生爭議時，得提出申訴或提交調解或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。</p>
      </div>

      <div>
        <h6>第十五條  管轄法院</h6>
        <p>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。</p>
      </div>

      <div>
        <h6>第十六條  法令適用</h6>
        <p>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。</p>
      </div>
    </>
  )
}

// 安達產物行動裝置竊盜損失附加條款（J）
export const Clause2_J = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之竊盜搶奪強盜損失</h5>
      <p className="text-right">113.07.11安達商字第1130493號函備查</p>

      <section>
        <h6>第一條 承保範圍</h6>
        <p>茲經雙方同意，要保人投保安達產物行動裝置保險(以下簡稱主保險契約)後，加保安達產物行動裝置竊盜損失附加條款(J)(以下簡稱本附加條款)，對於主保險契約承保之行動裝置於保險期間內因竊盜、搶奪、強盜所致之整機滅失，本公司以置換方式進行理賠，理賠次數與主保險契約併計以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱之置換方式依主保險契約辦理。</p>
      </section>

      <section>
        <h6>第二條 不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之滅失時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>被保險人之故意或犯罪行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人因承保行動裝置之滅失所致之任何性質之附帶損失。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>承保行動裝置之遺失(被保險人無法證明承保行動裝置之整機滅失係由竊盜、搶奪、強盜所致者或未取得警察機關事故證明視為遺失)。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>竊盜、搶奪、強盜係由被保險人之三親等內血親或姻親所為者。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>被保險人未於知悉行動裝置遭遇竊盜、搶奪或強盜後48小時內向警察機關報案並向電信公司辦理停話手續，但有正當理由者，不在此限。</span>
        </p>
      </section>

      <section>
        <h6>第三條 自負額</h6>
        <p>被保險人於本附加條款保險期間內發生承保範圍內之滅失時，對於每一次事故，須先負擔本附加條款所約定之自負額。</p>
      </section>

      <section>
        <h6>第四條 保險事故發生後之處理</h6>
        <p>被保險人於知悉保險事故發生後，除有正當理由外，應於48小時內向警察機關報案，取得警察機關事故證明並向電信業者辦理暫停通話手續。</p>
      </section>

      <section>
        <h6>第五條 理賠申請文件</h6>
        <p>依本附加條款約定向本公司申請理賠時，應檢附下列文件：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>警察機關刑事報案證明，如損失係發生在海外者，應檢附當地警察機關開具之報案證明。上述證明文件需載明行動裝置之行動通訊國際辨識碼IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
      </section>

      <section>
        <h6>第六條 理賠後之處分權</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經本公司賠付後，本公司並即取得對該殘餘物之處分權，但該殘餘物如仍有未了責任或義務應由被保險人自行處理，本公司並不因取得該殘餘物之處分權，而隨同移轉予本公司承受。</p>
      </section>

      <section>
        <h6>第七條 承保行動裝置尋回之處理</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經賠付後尋回者，被保險人應於知悉後盡速以書面或其他適當方式通知本公司，並應負責於領回後交付予本公司。</p>
      </section>

      <section>
        <h6>第八條 條款之適用</h6>
        <p>本附加條款所記載之事項，如與主保險契約約定牴觸時，依本附加條款之約定辦理，其他事項仍適用主保險契約條款之約定。</p>
      </section>
    </>
  )
}

// 安達產物行動裝置竊盜損失附加條款（一次交付I）
export const Clause2_I = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之竊盜搶奪強盜損失</h5>
      <p className="text-right">113.07.11安達商字第1130495號函備查</p>

      <section>
        <h6>第一條 承保範圍</h6>
        <p>茲經雙方同意，要保人投保安達產物行動裝置保險(一次交付)(以下簡稱主保險契約)後，加保安達產物行動裝置竊盜損失附加條款(一次交付I) (以下簡稱本附加條款)，對於主保險契約承保之行動裝置於保險期間內因竊盜、搶奪、強盜所致之整機滅失，本公司以置換方式進行理賠，理賠次數與主保險契約併計以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱之置換方式依主保險契約辦理。</p>
      </section>

      <section>
        <h6>第二條 不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之滅失時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>被保險人之故意或犯罪行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人因承保行動裝置之滅失所致之任何性質之附帶損失。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>承保行動裝置之遺失(被保險人無法證明承保行動裝置之整機滅失係由竊盜、搶奪、強盜所致者或未取得警察機關事故證明視為遺失)。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>竊盜、搶奪、強盜係由被保險人之三親等內血親或姻親所為者。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>被保險人未於知悉行動裝置遭遇竊盜、搶奪或強盜後48小時內向警察機關報案並向電信公司辦理停話手續，但有正當理由者，不在此限。</span>
        </p>
      </section>

      <section>
        <h6>第三條 自負額</h6>
        <p>被保險人於本附加條款保險期間內發生承保範圍內之滅失時，對於每一次事故，須先負擔本附加條款所約定之自負額。</p>
      </section>

      <section>
        <h6>第四條 保險事故發生後之處理</h6>
        <p>被保險人於知悉保險事故發生後，除有正當理由外，應於48小時內向警察機關報案，取得警察機關事故證明並向電信業者辦理暫停通話手續。</p>
      </section>

      <section>
        <h6>第五條 理賠申請文件</h6>
        <p>依本附加條款約定向本公司申請理賠時，應檢附下列文件：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>警察機關刑事報案證明，如損失係發生在海外者，應檢附當地警察機關開具之報案證明。上述證明文件需載明行動裝置之行動通訊國際辨識碼IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
      </section>

      <section>
        <h6>第六條 理賠後之處分權</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經本公司賠付後，本公司並即取得對該殘餘物之處分權，但該殘餘物如仍有未了責任或義務應由被保險人自行處理，本公司並不因取得該殘餘物之處分權，而隨同移轉予本公司承受。</p>
      </section>

      <section>
        <h6>第七條 承保行動裝置尋回之處理</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經賠付後尋回者，被保險人應於知悉後盡速以書面或其他適當方式通知本公司，並應負責於領回後交付予本公司。</p>
      </section>

      <section>
        <h6>第八條 條款之適用</h6>
        <p>本附加條款所記載之事項，如與主保險契約約定牴觸時，依本附加條款之約定辦理，其他事項仍適用主保險契約條款之約定。</p>
      </section>
    </>
  )
}

// 安達產物行動裝置竊盜損失附加條款（L）
export const Clause2_L = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之竊盜搶奪強盜損失</h5>
      <p className="text-right">112.08.16安達商字第1120491號函備查</p>

      <section>
        <h6>第一條 承保範圍</h6>
        <p>茲經雙方同意，要保人投保安達產物行動裝置保險(以下簡稱主保險契約)後，加保安達產物行動裝置竊盜損失附加條款(L)(以下簡稱本附加條款)，對於主保險契約承保之行動裝置於保險期間內因竊盜、搶奪、強盜所致之整機滅失，本公司以置換方式進行理賠，理賠次數與主保險契約併計以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱之置換方式依主保險契約第二條第五項辦理。</p>
      </section>

      <section>
        <h6>第二條 不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之滅失時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>被保險人之故意或犯罪行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人因承保行動裝置之滅失所致之任何性質之附帶損失。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>承保行動裝置之遺失(被保險人無法證明承保行動裝置之整機滅失係由竊盜、搶奪、強盜所致者或未取得警察機關事故證明視為遺失)。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>竊盜、搶奪、強盜係由被保險人之三親等內血親或姻親所為者。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>被保險人未於知悉行動裝置遭遇竊盜、搶奪或強盜後48小時內向警察機關報案並向電信公司辦理停話手續，但有正當理由者，不在此限。</span>
        </p>
      </section>

      <section>
        <h6>第三條 自負額</h6>
        <p>被保險人於本附加條款保險期間內發生承保範圍內之滅失時，對於每一次事故，須先負擔本附加條款所約定之自負額。</p>
      </section>

      <section>
        <h6>第四條 保險事故發生後之處理</h6>
        <p>被保險人於知悉保險事故發生後，除有正當理由外，應於48小時內向警察機關報案，取得警察機關事故證明並向電信業者辦理暫停通話手續。</p>
      </section>

      <section>
        <h6>第五條 理賠申請文件</h6>
        <p>依本附加條款約定向本公司申請理賠時，應檢附下列文件：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>警察機關刑事報案證明，如損失係發生在海外者，應檢附當地警察機關開具之報案證明。上述證明文件需載明行動裝置之行動通訊國際辨識碼IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
      </section>

      <section>
        <h6>第六條 理賠後之處分權</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經本公司賠付後，本公司並即取得對該殘餘物之處分權，但該殘餘物如仍有未了責任或義務應由被保險人自行處理，本公司並不因取得該殘餘物之處分權，而隨同移轉予本公司承受。</p>
      </section>

      <section>
        <h6>第七條 承保行動裝置尋回之處理</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經賠付後尋回者，被保險人應於知悉後盡速以書面或其他適當方式通知本公司，並應負責於領回後交付予本公司。</p>
      </section>

      <section>
        <h6>第八條 條款之適用</h6>
        <p>本附加條款所記載之事項，如與主保險契約約定牴觸時，依本附加條款之約定辦理，其他事項仍適用主保險契約條款之約定。</p>
      </section>
    </>
  )
}

// 安達產物行動裝置竊盜損失附加條款（P）
export const Clause2_P = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之竊盜搶奪強盜損失</h5>
      <p className="text-right">113.09.04安達商字第1130684號函備查</p>

      <section>
        <h6>第一條 承保範圍</h6>
        <p>茲經雙方同意，要保人投保安達產物行動裝置保險(以下簡稱主保險契約)後，加保安達產物行動裝置竊盜損失附加條款(P) (以下簡稱本附加條款)，對於主保險契約承保之行動裝置於保險期間內因竊盜、搶奪、強盜所致之整機滅失，本公司以置換方式進行理賠，理賠次數與主保險契約併計以本保險契約所載之累計最高賠償次數為限。</p>
        <p>前項所稱之置換方式依主保險契約辦理。</p>
      </section>

      <section>
        <h6>第二條 不保事項</h6>
        <p>本公司對於承保行動裝置直接或間接因下列事項所致之滅失時，不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>被保險人之故意或犯罪行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>被保險人因承保行動裝置之滅失所致之任何性質之附帶損失。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>承保行動裝置之遺失(被保險人無法證明承保行動裝置之整機滅失係由竊盜、搶奪、強盜所致者或未取得警察機關事故證明視為遺失)。</span>
        </p>
        <p className="d-flex">
          <span>四、</span>
          <span>竊盜、搶奪、強盜係由被保險人之三親等內血親或姻親所為者。</span>
        </p>
        <p className="d-flex">
          <span>五、</span>
          <span>被保險人未於知悉行動裝置遭遇竊盜、搶奪或強盜後48小時內向警察機關報案並向電信公司辦理停話手續，但有正當理由者，不在此限。</span>
        </p>
      </section>

      <section>
        <h6>第三條 自負額</h6>
        <p>被保險人於本附加條款保險期間內發生承保範圍內之滅失時，對於每一次事故，須先負擔本附加條款所約定之自負額。</p>
      </section>

      <section>
        <h6>第四條 保險事故發生後之處理</h6>
        <p>被保險人於知悉保險事故發生後，除有正當理由外，應於48小時內向警察機關報案，取得警察機關事故證明並向電信業者辦理暫停通話手續。</p>
      </section>

      <section>
        <h6>第五條 理賠申請文件</h6>
        <p>依本附加條款約定向本公司申請理賠時，應檢附下列文件：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>警察機關刑事報案證明，如損失係發生在海外者，應檢附當地警察機關開具之報案證明。上述證明文件需載明行動裝置之行動通訊國際辨識碼IMEI碼或S/N序號。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>必要時本公司得要求檢附其他證明文件。</span>
        </p>
      </section>

      <section>
        <h6>第六條 理賠後之處分權</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經本公司賠付後，本公司並即取得對該殘餘物之處分權，但該殘餘物如仍有未了責任或義務應由被保險人自行處理，本公司並不因取得該殘餘物之處分權，而隨同移轉予本公司承受。</p>
      </section>

      <section>
        <h6>第七條 承保行動裝置尋回之處理</h6>
        <p>承保行動裝置發生本附加條款承保範圍之整機滅失，經賠付後尋回者，被保險人應於知悉後盡速以書面或其他適當方式通知本公司，並應負責於領回後交付予本公司。</p>
      </section>

      <section>
        <h6>第八條 條款之適用</h6>
        <p>本附加條款所記載之事項，如與主保險契約約定牴觸時，依本附加條款之約定辦理，其他事項仍適用主保險契約條款之約定。</p>
      </section>
    </>
  )
}

// 安達產物行動裝置保險電池維修置換附加條款（G）
export const Clause3_G = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">113.09.04安達商字第1130683號函備查</p>

      <section>
        <h6>第一條 承保範圍</h6>
        <p>茲經雙方同意，要保人投保安達產物行動裝置保險(以下簡稱主保險契約)後，加繳保險費，投保安達產物行動裝置保險電池維修置換附加條款(G)(以下簡稱本附加條款)，於本附加條款有效期間內，主保險契約承保之行動裝置因電池容量低於約定之置換標準致無法正常運作時，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心，本公司得以原機維修或置換方式進行理賠。理賠次數以本保險契約所載之本附加條款累計最高賠償次數為限。</p>
        <p>本附加條款所稱電池之置換標準，係指承保行動裝置內建蓄電池容量經指定維修中心檢測低於原規格標示之特定比率，該特定比率於Apple品牌產品為80%，於非Apple品牌產品為65%。</p>
      </section>

      <section>
        <h6>第二條 不保事項</h6>
        <p>本公司對於下列事項所致損失不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>被保險人之故意或犯罪行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>承保行動裝置之電池遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之電池滅失。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>被保險人因發生本附加條款承保事故所致任何性質之附帶損失(Consequential Loss)。</span>
        </p>
      </section>

      <section>
        <h6>第三條 理賠方式</h6>
        <p>承保行動裝置發生本附加條款約定之承保事故，本公司將依照原廠授權方式以原機維修為原則，若電池停產、缺貨或其他原因無法原機維修時，以主保險契約約定之置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置所有權。若同款行動裝置停產、缺貨或其他原因致本公司無法提供同款之行動裝置時，本公司有權以類似規格之行動裝置替代之。</p>
      </section>

      <section>
        <h6>第四條 自負額</h6>
        <p>被保險人對於保險期間內就本附加條款所承保之每一保險事故，須先負擔本附加條款所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </section>

      <section>
        <h6>第五條 理賠文件</h6>
        <p>被保險人應於本保險契約有效期間內，將承保行動裝置整組送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附文件正本及其他證明文件。</span>
        </p>
      </section>

      <section>
        <h6>第六條 條款之適用</h6>
        <p>本附加條款所記載之事項，如與主保險契約條款牴觸時，依本附加條款約定辦理，其他事項仍適用主保險契約條款之約定。</p>
      </section>
    </>
  )
}

// 安達產物行動裝置保險電池維修置換附加條款（E）
export const Clause3_E = () => {
  return (
    <>
      <h5 className="text-center">承保行動裝置之原機維修或置換</h5>
      <p className="text-right">112.08.16安達商字第1120492號函備查</p>

      <section>
        <h6>第一條 承保範圍</h6>
        <p>茲經雙方同意，要保人投保安達產物行動裝置保險(以下簡稱主保險契約)後，加繳保險費，投保安達產物行動裝置保險電池維修置換附加條款(E)(以下簡稱本附加條款)，於本附加條款有效期間內，主保險契約承保之行動裝置因電池容量低於約定之置換標準致無法正常運作時，被保險人至遲應於保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心，本公司得以原機維修或置換方式進行理賠。理賠次數以本保險契約所載之本附加條款累計最高賠償次數為限。</p>
        <p>本附加條款所稱電池之置換標準，係指承保行動裝置內建蓄電池容量經指定維修中心檢測低於原規格標示之特定比率，該特定比率於Apple品牌產品為80%，於非Apple品牌產品為65%。</p>
      </section>

      <section>
        <h6>第二條 不保事項</h6>
        <p>本公司對於下列事項所致損失不負賠償責任：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>被保險人之故意或犯罪行為。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>承保行動裝置之電池遺失；或因竊盜、搶奪、強盜行為所致承保行動裝置之電池滅失。</span>
        </p>
        <p className="d-flex">
          <span>三、</span>
          <span>被保險人因發生本附加條款承保事故所致任何性質之附帶損失(Consequential Loss)。</span>
        </p>
      </section>

      <section>
        <h6>第三條 理賠方式</h6>
        <p>承保行動裝置發生本附加條款約定之承保事故，本公司將依照原廠授權方式以原機維修為原則，若電池停產、缺貨或其他原因無法原機維修時，以主保險契約約定之置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置所有權。若同款行動裝置停產、缺貨或其他原因致本公司無法提供同款之行動裝置時，本公司有權以類似規格之行動裝置替代之。</p>
      </section>

      <section>
        <h6>第四條 自負額</h6>
        <p>被保險人對於保險期間內就本附加條款所承保之每一保險事故，須先負擔本附加條款所約定之自負額，本公司僅對超過自負額之部分負賠償之責。</p>
      </section>

      <section>
        <h6>第五條 理賠文件</h6>
        <p>被保險人應於本保險契約有效期間內，將承保行動裝置整組送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：</p>
        <p className="d-flex">
          <span>一、</span>
          <span>理賠申請書或經本公司同意以其他方式為之。</span>
        </p>
        <p className="d-flex">
          <span>二、</span>
          <span>必要時本公司得要求檢附文件正本及其他證明文件。</span>
        </p>
      </section>

      <section>
        <h6>第六條 條款之適用</h6>
        <p>本附加條款所記載之事項，如與主保險契約條款牴觸時，依本附加條款約定辦理，其他事項仍適用主保險契約條款之約定。</p>
      </section>
    </>
  )
}

// 財產保險投保人須知 20210805 ver.
export const ClauseInsurancePolicy = () => (
  <>
    <h5 className="text-center">財產保險投保人須知</h5>

    <section>
      <p>玆依據「金融消費者保護法」及「金融服務業提供金融商品或服務前說明契約重要內容及揭露風險辦法」之規定，美商安達產物保險股份有限公司台灣分公司（下稱本分公司）敬告要（被）保險人（下稱貴客戶）於投保前須特別注意下列事項：</p>
    </section>

    <section>
      <p className="d-flex">
        <span>一、</span>
        <span>投保時，業務員會告知您授權範圍及提供審閱保險單條款。為明瞭投保的內容，貴客戶請務必要求業務員出示相關文件並詳細告知，以確保本身的權益。</span>
      </p>
      <p className="d-flex">
        <span>二、</span>
        <span>要保人及被保險人應誠實告知相關事項，若有違反告知義務時，本分公司得解除保險契約；保險事故發生後亦同。保險契約係最大誠信契約，故要保人及被保險人對於要保書書面詢問事項應據實說明，不得有隱匿或遺漏不為說明，或為不實之說明。若因此以致減少本分公司變更或減少危險之估計時，依保險法第64條規定，本分公司有解除契約之權，同時可依同法第25條規定無須返還已繳交之保險費。</span>
      </p>
      <p className="d-flex">
        <span>三、</span>
        <span>除外責任：保險公司依照保險法的規定，對於被保險人的故意行為，不負給付保險金之責。此外，在保險單條款中會有詳細的除外責任及不保事項，消費者請務必審閱清楚。</span>
      </p>
      <p className="d-flex">
        <span>四、</span>
        <span>
          <span>貴客戶對保險契約之權利、行使、變更及終止方式：</span>
          <span className="d-flex">
            <span>（一）</span>
            <span>
              <span>權利行使：</span>
              <span className="d-flex">
                <span>１、</span>
                <span>被保險標的發生保險契約承保範圍之事故時，貴客戶或受益人應依保險法相關法令與保險契約之約定與程序通知本分公司。</span>
              </span>
              <span className="d-flex">
                <span>２、</span>
                <span>由保險契約所生之權利，自得為請求之日起，經二年不行使而消滅。</span>
              </span>
            </span>
          </span>
          <span className="d-flex">
            <span>（二）</span>
            <span>契約變更、解除及終止：貴客戶得隨時以書面通知本分公司，辦理變更、解除及終止保險契約，但非經雙方同意且經本分公司簽批者不生效力。</span>
          </span>
        </span>
      </p>
      <p className="d-flex">
        <span>五、</span>
        <span>本分公司對於保險契約之重要權利、義務及責任：本分公司依據主管機關核定之各項規定承保並收取保險費；於承保危險事故發生時，依契約及相關法令規定，依承保責任負給付保險金之義務。</span>
      </p>
      <p className="d-flex">
        <span>六、</span>
        <span>貴客戶於繳交保險費外，無須另繳交其他費用或違約金。</span>
      </p>
      <p className="d-flex">
        <span>七、</span>
        <span>本分公司保險商品皆依保險相關法令辦理，並受財團法人保險安定基金之保障。</span>
      </p>
      <p className="d-flex">
        <span>八、</span>
        <span>因投保契約所生紛爭之處理方式及申訴之管道：要保人或被保險人因投保之保險契約發生爭議，可以向本分公司（免費申訴電話：0800608989）、依金融消費者保護法規設立之爭議處理機構或金融監督管理委員會申訴。</span>
      </p>
      <p className="d-flex">
        <span>九、</span>
        <span>本分公司依美國、聯合國、歐盟及中華民國等經濟制裁相關法令規定，就涉及古巴、伊朗、北韓、蘇丹、敘利亞、克里米亞共和國、委內瑞拉或其他制裁國家所發生之保險事故，本分公司不予承保及理賠。</span>
      </p>
    </section>
  </>
)