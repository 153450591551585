import { service } from '.'

export interface Salesman {
  id: number, name: string, nationId: string,
  email: string, uno: string, status: boolean,
  dealerId: number, dealerName: string,
  storeId: number, storeName: string,
  score: string,
}

export const emptySalesman = { id: 0, name: '', nationId: '', email: '', uno: '', status: true, dealerId: 0, storeId: 0, dealerName: '', storeName: '', score: '' }

class SalesStore {
  private _salesmen: Salesman[] = []
  private _salesman: Salesman = { ...emptySalesman }

  get salesmen() { return this._salesmen }
  get salesman() { return this._salesman }

  loadAll = async () => {
    const res = await service.getSaless()
    if (res.status === 200) {
      this._salesmen = await res.json()
      return Promise.resolve()
    }
    else if (res.status === 401) { }
    else return Promise.reject({ message: `無法取得業務員資訊！ 錯誤代碼：${res.status}` })
  }

  loadOne = async (id: number) => {
    if (id === 0) {
      this._salesman = { ...emptySalesman }
      return Promise.resolve()
    }

    const res = await service.getSales(id)
    if (res.status === 200) {
      this._salesman = await res.json()
      return Promise.resolve()
    }

    return Promise.reject({ message: `無法取得業務員資訊！ 錯誤代碼：${res.status}` })
  }

  save = async (salesman: Salesman) => {
    const res = await (salesman.id === 0 ?
      service.addSales(salesman.name, salesman.uno, salesman.nationId, salesman.email, salesman.dealerId, salesman.storeId, salesman.dealerName, salesman.storeName) :
      service.updateSales(salesman.id, salesman.name, salesman.uno, salesman.nationId, salesman.email, salesman.status, salesman.dealerId, salesman.storeId, salesman.dealerName, salesman.storeName))

    if (res.status === 200) return Promise.resolve()
    else return Promise.reject({ message: `無法儲存！　錯誤代碼：${res.status}` })
  }

  getExamResult = async (dealerId: number, filename: string) => {
    const res = await service.getExamResult(dealerId)
    if (res.status === 200) {
      const buffer = await res.arrayBuffer()
      const url = URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      link.click()
      Promise.resolve()
    }
    else return Promise.reject({ message: `無法取得業務員測驗結果！ 錯誤代碼：${res.status}` })
  }
}

export const salesStore = new SalesStore()