import moment from 'moment'
import * as Yup from 'yup'

// 檢核身分證字號
export const checkTaiwanIndentity = (id: string) => {
  if (!id) return false
  if (id.length !== 10) return false

  const tab = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
  const A1 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3]
  const A2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5]
  const Mx = [9, 8, 7, 6, 5, 4, 3, 2, 1, 1]

  let i = tab.indexOf(id.charAt(0));
  if (i === -1) return false;
  let sum = A1[i] + A2[i] * 9;

  for (i = 1; i < 10; i++) {
    const v = parseInt(id.charAt(i));
    if (isNaN(v)) return false;
    sum = sum + v * Mx[i];
  }
  if (sum % 10 !== 0) return false;
  return true;
}

// 檢核外國人統一證號
export const checkForeignIndentity = (id: string) => {
  if (!id) return false
  if (id.length !== 10) return false

  const tab = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
  const A1 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3]
  const A2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5]
  const Mx = [9, 8, 7, 6, 5, 4, 3, 2, 1]

  let p = tab.indexOf(id.charAt(0))
  if (p === -1) return false
  let sum = A1[p] + ((A2[p] * 9) % 10)

  let n = tab.indexOf(id.charAt(1))
  if (n === -1) return false
  sum = sum + ((A2[n] * 8) % 10)

  for (let i = 2; i < 9; i++) {
    const v = parseInt(id.charAt(i))
    if (isNaN(v)) return false
    sum = sum + (v * Mx[i]) % 10
  }

  if ((10 - parseInt(id.charAt(9))) !== sum % 10) return false
  return true
}

// 檢核是否年滿18歲
export const checkAdultCriterion = (birthday: string) => {
  if (!birthday) return false

  const criteria = moment().subtract(18, 'years')
  if (moment(birthday).isSameOrBefore(criteria)) return true
  else return false
}

// 檢核保險員證號
export const checkUno = function (this: { resolve: Function }, uno: string) {
  // console.log(this.resolve(Yup.ref('nationId')))
  // console.log(this)

  const nationId = this.resolve(Yup.ref('nationId'))
  if (!uno) return false
  if (uno.charAt(0) === nationId.charAt(0)) return true
  else return false
}

// 檢核 IMEI 
export const checkImei = (imei: string) => {
  if (!imei) return false
  const imeiFirst = imei.charAt(0)
  const regex = RegExp('[a-z]')

  if ((!imeiFirst.match(regex)) && (!RegExp(/^\d{15}$/).test(imei)) && (!RegExp(/^\d{17}$/).test(imei))) return false
  else return true
}