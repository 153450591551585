import React from 'react'
import moment from 'moment'

import { dealerStore, storeStore, policyStore, dailyReportStore, DailyReportItem, } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { SingleDatePicker, DealerSelector, StoreSelector, ExportButton } from 'adminConsole/component'

enum Code { Loading, Ready, Failed }

export const ReportDaily: React.FC = props => {
  const [status, setStatus] = React.useState({ code: Code.Loading, message: '' })
  const defaultDate = moment().subtract(1, 'day')
  const [reportDate, setReportDate] = React.useState(defaultDate)
  const [dealerId, setDealerId] = React.useState(0)
  const [storeId, setStoreId] = React.useState(0)

  React.useEffect(() => {
    setStatus({ code: Code.Loading, message: '' })
    Promise.all([
      dealerStore.loadAll(),
      storeStore.loadAll(),
      dailyReportStore.loadAll(reportDate),
    ]).then(() => setStatus({ code: Code.Ready, message: '' }))
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [reportDate])

  const handleDealerChange = (dealerId: number) => {
    setDealerId(dealerId)
    setStoreId(0)
  }
  const handleStoreChange = (storeId: number) => setStoreId(storeId)

  const { dealers } = dealerStore
  const stores = storeStore.findByDealerId(dealerId)
  const items = dailyReportStore.items
    .filter(item => dealerId === 0 ? true : item.dealerId === dealerId)
    .filter(item => storeId === 0 ? true : item.storeId === storeId)

  return (
    <>
      <TitleBar title="每日進件" />
      <section className="form-row mb-4">
        <div className="col-3">
          <SingleDatePicker date={reportDate} onChange={setReportDate} />
        </div>
        <div className="col-3">
          <DealerSelector dealers={dealers} value={dealerId} onChange={handleDealerChange} />
        </div>
        <div className="col-3">
          <StoreSelector stores={stores} value={storeId} onChange={handleStoreChange} />
        </div>
        <div className="col-3 text-right">
          <ExportButton loadDataFn={policyStore.loadDailyPolicyInfo} query={reportDate} filename={`要保書 - ${reportDate.format('YYYYMMDD')}.csv`} />
        </div>
      </section>

      <section>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">經銷商名稱</th>
              <th scope="col">門市名稱</th>
              <th scope="col">進件</th>
            </tr>
          </thead>
          <tbody>
            {
              status.code !== Code.Loading ? status.code !== Code.Ready ?
                <FailedMsg msg={status.message} /> :
                <Rows items={items} /> :
                <Loading />
            }
          </tbody>
        </table>
      </section>
    </>
  )

}

const Loading: React.FC = () => (
  <tr>
    <td colSpan={3} className="text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </td>
  </tr>
)

const FailedMsg: React.FC<{ msg: String }> = ({ msg }) => (
  <tr><td colSpan={3} className="text-danger">{msg}</td></tr>
)

const Rows: React.FC<{ items: DailyReportItem[] }> = ({ items }) => {
  return (
    <>
      {items.map(item => (
        <tr key={item.id}>
          <td>{item.dealerName}</td>
          <td>{item.storeName}</td>
          <td>{item.caseCount}</td>
        </tr>
      ))}
    </>
  )
}