import React, { useState, useEffect, FC } from 'react'
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik'
import * as Yup from 'yup'

import { ltcStore, LaptopInfo, subjectStore, ltcProductCategory } from 'mobileApp/store'
import { LtcLayout } from 'mobileApp/ltc/layout'
import { LaptopBarcodeReader } from 'mobileApp/common/component/barcode'

enum Code { Loading, Ready, Failed }

const receiptRegEx = /^[A-Za-z]{2}\d{8}$/

export const S02LaptopInfo = () => {
  const [status, setStatus] = useState({ code: Code.Loading, message: '' })

  useEffect(() => {
    subjectStore.getSubjects(ltcProductCategory)
      .then(() => { setStatus({ code: Code.Ready, message: '' })})
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [])

  return (
    <LtcLayout title="商品資訊" step={1}>
      {status.code !== Code.Loading ? status.code !== Code.Ready ?
        <FailedMsg msg={status.message} /> :
        <LaptopInfoFormik /> :
        <Loading />
      }
    </LtcLayout>
  )
}

const Loading = () => (
  <div className="row">
    <div className="col-12 text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </div>
  </div>
)

const FailedMsg: FC<{ msg: String }> = ({ msg }) => (
  <div className="row"><div className="col-12 text-danger">{msg}</div></div>
)

const LaptopInfoFormik = () => {
  return (
    <Formik
      initialValues={ltcStore.laptopInfo}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        const subjectPrice = subjectStore.getSubjectById(values.subjectId)[0]?.price
        setStatus()

        // 上傳前的將 發票號碼 的英文轉為大寫
        values.mbInvoiceNum = values.mbInvoiceNum.toUpperCase()

        if (values.sellPrice && (values.sellPrice < subjectPrice * 0.8 || values.sellPrice > subjectPrice * 1.2)) {
          setStatus('此商品未符合美商安達核保規範，很抱歉將無法進行投保作業！')
          setSubmitting(false)
        } else {
          ltcStore.laptopInfo = values
          ltcStore.navi.goNext()
        }
      }}
      validationSchema={Yup.object().shape({
        imei: Yup.string().required('必填欄位！').lowercase().max(50, '請輸入有效的 Serial No.！'),
        poProductNo: Yup.string().required('必填欄位！'),
        supNo: Yup.string().required('必填欄位！'),
        mara: Yup.string().required('必填欄位！'),
        model: Yup.string().required('必填欄位！'),
        sellPrice: Yup.number().required('必填欄位！'),
        mbInvoiceNum: Yup.string().required('必填欄位！').matches(receiptRegEx, '請輸入有效的發票號碼！'),
      })}
      component={(formikProps) => LaptopInfoForm(formikProps)}
    />
  )
}

const LaptopInfoForm = ({ errors, touched, status, isSubmitting, setFieldValue, handleChange }: FormikProps<LaptopInfo>) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const handleProductNoChange = (e: any) => {
    handleChange(e)
    const matchedSubject = subjectStore.getSubjectByProductNo(e.target.value)

    if (matchedSubject.length > 0) {
      const { id, model, mara, supNo, price, importSource } = matchedSubject[0]
      setFieldValue('subjectId', id)
      setFieldValue('model', model)
      setFieldValue('mara', mara)
      setFieldValue('supNo', supNo)
      setFieldValue('sellPrice', price)
      setFieldValue('importSource', importSource)
      setIsDisabled(true)
    } else {
      setFieldValue('subjectId', '')
      setFieldValue('model', '')
      setFieldValue('mara', '')
      setFieldValue('supNo', '')
      setFieldValue('sellPrice', '')
      setFieldValue('importSource', '')
      setIsDisabled(false)
    }
  }

  return (
    <Form>
      <section>
        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="imei">商品 Serial No.</label>
            <Field type="text" name="imei" className={'form-control' + (errors.imei && touched.imei ? ' is-invalid' : '')} />
            <ErrorMessage name="imei" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label>&nbsp;</label>
            <button type="button" className="btn btn-secondary d-block" data-toggle="modal" data-target="#barcodeReader">掃描</button>
          </div>
          <LaptopBarcodeReader id="barcodeReader" cb={code => setFieldValue('imei', code)} />
        </div>

        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="poProductNo">投保商品貨號</label>
            <Field type="text" name="poProductNo" onChange={handleProductNoChange} className={'form-control' + (errors.poProductNo && touched.poProductNo ? ' is-invalid' : '')} />
            <ErrorMessage name="poProductNo" component="div" className="invalid-feedback" />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="supNo">廠牌</label>
            <Field type="text" name="supNo" disabled={isDisabled} className={'form-control' + (errors.supNo && touched.supNo ? ' is-invalid' : '')} />
            <ErrorMessage name="supNo" component="div" className="invalid-feedback" />
          </div>
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="mara">品名</label>
            <Field type="text" name="mara" disabled={isDisabled} className={'form-control' + (errors.mara && touched.mara ? ' is-invalid' : '')} />
            <ErrorMessage name="mara" component="div" className="invalid-feedback" />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="model">型號</label>
            <Field type="text" name="model" disabled={isDisabled} className={'form-control' + (errors.model && touched.model ? ' is-invalid' : '')} />
            <ErrorMessage name="model" component="div" className="invalid-feedback" />
          </div>
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="sellPrice">售價</label>
            <Field type="number" name="sellPrice" disabled={isDisabled} className={'form-control' + (errors.sellPrice && touched.sellPrice ? ' is-invalid' : '')} />
            <ErrorMessage name="sellPrice" component="div" className="invalid-feedback" />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="mbInvoiceNum">發票號碼</label>
            <Field type="text" name="mbInvoiceNum" className={'form-control' + (errors.mbInvoiceNum && touched.mbInvoiceNum ? ' is-invalid' : '')} />
            <ErrorMessage name="mbInvoiceNum" component="div" className="invalid-feedback" />
          </div>
        </div>
      </section>

      <div className="text-danger">{status}</div>

      <section className="my-3 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={ltcStore.navi.goBack}>上一步</button>
        <button type="submit" disabled={isSubmitting} className="btn btn-secondary">下一步</button>
      </section>
    </Form>
  )
}