import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProtectedRoute } from '../common'
import { Path, Exam, Score } from './page'
import { NotFound } from '../common/page'

const Module: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute path={Path.Exam} component={Exam} />
      <ProtectedRoute path={Path.Score} component={Score} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Module