import { service } from '.'

export interface User { id: number, name: string, acc: string, role: string, status: boolean }
const emptyUser = { id: 0, name: '', acc: '', role: 'ROLE_SENYOUNG', status: true }

class UserStore {
  private _users: User[] = []
  private _user: User = { ...emptyUser }

  get users() { return this._users }
  get user() { return this._user }

  loadAll = async () => {
    const res = await service.getUsers()
    if (res.status === 200) {
      this._users = await res.json()
      return Promise.resolve()
    }
    else if(res.status === 401) { }
    else return Promise.reject({ message: `無法取得審核員資訊！ 錯誤代碼：${res.status}` })
  }

  loadOne = async (id: number) => {
    if (id === 0) {
      this._user = { ...emptyUser }
      return Promise.resolve()
    }

    const res = await service.getUser(id)
    if (res.status === 200) {
      this._user = await res.json()
      return Promise.resolve()
    }

    return Promise.reject({ message: `無法取得審核員資訊！ 錯誤代碼：${res.status}` })
  }

  save = async (user: User) => {
    const res = await (user.id === 0 ?
      service.addUser(user.name, user.acc, user.role) :
      service.updateUser(user.id, user.acc, user.name, user.role, user.status))

    if (res.status === 200) return Promise.resolve()
    else if (res.status === 400) return Promise.reject({ message: '無法儲存！ 請檢查「名稱」是否重複？' })
    else return Promise.reject({ message: `無法儲存！　錯誤代碼：${res.status}` })
  }
}

export const userStore = new UserStore()