import React from 'react'
import styled from 'styled-components'

interface Props {
  titles: Array<string>
  step: number
}

const Indicator: React.FC<Props> = ({ titles, step }) => (
  <List>
    {titles.map((title, index) => (
      <Item className={index < step ? 'complete' : index === step ? 'processing' : 'incomplete'} key={index}></Item>
    ))}
  </List>
)

export default Indicator

const List = styled.ul`
  margin: 12px auto 0;
  padding: 12px;

  display: flex;
  justify-content: center;
`
const Item = styled.li`
  position: relative;
  width: 32px;
  height: 4px;

  +li {
    margin-left: 16px;
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;    
  }

  &.complete::after { background-color: #008fcb }
  &.processing::after { background-color: #fbc700 }
  &.incomplete::after { background-color: #4a4a4a } 
  /* &.complete::after { background-color: #0088CB } */
  /* &.processing::after { background-color: #C1CE01 } */
  /* &.incomplete::after { background-color: #6C757D }  */
`