import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import 'bootstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.css'

import './style/main.scss'
import { MobileApp } from './mobileApp'
import { AdminConsole } from './adminConsole'

toastr.options = {
  // "closeButton": false,
  // "newestOnTop": false,
  // "progressBar": true,
  // "positionClass": "toast-top-right",
  "preventDuplicates": true,
  // "showDuration": 300,
  // "hideDuration": 1000,
  "timeOut": 3000,
  // "extendedTimeOut": 1000,
  // "showEasing": "swing",
  // "hideEasing": "linear",
  // "showMethod": "fadeIn",
  // "hideMethod": "fadeOut",
}

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/app" component={MobileApp} />
        <Route path="/admin" component={AdminConsole} />
        <Redirect to="/app" />
      </Switch>
    </Router>
  );
}

export default App