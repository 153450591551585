import React from 'react'

declare var TPDirect: any
const tapPayId = process.env.REACT_APP_TAPPAY_ID
const tapPayKey = process.env.REACT_APP_TAPPAY_KEY
const tapPayType = process.env.REACT_APP_TAPPAY_TYPE
TPDirect.setupSDK(tapPayId, tapPayKey, tapPayType)

const fields = {
  number: { element: '#ccNum', placeholder: '**** **** **** ****' },
  expirationDate: { element: '#ccExp', placeholder: 'MM / YY' },
  ccv: { element: '#ccCcv', placeholder: 'ccv' }
}

const styles = {
  'input': { 'font-size': '1.123rem', },
  '.valid': { 'color': 'green' },
  '.invalid': { 'color': 'red' },
}

enum Status { OK, Empty, Error, Typing }

export const tapPayTCMsgStatusCode: Map<string, string> = new Map([
  ['915', '系統錯誤，請聯繫 TapPay'],
  ['10003', '卡片錯誤，請洽詢發卡行'],
  ['10005', '銀行系統逾時'],
  ['10006', '重複交易'],
  ['10008', '商家帳戶錯誤'],
  ['10009', '金額錯誤'],
  ['10013', 'Bank transaction id 重複'],
  ['10023', '銀行錯誤或TSP錯誤'],
  ['10015', '紅利折抵失敗'],
])

interface Props { className?: string, onUpdate: Function }

export const TapPaySection: React.FC<Props> = ({ className = '', onUpdate }) => {
  const [status, setStatus] = React.useState({ number: Status.Empty, expiry: Status.Empty, ccv: Status.Empty })

  React.useEffect(() => {
    TPDirect.card.setup({ fields, styles, })
    TPDirect.card.onUpdate((update: any) => {
      setStatus({ ...update.status })
      onUpdate(update.canGetPrime)
    })
  }, [onUpdate])

  return (
    <section className={'row ' + className}>
      <div className="form-group col-12 col-sm-6">
        <label htmlFor="ccNum">信用卡卡號</label>
        <div id="ccNum" className={'form-control' + (status.number === Status.Error ? ' is-invalid' : '')} />
        <div className="invalid-feedback">信用卡卡號錯誤！</div>
      </div>

      <div className="form-group col-6 col-sm-3">
        <label htmlFor="ccExp">有效日期</label>
        <div id="ccExp" className={'form-control' + (status.expiry === Status.Error ? ' is-invalid' : '')} />
        <div className="invalid-feedback">有效日期錯誤！</div>
      </div>

      <div className="form-group col-6 col-sm-3">
        <label htmlFor="ccCcv">驗證碼</label>
        <div id="ccCcv" className={'form-control' + (status.ccv === Status.Error ? ' is-invalid' : '')} />
        <div className="invalid-feedback">驗證碼錯誤！</div>
      </div>
    </section>
  )
}