import { authStore } from '.'

const baseUrl = process.env.REACT_APP_SERVICE_URL

const url = {
  login: baseUrl + '/api/pub/admin/login',
  resetPwd: baseUrl + '/api/pub/admin/forget',

  chgPwd: baseUrl + '/api/jwt/admin/chgPwd',
  pwdRule: baseUrl + '/api/jwt/admin/chgPwd/rule',

  dealer: baseUrl + '/api/jwt/admin/dealer',
  store: baseUrl + '/api/jwt/admin/store',
  user: baseUrl + '/api/jwt/admin/user',
  sales: baseUrl + '/api/jwt/admin/sales',

  dailyReports: baseUrl + '/api/jwt/admin/report/daily',
  monthlyReports: baseUrl + '/api/jwt/admin/report/monthly',

  policy: baseUrl + '/api/jwt/admin/policy',
  export: baseUrl + '/api/jwt/admin/export',
  import: baseUrl + '/api/jwt/admin/upload'
}

enum Method {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

const genReqOpts = (method: Method, body?: string) => {
  const Authorization = 'Bearer ' + authStore.currUser.token
  return {
    method,
    headers: { 'Content-Type': 'application/json', Authorization },
    body,
  }
}

const _unauthorizedHandler = (response: Response) => {
  if (response.status === 401) authStore.logout()
  return response
}

// ------------------------------------
// Following are public APIs
// ------------------------------------
const login = (acc: string, pwd: string) => {
  const opts = genReqOpts(Method.Post, JSON.stringify({ acc, pwd }))
  return fetch(url.login, opts)
}

const resetPwd = (acc: string, token: string) => {
  const opts = genReqOpts(Method.Post, JSON.stringify({ acc, token }))
  return fetch(url.resetPwd, opts)
}

// ------------------------------------
// Following are JWT APIs
// ------------------------------------
const chgPwd = (oldPwd: string, newPwd: string) => {
  const opts = genReqOpts(Method.Post, JSON.stringify({ oldPwd, newPwd }))
  return fetch(url.chgPwd, opts).then(_unauthorizedHandler)
}

const getPwdRule = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(url.pwdRule, opts)
}

// dealer
const getDealers = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(url.dealer, opts).then(_unauthorizedHandler)
}

const getDealer = (id: number) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.dealer}/${id}`, opts).then(_unauthorizedHandler)
}

const addDealer = (name: string, no: string, maintainCenter: string) => {
  const opts = genReqOpts(Method.Post, JSON.stringify({ name, no, maintainCenter }))
  return fetch(url.dealer, opts).then(_unauthorizedHandler)
}

const updateDealer = (id: number, name: string, status: boolean, no: string, maintainCenter: string) => {
  const opts = genReqOpts(Method.Put, JSON.stringify({ id, name, status, no, maintainCenter }))
  return fetch(url.dealer, opts).then(_unauthorizedHandler)
}

const getDealerNoOptions = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.dealer}/no`, opts).then(_unauthorizedHandler)
}

// store
const getStores = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(url.store, opts).then(_unauthorizedHandler)
}

const getStore = (id: number) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.store}/${id}`, opts).then(_unauthorizedHandler)
}

const addStore = (dealerId: number, name: string, code: string) => {
  const opts = genReqOpts(Method.Post, JSON.stringify({ dealerId, name, code }))
  return fetch(url.store, opts).then(_unauthorizedHandler)
}

const updateStore = (id: number, dealerId: number, name: string, code: string, status: boolean) => {
  const opts = genReqOpts(Method.Put, JSON.stringify({ id, dealerId, name, code, status }))
  return fetch(url.store, opts).then(_unauthorizedHandler)
}

// user
const getUsers = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(url.user, opts).then(_unauthorizedHandler)
}

const getUser = (id: number) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.user}/${id}`, opts).then(_unauthorizedHandler)
}

const addUser = (name: string, acc: string, role: string) => {
  const opts = genReqOpts(Method.Post, JSON.stringify({ name, acc, role }))
  return fetch(url.user, opts).then(_unauthorizedHandler)
}

const updateUser = (id: number, acc: string, name: string, role: string, status: boolean) => {
  const opts = genReqOpts(Method.Put, JSON.stringify({ id, acc, name, role, status }))
  return fetch(url.user, opts).then(_unauthorizedHandler)
}

// sales
const getSaless = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(url.sales, opts).then(_unauthorizedHandler)
}

const getSales = (id: number) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.sales}/${id}`, opts).then(_unauthorizedHandler)
}

const addSales = (name: string, uno: string, nationId: string, email: string, dealerId: number, storeId: number, dealerName: string, storeName: string) => {
  const opts = genReqOpts(Method.Post, JSON.stringify({ name, uno, nationId, email, dealerId, storeId, dealerName, storeName }))
  return fetch(url.sales, opts).then(_unauthorizedHandler)
}

const updateSales = (id: number, name: string, uno: string, nationId: string, email: string, status: boolean, dealerId: number, storeId: number, dealerName: string, storeName: string) => {
  const opts = genReqOpts(Method.Put, JSON.stringify({ id, name, uno, nationId, email, status, dealerId, storeId, dealerName, storeName }))
  return fetch(url.sales, opts).then(_unauthorizedHandler)
}

const getExamResult = (dealerId: number) => {
  const opts = genReqOpts(Method.Get)
  const queryDealer = (dealerId !== 0) ? `?dealerId=${dealerId}` : ''
  return fetch(`${url.export}/sales${queryDealer}`, opts).then(_unauthorizedHandler)
}

// daily reports
const getDailyReports = (ymd: string) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.dailyReports}?ymd=${ymd}`, opts).then(_unauthorizedHandler)
}

const getDailyPolicy = (ymd: string) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.export}/policy?ymd=${ymd}`, opts).then(_unauthorizedHandler)
}

// monthly reports
const getMonthlyReports = (ym: string) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.monthlyReports}?ym=${ym}`, opts).then(_unauthorizedHandler)
}

const getMonthlyPolicy = (ym: string) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.export}/policy?ym=${ym}`, opts).then(_unauthorizedHandler)
}

// policy
const getPoliciesTop2000 = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.policy}?page=0&size=2000`, opts).then(_unauthorizedHandler)
}

const getReviewedPoliciesTop2000 = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.policy}?approve=Y&page=0&size=2000`, opts).then(_unauthorizedHandler)
}

const getUnreviewedPolicyTop2000 = () => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.policy}?approve=N&page=0&size=2000`, opts).then(_unauthorizedHandler)
}

// policy audit

const getPolicyPDF = (id: number) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.policy}/${id}`, opts).then(_unauthorizedHandler)
}

const getPolicyMemo = (id: number) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.policy}/memo/${id}`, opts).then(_unauthorizedHandler)
}

const updatePolicyMemo = (id: number, memo: string) => {
  const opts = genReqOpts(Method.Put, JSON.stringify({ id, memo }))
  return fetch(`${url.policy}/memo`, opts).then(_unauthorizedHandler)
}

const approvePolicy = (id: number, memo: string) => {
  const opts = genReqOpts(Method.Put, JSON.stringify({ id, memo }))
  return fetch(`${url.policy}/approve`, opts).then(_unauthorizedHandler)
}

// export policy & consent zip

const getPolicyConsentZip = (id: number) => {
  const opts = genReqOpts(Method.Get)
  return fetch(`${url.policy}/zip/${id}`, opts).then(_unauthorizedHandler)
}

// Excel import
const importSubjects = (data: any) => {
  const opts = genReqOpts(Method.Post, JSON.stringify(data))
  return fetch(`${url.import}/subjects`, opts).then(_unauthorizedHandler)
}

const importPriceBands = (data: any) => {
  const opts = genReqOpts(Method.Post, JSON.stringify(data))
  return fetch(`${url.import}/quotes`, opts).then(_unauthorizedHandler)
}

export const service = {
  login, resetPwd, chgPwd, getPwdRule,
  getDealers, getDealer, addDealer, updateDealer, getDealerNoOptions,
  getStores, getStore, addStore, updateStore,
  getUsers, getUser, addUser, updateUser,
  getSaless, getSales, addSales, updateSales, getExamResult,
  getDailyReports, getDailyPolicy, getMonthlyReports, getMonthlyPolicy,
  getPoliciesTop2000, getReviewedPoliciesTop2000, getUnreviewedPolicyTop2000,
  getPolicyPDF, getPolicyMemo, updatePolicyMemo, approvePolicy,
  getPolicyConsentZip,
  importSubjects, importPriceBands,
}