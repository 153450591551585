import React from 'react'

import { SignatureCanvas } from '.'

interface Props {
  title: string
  canvasRef: any
  height: string
}

export const SignaturePad: React.FC<Props> = ({ title, canvasRef, height }) => {
  return (
    <section className="card">
      <div className="card-header d-flex justify-content-between align-items-end">
        <div>{title}</div>
        <div className="text-muted fs_caption">此聲明事項，乃確實經由本人確認後親自簽名</div>
      </div>
      <div className="card-body p-0" style={{ height }}>
        <SignatureCanvas ref={canvasRef} />
      </div>
    </section>
  )
}