import React from 'react'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'

import { BasicLayout } from '../../common/layout'
import { questionnaireStore } from 'mobileApp/store'
import { Path as QuestionnairePath } from '.'

export const Score: React.FC<RouteComponentProps> = (props) => {
  const { myScore, isPass } = questionnaireStore

  return (
    <BasicLayout title="業務員測驗結果">
      <ResultContainer className="d-flex justify-content-center align-items-center">
        <section>
          {isPass ?
            <ResultDiv className="text-muted mb-2">您答對 <span className="text-primary px-2">{myScore}</span> 題，已通過測驗。</ResultDiv> :
            <ResultDiv className="text-muted mb-2">您答對 <span className="text-primary px-2">{myScore}</span> 題，請重新測驗。</ResultDiv>
          }
          <div className="text-danger">合格標準為 7 題</div>
        </section>
      </ResultContainer>
      <section className="mt-5 mb-4 d-flex justify-content-between">
        {isPass ?
          <button type="button" className="btn btn-secondary mx-auto" onClick={() => props.history.push('/')}>下一步</button> :
          <button type="button" className="btn btn-secondary mx-auto" onClick={() => props.history.push(QuestionnairePath.Exam)}>重新測驗</button>
        }
      </section>
    </BasicLayout>
  )
}

const ResultContainer = styled.div`
  padding: 120px 0px 0px;
`

const ResultDiv = styled.div`
  font-size: 24px;

  span {
    font-size: 32px;
    font-weight: 500;
  }
`