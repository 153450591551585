import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'

import { dealerStore, Dealer } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
// import { Debug } from 'share/component/formik'

enum Status { Loading, Ready, Failed }
interface Params { id: string }

export const DealerEdit: React.FC<RouteComponentProps<Params>> = props => {
  const id = parseInt(props.match.params.id)
  const [status, setStatus] = React.useState(Status.Loading)
  const { goBack } = props.history

  React.useEffect(() => {
    setStatus(Status.Loading)
    Promise.all([
      dealerStore.loadOne(id),
      dealerStore.loadDealerNoOptions(),
    ]).then(() => setStatus(Status.Ready))
      .catch(({ message }) => {
        toastr.error(message)
        setStatus(Status.Failed)
      })
  }, [id])

  return (
    <>
      <TitleBar title="經銷商管理" isLoading={status === Status.Loading} />
      <main className="my-3 px-3">
        {
          status === Status.Failed ?
            <button type="button" className="btn btn-light" onClick={() => goBack()}>返回</button> :
            ''
        }
        {
          status === Status.Ready ?
            <Formik
              initialValues={dealerStore.dealer}
              validationSchema={Yup.object().shape({
                id: Yup.number().required('必填欄位！'),
                name: Yup.string().required('必填欄位！'),
                status: Yup.boolean().required('必填欄位！'),
                no: Yup.string().required('必填欄位'),
                maintainCenter: Yup.string().required('必填欄位'),
              })}
              onSubmit={(values: Dealer, { setStatus, setSubmitting }: FormikActions<Dealer>) => {
                setStatus()
                dealerStore.save(values)
                  .then(goBack)
                  .catch(({ message }) => {
                    setStatus(message)
                    setSubmitting(false)
                  })
              }}
              component={formikProps => DealerForm(formikProps, goBack)}
            /> : ''
        }
      </main>
    </>
  )
}

const DealerForm = ({ errors, status, touched, isSubmitting }: FormikProps<Dealer>, goBack: Function) => (
  <Form>
    <div className="row">
      <div className="form-group col-12 col-sm-6">
        <label htmlFor="name">經銷商名稱</label>
        <Field type="text" name="name" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
        <ErrorMessage name="name" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="status">狀態</label>
        <Field component="select" name="status" className={'form-control' + (errors.status && touched.status ? ' is-invalid' : '')}>
          <option value="true">啟用</option>
          <option value="false">停用</option>
        </Field>
        <ErrorMessage name="status" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="no">經銷商代碼</label>
        <Field component="select" name="no" className={'form-control' + (errors.no && touched.no ? ' is-invalid' : '')}>
          <option value="">請選擇</option>
          {dealerStore.dealerNoOptions.map(noOptions => (
            <option key={noOptions} value={noOptions}>{noOptions}</option>
          ))}
        </Field>
        <ErrorMessage name="no" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="maintainCenter">維修中心</label>
        <Field type="text" name="maintainCenter" className={'form-control' + (errors.maintainCenter && touched.maintainCenter ? ' is-invalid' : '')} />
        <ErrorMessage name="maintainCenter" component="div" className="invalid-feedback" />
      </div>

      <div className="col-12 text-danger">{status}</div>
    </div>

    <div className="row mt-3">
      <div className="col-12 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={() => goBack()}>取消</button>
        <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>儲存</button>
      </div>
    </div>
    {/* <Debug /> */}
  </Form>
)