import React from 'react'
import { CSVLink } from 'react-csv'
import toastr from 'toastr'

enum Code { Idle, Loading, Ready, Failed }

interface Props {
  loadDataFn: Function,
  getDataFn: Function,
  time: string | any,
  filename: string
}

export const CsvButton: React.FC<Props> = ({ loadDataFn, getDataFn, time, filename }) => {
  const [status, setStatus] = React.useState({ code: Code.Idle, msg: '' })
  const csvlinkRef = React.useRef(null)
  let [data, setData] = React.useState([])

  React.useEffect(() => {
    setStatus({ code: Code.Idle, msg: '' })
  }, [time])

  const handleExport = () => {
    setStatus({ code: Code.Loading, msg: '' })
    loadDataFn(time)
      .then(() => {
        const dataArray = getDataFn()
        setData(dataArray)
        setStatus({ code: Code.Ready, msg: '' });

        (csvlinkRef.current as any).link.click()
        setStatus({ code: Code.Idle, msg: '' })
      })
      .catch((msg: string) => {
        setStatus({ code: Code.Failed, msg })
        toastr.error(msg)
      })
  }

  return (
    <>
      {
        status.code !== Code.Loading ? status.code !== Code.Ready ? status.code !== Code.Failed ?
          <IdleButton onClick={handleExport} /> :
          <FailedButton msg={status.msg} /> :
          <CSVLink data={data} filename={filename} ref={csvlinkRef} target="_self" style={{ display: 'none' }} /> :
          <LoadingButton />
      }
    </>
  )
}

const LoadingButton: React.FC = () => {
  return (
    <button type="button" className="btn btn-secondary">
      <div className="spinner-border spinner-border-sm" />
    </button>
  )
}

const FailedButton: React.FC<{ msg: String }> = ({ msg }) => {
  return (
    <button type="button" className="btn btn-secondary">
      <i className="material-icons align-bottom">error_outline</i>
    </button>
  )
}

const IdleButton: React.FC<{ onClick: Function }> = ({ onClick }) => {
  return (
    <button type="button" className="btn btn-secondary" onClick={() => onClick()}>
      <i className="material-icons align-bottom">save_alt</i>
    </button>
  )
}