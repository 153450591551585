import React from 'react'
import moment from 'moment'

interface Props { value: string, onChange: Function }

export const MonthlySelector: React.FC<Props> = ({ value, onChange }) => {
  const months = Array.from({ length: 12 }, (v, k) => {
    const value = moment().subtract(k, 'month').format('YYYYMM')
    const desc = moment().subtract(k, 'month').format('YYYY / MM')
    return { value, desc }
  })

  return (
    <select name="month" className="custom-select" value={value} onChange={e => onChange(e.target.value)}>
      <option value="0">請選擇月份</option>
      {months.map((month, index) => (
        <option key={index} value={month.value}>{month.desc}</option>
      ))}
    </select>
  )
}