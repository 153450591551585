import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Field } from 'mobileApp/common/component/formik';
import { Modal } from 'mobileApp/common/component/modal';
import { Clause1_1Y, Clause1_2Y, Clause1_36M, Clause1_3Y, Clause2_I, Clause2_J, Clause2_P, Clause3_G, authStore, premiumStore, wbeProductCategory, wbeStore } from 'mobileApp/store';
import { WbeLayout } from 'mobileApp/wbe/layout';

enum Code { Loading, Ready, Failed }

export const S04Coverage = () => {
  const [status, setStatus] = useState({ code: Code.Loading, message: '' })

  useEffect(() => {
    premiumStore.getPremiums(wbeProductCategory)
      .then(() => wbeStore.quote())
      .then(() => setStatus({ code: Code.Ready, message: '' }))
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [])

  return (
    <WbeLayout title="承保範圍" step={3}>
      {status.code !== Code.Loading ? status.code !== Code.Ready ?
        <FailedMsg msg={status.message} /> :
        <CoverageFormik /> :
        <Loading />
      }
    </WbeLayout>
  )
}

const Loading = () => (
  <div className="row">
    <div className="col-12 text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </div>
  </div>
)

const FailedMsg: FC<{ msg: String }> = ({ msg }) => (
  <div className="row"><div className="col-12 text-danger">{msg}</div></div>
)

const CoverageFormik = () => {
  return (
    <Formik
      initialValues={{ paymentType: wbeStore.paymentType }}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus()
        wbeStore.paymentType = values.paymentType
        setSubmitting(false)
        wbeStore.navi.goNext()
      }}
      validationSchema={ Yup.object().shape({ paymentType: Yup.string().required('必填欄位！') })}
      component={(formikProps) => CoverageForm(formikProps)}
    />
  )
}

const CoverageForm = ({ values: { paymentType }, isSubmitting }: FormikProps<{ paymentType: string }>) => {
  const { maintainCenter } = authStore.currUser
  const { paymentOptions } = wbeStore
  const curPremium = paymentOptions.filter(premium => premium.paymentType === paymentType)[0]
  const { premium, chgDeductible } = curPremium || {}
  
  const yearAmount = (paymentType === '3Y' || paymentType === 'M') && 3 || paymentType === '2Y' && 2 || 1

  return (
    <Form>
      <section className="mb-3">
        { (paymentType === 'M' || paymentType === '2Y') ? 
            <div>承保行動裝置無法正常使用或因不可預料之事故所致毀損</div> :
            <div>承保行動裝置因不可預料之事故所致毀損</div> }
        <div className="text-primary">（賠償方式僅限置換）</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">保險期間</div>
        <div className="col-12 col-sm-8">{`${moment().format('YYYY／M／D')} ～ ${moment().add(yearAmount, 'year').subtract(1, 'day').format('YYYY／M／D')}`}</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">保險期間累積最高賠償次數</div>
        <div className="col-5 col-sm-2 fs_caption text-muted">第一年<span className="h5 text-dark px-2 m-0">3</span>次</div>
        { (paymentType === 'M' || paymentType === '2Y' || paymentType === '3Y') && <div className="col-5 col-sm-2 fs_caption text-muted">第二年<span className="h5 text-dark px-2 m-0">3</span>次</div> }
        { (paymentType === 'M' || paymentType === '3Y') && <div className="col-5 col-sm-2 fs_caption text-muted">第三年<span className="h5 text-dark px-2 m-0">3</span>次</div> }
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">自負額</div>
        <div className="col-5 col-sm-3 fs_caption text-muted">置換<span className="h5 text-dark px-2 m-0">{chgDeductible}</span>元</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">指定維修中心</div>
        <div className="col-12 fs_body1 col-sm-8">{maintainCenter}</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">保險費</div>
        <div className="col-12 col-sm-8 fs_caption text-muted">新台幣<span className="h5 text-dark px-2 m-0">{premium}</span>元</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-sm-4 text-primary">繳款方式</div>
        <div className="col-sm-8">
          <RadioBox>
            <Field type="radio" name="paymentType" id="M" value="M" checked={paymentType === 'M'} className="d-none" />
            <label htmlFor="M">月繳（36M）</label>
            <ErrorMessage name="paymentType" component="div" className="invalid-feedback" />
          </RadioBox>

          <RadioBox>
            <Field type="radio" name="paymentType" id="1Y" value="1Y" checked={paymentType === '1Y'} className="d-none" />
            <label htmlFor="1Y">年繳（1Y）</label>
          </RadioBox>

          <RadioBox>
            <Field type="radio" name="paymentType" id="2Y" value="2Y" checked={paymentType === '2Y'} className="d-none" />
            <label htmlFor="2Y">年繳（2Y）</label>
          </RadioBox>

          <RadioBox>
            <Field type="radio" name="paymentType" id="3Y" value="3Y" checked={paymentType === '3Y'} className="d-none" />
            <label htmlFor="3Y">年繳（3Y）</label>
          </RadioBox>
        </div>
      </section>

      <section className="row mb-3">
        <div className="col-sm-4 text-primary">本保險契約適用附加條款</div>
        <div className="col-sm-8 text-muted fs_caption mt-3 mt-sm-0">
          { 
            paymentType === 'M' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_36M">安達產物行動裝置保險（分期交付巳型）</ClauseBtn> :
            paymentType === '1Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_1Y">安達產物行動裝置保險（一次交付寅型）</ClauseBtn> :
            paymentType === '2Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_2Y">安達產物行動裝置保險（一次交付卯型）</ClauseBtn> :
            paymentType === '3Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_3Y">安達產物行動裝置保險（一次交付亥型）</ClauseBtn> : null
          }
          { 
            paymentType === 'M' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_36M">安達產物行動裝置竊盜損失附加條款（J）</ClauseBtn> :
            paymentType === '1Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_1Y">安達產物行動裝置竊盜損失附加條款（一次交付I）</ClauseBtn> :
            paymentType === '2Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_2Y">安達產物行動裝置竊盜損失附加條款（一次交付I）</ClauseBtn> :
            paymentType === '3Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_2Y3">安達產物行動裝置保險竊盜損失附加條款（P）</ClauseBtn> : null
          }
        </div>
      </section>

      {
        paymentType === 'M' ? <Modal id="clause1_36M" title="安達產物行動裝置保險（分期交付巳型）" contentFn={Clause1_36M} /> :
        paymentType === '1Y' ? <Modal id="clause1_1Y" title="安達產物行動裝置保險（一次交付寅型）" contentFn={Clause1_1Y} /> :
        paymentType === '2Y' ? <Modal id="clause1_2Y" title="安達產物行動裝置保險（一次交付卯型）" contentFn={Clause1_2Y} /> :
        paymentType === '3Y' ? <Modal id="clause1_3Y" title="安達產物行動裝置保險（一次交付亥型）" contentFn={Clause1_3Y} /> : null
      }

      {
        paymentType === 'M' ? <Modal id="clause2_36M" title="安達產物行動裝置竊盜損失附加條款（J）" contentFn={Clause2_J} /> :
        paymentType === '1Y' ? <Modal id="clause2_1Y" title="安達產物行動裝置竊盜損失附加條款（一次交付I）" contentFn={Clause2_I} /> :
        paymentType === '2Y' ? <Modal id="clause2_2Y" title="安達產物行動裝置竊盜損失附加條款（一次交付I）" contentFn={Clause2_I} /> :
        paymentType === '3Y' ? <Modal id="clause2_2Y3" title="安達產物行動裝置保險竊盜損失附加條款（P）" contentFn={Clause2_P} /> : null
      }

      <section className="my-3 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={wbeStore.navi.goBack}>上一步</button>
        <button type="submit" disabled={isSubmitting} className="btn btn-secondary">下一步</button>
      </section>
    </Form>
  )
}

const RadioBox = styled.div`
  display: inline-block;
  margin-right: 1rem;

  label {
    display: inline-block;
    padding: 4px 16px;
    margin-top: .5rem;
    font-size: 18px;
    color: #008fcb;
    background-color: #FFFFFF;
    border: solid 1px #008fcb;
    border-radius: 2px;
    cursor: pointer;
  } 

  input:checked+label {
    font-weight: 500;
    color: #FFFFFF;
    background-color: #008fcb;
  }
`

const ClauseBtn = styled.button`
  display: block;
  color: #6c757d;
  padding: 0px;
  margin-bottom: 1rem;
  text-decoration: underline;
  text-align: left;
`