import React, { useState, useRef, FC, ChangeEvent, DragEvent } from 'react'

type Props = {
  id: string
  handleFileToData: Function
  acceptType?: string
}

export const DragAndDropField: FC<Props> = ({ id, acceptType = '.xlsx, .xls', handleFileToData }) => {
  const [dragActive, setDragActive] = useState<boolean>(false)
  const inputRef = useRef(null)
  const labelClass = 'drag_and_drop_label' + (dragActive ? ' drag_active' : '')

  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') setDragActive(true)
    if (e.type === 'dragleave') setDragActive(false)
  }

  const handleDrop = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (!e.dataTransfer.files) return
    handleFileToData(e.dataTransfer.files[0])
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files) return
    handleFileToData(e.target.files[0])
  }

  return (
    <form className="drag_and_drop_container" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input type="file" id={id} ref={inputRef} onChange={handleChange} className="d-none" multiple={false} accept={acceptType} />
      <label htmlFor={id} className={labelClass}>
        <div>
          <i className="material-icons">file_upload</i>
          <p>請將檔案拖曳到此或點擊選取檔案</p>
        </div>
      </label>
      { dragActive && <div className="drag_and_drop_element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>
  )
}
