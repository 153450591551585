import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { authStore } from 'adminConsole/store'
import { Path } from 'adminConsole'

const Header = (props: RouteComponentProps) => {
  const { name } = authStore.currUser
  const { logout } = authStore

  return (
    <TopHeader className="bg-secondary w-100 d-flex justify-content-between align-items-center">
      <LogoSection className="d-flex align-items-end">
        <h5 className="mb-1 mr-2">安盛保代</h5>
        <h5 className="mb-1 text-white">行動投保管理後台</h5>
      </LogoSection>
      <MenuSection className="dropdown">
        <div className="dropdown-toggle text-muted" data-toggle="dropdown">
          <i className="material-icons align-middle">account_circle</i>
          <NameSpan>{name}</NameSpan>
        </div>
        <div className="dropdown-menu dropdown-menu-right">
          <Link to={Path.PasswordChange} className="dropdown-item text-primary">變更密碼</Link>
          {/* eslint-disable-next-line */}
          <a className="dropdown-item text-primary" onClick={logout}>登出</a>

          {/* 行動投保App 相關資訊 */}
          <div className="dropdown-divider"></div>
          <div className="dropdown-item d-flex align-items-center">
            <span className="text-muted">行動投保</span>
            <i className="material-icons text-muted ml-2">phone_iphone</i>
          </div>
          <div className="dropdown-item text-muted">環境 {process.env.REACT_APP_ENV_TYPE}</div>
          <div className="dropdown-item text-muted">版本 {process.env.REACT_APP_VERSION}</div>
        </div>
      </MenuSection>
    </TopHeader>
  )
}

export default withRouter(Header)

const TopHeader = styled.header`
  position: sticky;
  top: 0px;
  height: 72px;
  z-index: 100;
`

const LogoSection = styled.section`
  margin-left: 32px;
`

const MenuSection = styled.section`
  margin-right: 32px;
`

const NameSpan = styled.span`
  padding: 0px 8px;
  font-weight: 500;
`
