import * as Yup from 'yup'

import { PriceBandData } from 'adminConsole/store'

let priceBandSchema = Yup.object({
  dealerNo: Yup.string().oneOf(['TK3C']).required(),
  productCategory: Yup.string().oneOf(['phone', 'accessories', 'laptop', 'airpods']).required(),
  paymentType: Yup.string().oneOf(['M', '1Y', '2Y', '3Y']).required(),
  scopeType: Yup.string().required(),
  productType: Yup.string().required(),
  premium: Yup.number().integer().min(0).required(),
  priceMin: Yup.number().integer().min(0).required(),
  priceMax: Yup.number().integer().min(0).required(),
  chgDeductible: Yup.number().integer().min(0).required(),
  fixDeductible: Yup.number().integer().min(0),
})

export const validatePriceBands = (priceBands: PriceBandData[]) => {
  let validPriceBands: PriceBandData[] = []
  let invalidPriceBands: PriceBandData[] = []

  priceBands.forEach(priceBand => priceBandSchema.isValidSync(priceBand) ? validPriceBands.push(priceBand) : invalidPriceBands.push(priceBand))

  return { validPriceBands, invalidPriceBands }
}