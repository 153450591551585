import React from 'react'

import { ProtectedRoute } from '../common'
import {
  S00CaseInit,
  S01CfSignature, S02LaptopInfo, S03PolicyHolderInfo, S04Coverage,
  S05ReviewPolicy, S06PhSignature, S07SmSignature,
  S08ReviewApplication, S09PremiumInfo, S10OnlinePayment, S11ChSignature, S12Confirmation,
  S21CfSignaturePad, S22PhSignaturePad, S23SmSignaturePad, S24ChSignaturePad,
} from './page'
import { deviceStore, ltcStore } from 'mobileApp/store';

const tabletPages = [
  S00CaseInit,
  S01CfSignature, S02LaptopInfo, S03PolicyHolderInfo, S04Coverage, S05ReviewPolicy,
  S06PhSignature, S07SmSignature,
  S08ReviewApplication, S09PremiumInfo, S10OnlinePayment, S11ChSignature, S12Confirmation
]

const phonePages = [
  S00CaseInit,
  S01CfSignature, S21CfSignaturePad, S02LaptopInfo, S03PolicyHolderInfo, S04Coverage, S05ReviewPolicy,
  S06PhSignature, S22PhSignaturePad, S23SmSignaturePad,
  S08ReviewApplication, S09PremiumInfo, S10OnlinePayment, S11ChSignature, S24ChSignaturePad, S12Confirmation
]

const Module: React.FC = () => {
  const [curPage, setCurPage] = React.useState(0)
  const pages = deviceStore.isPhone ? phonePages : tabletPages
  ltcStore.navi.goBack = () => curPage > 0 && setCurPage(curPage - 1)
  ltcStore.navi.goNext = () => curPage < pages.length - 1 && setCurPage(curPage + 1)

  return (
    <ProtectedRoute component={pages[curPage]} />
  )
}

export default Module