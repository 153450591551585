import React, { useState, useEffect, useRef } from 'react'
import { RouteComponentProps } from 'react-router'
import { observer } from 'mobx-react'
import toastr from 'toastr'

import { SignatureCanvas, SignaturePad } from 'mobileApp/common/component/signature'
import { ltcStore, deviceStore } from 'mobileApp/store'
import { LtcLayout } from 'mobileApp/ltc/layout'

export const S01CfSignature: React.FC<RouteComponentProps> = observer((props) => {
  const canvasRef = useRef<SignatureCanvas>(null)
  const [disabled, setDisabled] = useState(false)
  const [consent, setConsent] = useState(false)
  useEffect(() => {
    ltcStore.cfSignature && canvasRef.current && canvasRef.current.fromDataURL(ltcStore.cfSignature)
  }, [])

  const handleClear = () => canvasRef.current!.clear()
  const handleNext = () => {
    setDisabled(true)
    if (deviceStore.isPhone) ltcStore.navi.goNext()
    else
      ltcStore.uploadCfSignature(canvasRef.current!.toDataURL()).then((statusCode) => {
        if (statusCode === 200) ltcStore.navi.goNext()
        else {
          const errMsg = statusCode === 400 ? '簽名不可空白！' : `上傳簽名檔失敗！ 錯誤碼：${statusCode}`
          toastr.error(errMsg)
          setDisabled(false)
        }
      })
  }

  return (
    <LtcLayout title="要保人簽署同意書" step={0}>
      <section>
        <Article
          no="（１）"
          content="本人同意安盛保險代理人股份有限公司（以下簡稱安盛保代）於平板電腦、手機、筆記型電腦及個人電腦等電子設備（以下簡稱行動裝置）所提供的「安盛保代行動投保平台」進行投保作業時，以觸控親簽取代書面親簽。"
        />
        <Article
          no="（２）"
          content="本人知悉安盛保險代理人股份有限公司得依「個人資料保護法」之相關規定，於特定目的範圍內對要保人或被保險人之個人資料，有蒐集、處理及利用之權利。"
        />
      </section>

      {deviceStore.isPhone ? null : <SignaturePad title="要保人簽名" canvasRef={canvasRef} height="240px" />}

      <section className="my-3 d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-light mr-2 mr-md-3 btn_sm_compact"
          onClick={() => props.history.push('/system/home')}
        >
          回首頁
        </button>
        {deviceStore.isPhone ? (
          <div>&nbsp;</div>
        ) : (
          <button type="button" className="btn btn-light mr-2 mr-md-3 btn_sm_compact" onClick={handleClear}>
            清除
          </button>
        )}
        <button
          type="button"
          className="btn btn-primary ml-auto mr-2 mr-md-3 btn_sm_compact"
          data-toggle="modal"
          data-target="#consent_form"
        >
          同意書
        </button>
        <button
          type="button"
          className="btn btn-secondary btn_sm_compact"
          disabled={consent === false || disabled}
          onClick={handleNext}
        >
          下一步
        </button>
      </section>

      <div className="modal fade" id="consent_form" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">同意書</h5>
            </div>
            <div className="modal-body">
              <h5 className="text-center">要保人同意使用行動投保同意書</h5>
              <section>
                <p>
                  １、本人同意安盛保險代理人股份有限公司(以下簡稱安盛保代)於平板電腦、手機、筆記型電腦及個人電腦等電子設備（以下簡稱行動裝置）所提供的「安盛保代行動投保平台」進行投保作業時，以觸控親簽取代書面親簽。
                </p>
              </section>
              <section>
                <p>
                  ２、本人確認本同意書之簽名樣式確為親自簽署。本人簽署本同意書後，同意安盛保代歸檔於影像資料庫，爾後透過前條所約定之方式投保時，由安盛保代調閱同意書影像資料，確認本人於行動裝置觸控螢幕之簽名與本同意書上簽名樣式一致時，視同本人書面之親簽。
                </p>
              </section>
              <section>
                <p>
                  ３、本人同意安盛保代行動投保平台對於本人簽名樣式業已依照本同意書約定之方式進行蒐集、處理及利用，並已詳讀個人資料之告知事項說明。
                </p>
              </section>
              <section>
                <p>
                  ４、本人於本同意書之簽名樣式，僅能作為以行動裝置進行投保作業時確認簽名之用，不得為前述目的以外之利用。
                </p>
              </section>

              <hr className="my-5" />

              <h5 className="text-center">
                安盛保險代理人履行個人資料保護法
                <br />
                蒐集、處理及利用個人資料告知書
              </h5>
              <section>
                安盛保險代理人股份有限公司（以下稱安盛保代）、安盛保代所代理之美商安達產物保險股份有限公司（以下稱安達產險），以及燦坤實業股份有限公司，為保險業務之合作，依據個人資料保護法（以下稱個資法）第8條第1項規定（如為間接蒐集之個人資料則為第9條第1項），向
                台端告知。安達產險及燦坤實業股份有限公司之個人資料告知事項請至各該公司官網查詢。安盛保代之個人資料告知事項如下，敬請
                台端詳閱：
              </section>
              <section>
                <h6>一、蒐集之目的：</h6>
                <p>（一）財產保險</p>
                <p>（二）保險代理</p>
                <p>（三）行銷</p>
                <p>（四）消費者、客戶管理與服務</p>
                <p>（五）其他經營合於營業登記項目或組織章程所定之業務</p>
              </section>
              <section>
                <h6>二、蒐集之個人資料類別：</h6>
                <p>
                  依主管機關公告之個人資料類別（包括但不限於載於各項聲請文件之客戶、其代理人及法定代理人之姓名、身分證統一證號、聯絡方式等個人資料）
                </p>
              </section>
              <section>
                <h6>三、個人資料來源（個人資料非由當事人提供，而為間接蒐集之情形適用）</h6>
                <p>（一）要保人/被保險人；</p>
                <p>（二）司法警憲機關、委託協助處理理賠之公證人或機構；</p>
                <p>（三）當事人之法定代理人、輔助人；</p>
                <p>（四）各醫療院所；</p>
                <p>
                  （五）與第三人共同行銷、交互運用客戶資料、合作推廣等關係、或於安盛保代各項業務內所委託往來之第三人。
                </p>
              </section>
              <section>
                <h6>四、個人資料利用之期間、地區、對象、方式：</h6>
                <p>（一）期間：因執行業務所必須及依法令規定應為保存至期間。</p>
                <p>（二）對象：安盛保代、業務委外機構、其他與安盛保代合作推廣之對象。</p>
                <p>（三）地區：上述對象所在地區。</p>
                <p>（四）方式：合於法令規定之利用方式。</p>
              </section>
              <section>
                <h6>五、依據個資法第3條規定， 台端就安盛保代保有之個人資料得行使之權利及方式：</h6>
                <div>
                  （一）得向安盛保代行使之權利：
                  <p>１、向安盛保代查詢、請求閱覽或請求製給複製本。</p>
                  <p>２、向安盛保代請求補充或更正。</p>
                  <p>３、向安盛保代請求停止蒐集、處理或利用及請求刪除。</p>
                </div>
                <div>（二）行使權利之方式：以書面或其他日後可供證明之方式。</div>
              </section>
              <section>
                <h6>六、台端不提供個人資料所致權益之影響（個人資料有當事人直接蒐集之情形適用）：</h6>
                <p>
                  台端若未能提供相關個人資料時，安盛保代將可能延後或無法進行必要之審核及處理作業，因此可能無法提供
                  台端相關服務。
                </p>
              </section>
              <section>
                本人同意與保險業務範圍內、安盛保代及燦坤實業股份有限公司可就本人之個人資料進行交互運用，包含但不限於帳務處理、行銷推廣、客戶管理等。
              </section>
              <section>
                本人同意成為安盛保代之會員，安盛保代得於行銷之目的範圍內蒐集、處理及利用本人個人資料，並得為提供會員優惠權益、行銷活動與最新商品訊息等相關行銷目的使用。
              </section>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setConsent(true)} data-dismiss="modal">
                我同意
              </button>
            </div>
          </div>
        </div>
      </div>
    </LtcLayout>
  )
})

const Article: React.FC<{ no: string; content: string }> = ({ no, content }) => (
  <p className="d-flex fs_caption">
    <span>{no}</span>
    <span>{content}</span>
  </p>
)
