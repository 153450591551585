import React from 'react'
import { Route } from 'react-router'

import { PolicyReviewList, PolicyReviewAudit } from '.'
import { StatusType } from 'adminConsole/store'

export const PolicyReview: React.FC = () => {
  const [dealerId, setDealerId] = React.useState(0)
  const [storeId, setStoreId] = React.useState(0)
  const [keyword, setKeyword] = React.useState('')
  const [poStatus, setPoStatus] = React.useState(StatusType.Pending)
  const [currPage, setCurrPage] = React.useState(1)

  const handleDealerChange = (dealerId: number) => {
    setDealerId(dealerId)
    setStoreId(0)
  }

  const handlePoStatusChange = (value: StatusType) => {
    setKeyword('')
    setPoStatus(value)
  }

  return (
    <>
      <Route path="/policy/review" exact render={props => <PolicyReviewList
        dealerId={dealerId} dealerOnChange={handleDealerChange}
        storeId={storeId} storeOnChange={setStoreId}
        keyword={keyword} keywordOnChange={setKeyword}
        currPage={currPage} currPageOnChange={setCurrPage}
        poStatus={poStatus} poStatusOnChange={handlePoStatusChange}
        {...props} />} />
      <Route path="/policy/review/:id" component={PolicyReviewAudit} />
    </>
  )
}