import React from 'react'

declare const pdfjsLib: any

interface PdfViewerProps {
  data: any
  pageNo: number
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ data, pageNo }) => {
  const canvasEl = React.useRef(null)

  React.useEffect(() => {
    pdfjsLib.getDocument({ data }).promise
      .then((pdf: any) => pdf.getPage(pageNo))
      .then((page: any) => {

        // Prepare canvas using PDF page dimensions
        const viewport = page.getViewport({ scale: 1.3 })
        if (!canvasEl.current) return
        const canvas = canvasEl.current! as HTMLCanvasElement
        canvas.height = viewport.height
        canvas.width = viewport.width

        // Render PDF page into canvas context
        page.render({ canvasContext: canvas.getContext('2d'), viewport })
      })
  }, [data, pageNo])

  return <canvas ref={canvasEl} />
}