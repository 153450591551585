import React, { FC } from 'react'

type Props = {
  handleReset: () => void
  errMsg: string
}

export const ImportFailed: FC<Props> = ({ handleReset, errMsg }) => {
  return (
    <div className="mx-auto">
      <div className="text-center" style={{ marginTop: '80px' }}>
        <h4>匯入失敗</h4>
        <p className="mt-4 text-danger">{errMsg}</p>
        <div className="mt-4">
          <button className="btn btn-link" onClick={handleReset}>返回</button>
        </div>
      </div>
    </div>
  )
}