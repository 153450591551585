import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  to: string
  className?: string
}

export const AddButton: React.FC<Props> = ({ to, className = '' }) => (
  <Link to={to} className={`btn btn-secondary px-3 ${className}`}>
    <i className="material-icons align-bottom">add</i>
  </Link>
)