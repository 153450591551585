import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'
import Recaptcha from 'react-recaptcha'

import { authStore } from 'mobileApp/store'
import { Path } from '.'
import { BasicLayout } from 'mobileApp/common/layout'


interface ResetPwdRequest { account: string }

export const PasswordReset: React.FC<RouteComponentProps> = (props) => {
  const goBack = () => props.history.goBack()
  const recaptchaEl = React.useRef(null)
  let token: string | null = null
  const verifyCallback = (res: string) => token = res
  const expiredCallback = () => token = null

  return (
    <BasicLayout title="忘記密碼">
      <Formik
        initialValues={{ account: '' }}
        validationSchema={Yup.object().shape({
          account: Yup.string().required('必填欄位！'),
        })}
        onSubmit={({ account }: ResetPwdRequest, { setStatus, setSubmitting }: FormikActions<ResetPwdRequest>) => {
          setStatus()
          if (!token) {
            setStatus('請勾選 reCaptcha 方塊！')
            setSubmitting(false)
            return
          }

          authStore.resetPassword(account, token)
            .then(() => props.history.push(Path.Login))
            .catch(({ message }) => {
              setStatus(message)
              setSubmitting(false)
            })
        }}
        component={formikProps => ResetPwdForm(formikProps, recaptchaEl, verifyCallback, expiredCallback, goBack)}
      />
    </BasicLayout>
  )
}

const ResetPwdForm = ({ errors, status, touched, isSubmitting }: FormikProps<ResetPwdRequest>, ref: any, verifyCallback: any, expiredCallback: any, goBack: any) => (
  <Form>
    <div className="row">
      <div className="form-group col-12 col-sm-6">
        <label htmlFor="account">Email</label>
        <Field name="account" type="text" className={'form-control' + (errors.account && touched.account ? ' is-invalid' : '')} />
        <ErrorMessage name="account" component="div" className="invalid-feedback" />
      </div>
    </div>

    <div className="my-3">
      <Recaptcha ref={ref} sitekey={process.env.REACT_APP_RECAPTCHA} verifyCallback={verifyCallback} expiredCallback={expiredCallback} />
    </div>

    {status && <div className="text-danger">{status}</div>}

    <div className="row mt-5">
      <div className="col-12 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={goBack}>取消</button>
        <button type="submit" className="btn btn-secondary ml-auto" disabled={isSubmitting}>送出</button>
      </div>
    </div>
  </Form>
)