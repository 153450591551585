import React from 'react'

interface Props {
  disabled: boolean,
  onClick: Function,
}

export const ReloadButton: React.FC<Props> = ({ disabled, onClick }) => (
  <button className="btn btn-primary px-3" disabled={disabled} onClick={() => onClick()}>
    <i className="material-icons align-bottom">refresh</i>
  </button>
)