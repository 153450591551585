import React from 'react'
import moment from 'moment'

import { dealerStore, storeStore, policyStore, monthlyReportStore, MonthlyReportItem } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { MonthlySelector, DealerSelector, StoreSelector, ExportButton } from 'adminConsole/component'

enum Code { Loading, Ready, Failed }

export const ReportMonthly: React.FC = props => {
  const [status, setStatus] = React.useState({ code: Code.Loading, message: '' })
  const defaultMonth = moment().format('YYYYMM')
  const [month, setMonth] = React.useState(defaultMonth)
  const [dealerId, setDealerId] = React.useState(0)
  const [storeId, setStoreId] = React.useState(0)

  React.useEffect(() => {
    setStatus({ code: Code.Loading, message: '' })
    Promise.all([
      dealerStore.loadAll(),
      storeStore.loadAll(),
      monthlyReportStore.loadAll(month),
    ]).then(() => setStatus({ code: Code.Ready, message: '' }))
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [month])

  const handleMonthChange = (month: string) => setMonth(month)
  const handleDealerChange = (dealerId: number) => {
    setDealerId(dealerId)
    setStoreId(0)
  }
  const handleStoreChange = (storeId: number) => setStoreId(storeId)

  const { dealers } = dealerStore
  const stores = storeStore.findByDealerId(dealerId)
  const items = monthlyReportStore.items
    .filter(report => dealerId === 0 ? true : report.dealerId === dealerId)
    .filter(report => storeId === 0 ? true : report.storeId === storeId)

  return (
    <>
      <TitleBar title="每月進件" />
      <section className="form-row mb-4">
        <div className="col-3">
          <MonthlySelector value={month} onChange={handleMonthChange} />
        </div>
        <div className="col-3">
          <DealerSelector dealers={dealers} value={dealerId} onChange={handleDealerChange} />
        </div>
        <div className="col-3">
          <StoreSelector stores={stores} value={storeId} onChange={handleStoreChange} />
        </div>
        <div className="col-3 text-right">
          <ExportButton loadDataFn={policyStore.loadMonthlyPolicyInfo} query={month} filename={`要保書 - ${month}.csv`} />
        </div>
      </section>

      <section>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">經銷商名稱</th>
              <th scope="col">門市名稱</th>
              <th scope="col">進件</th>
              <th scope="col">已審核</th>
              <th scope="col">未審核</th>
            </tr>
          </thead>
          <tbody>
            {
              status.code !== Code.Loading ? status.code !== Code.Ready ?
                <FailedMsg msg={status.message} /> :
                <Rows items={items} /> :
                <Loading />
            }
          </tbody>
        </table>
      </section>
    </>
  )
}

const Loading: React.FC = () => (
  <tr>
    <td colSpan={5} className="text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </td>
  </tr>
)

const FailedMsg: React.FC<{ msg: String }> = ({ msg }) => (
  <tr><td colSpan={5} className="text-danger">{msg}</td></tr>
)

const Rows: React.FC<{ items: MonthlyReportItem[] }> = ({ items }) => {
  return (
    <>
      {items.map(item => (
        <tr key={item.id}>
          <td>{item.dealerName}</td>
          <td>{item.storeName}</td>
          <td>{item.caseCount}</td>
          <td>{item.approveCount}</td>
          <td>{item.unreviewedCount}</td>
        </tr>
      ))}
    </>
  )
}