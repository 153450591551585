import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { authStore } from 'mobileApp/store'

const Header = (props: RouteComponentProps) => {
  const { name } = authStore.currUser

  return (
    <TopHeader className="bg-secondary w-100 d-flex justify-content-between align-items-center">
      <LogoSection className="d-flex align-items-end">
        {/* <img className="mr-2" src={`/img/logo_s.png`} alt="logo" height="40px" /> */}
        <h5 className="mb-1 mr-2">安盛保代</h5>
        <h5 className="text-white mb-1">行動投保</h5>
      </LogoSection>
      <MenuSection className="dropdown">
        <button className="btn p-0 dropdown-toggle text-muted" data-toggle="dropdown">
          <i className="material-icons align-middle text-muted">account_circle</i>
          <NameSpan>{name}</NameSpan>
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <Link to="/system/passwordChange" className="dropdown-item text-primary">變更密碼</Link>
          {/* eslint-disable-next-line */}
          <a className="dropdown-item text-primary" onClick={authStore.logout}>登出</a>

          {/* 行動投保App 相關資訊 */}
          <div className="dropdown-divider"></div>
          <div className="dropdown-item d-flex align-items-center">
            <span className="text-muted">行動投保</span>
            <i className="material-icons text-muted ml-2">phone_iphone</i>
          </div>
          <div className="dropdown-item text-muted">版本 {process.env.REACT_APP_VERSION}</div>
        </div>
      </MenuSection>
    </TopHeader>
  )
}

export default withRouter(Header)

const TopHeader = styled.header`
  position: sticky;
  top: 0px;
  height: 56px;
  z-index: 100;
`

const LogoSection = styled.section`
  margin-left: 32px;
`

const MenuSection = styled.section`
  margin-right: 32px;
`

const NameSpan = styled.span`
  padding: 0 8px;
  font-weight: 500;
`