import React from 'react'
import styled from 'styled-components'

interface Props { id: string, value: any, checked: boolean, label: string, onChange: Function }

export const RadioBox: React.FC<Props> = ({ id, value, checked, label, onChange }) => {
  return (
    <Box className="mr-2">
      <input type="radio" id={id} value={value} checked={checked} onChange={e => onChange(parseInt(e.target.value))} />
      <label htmlFor={id}>{label}</label>
    </Box>
  )
}

const Box = styled.div`
  display: inline-block;

  input { display: none;}

  label {
    padding: 8px 16px;
    margin: 0px;
    color: #0088CB;
    background-color: #FFFFFF;
    border: solid 1px #0088CB;
    border-radius: 2px;
    cursor: pointer;
  } 

  input:checked+label {
    color: #FFFFFF;
    background-color: #0088CB;
  }
`