import React from 'react'
import moment, { Moment } from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

interface Props {
  date: Moment | null
  onChange: Function
  isClearable?: boolean
}

export const SingleDatePicker: React.FC<Props> = ({ date, onChange, isClearable = false }) => (
  <Box>
    <DatePicker className="form-control" dateFormat="yyyy / MM / dd" placeholderText="請選擇日期"
      selected={date && date.toDate()} isClearable={isClearable}
      onChange={date => date ? onChange(moment(date)) : onChange(date)} />
    {/* <i className="material-icons fs_caption">calendar_today</i> */}
  </Box>
)

const Box = styled.div`
  position: relative;

  /* i {
    position: absolute;
    top: 14px;
    right: 12px;
    pointer-events: none ;
  } */
`