import React from 'react'
import { Route } from 'react-router'

import { UserList, UserEdit } from '.'

export const User: React.FC = () => {
  const [keyword, setKeyword] = React.useState('')

  return (
    <>
      <Route path="/user" exact render={props => <UserList keyword={keyword} keywordOnChange={setKeyword} {...props} />} />
      <Route path="/user/:id" component={UserEdit} />
    </>
  )
}