import React from 'react'
import { Route } from 'react-router'

import { StoreList, StoreEdit } from '.'

export const Store: React.FC = () => {
  const [keyword, setKeyword] = React.useState('')

  return (
    <>
      <Route path="/dealer/:dealerId/store" exact render={props => <StoreList keyword={keyword} keywordOnChange={setKeyword} {...props} />} />
      <Route path="/dealer/:dealerId/store/:storeId" component={StoreEdit} />
    </>
  )
}