import React, { FC } from 'react'

type Props = { notices: string[] }
export const Notice: FC<Props> = ({ notices }) => {
  return (
    <div>
      <h6 className="text-primary mb-4">注意事項</h6>
      {notices.map(notice => (
        <div key={notice} className="d-flex align-items-center">
          <i className="material-icons mb-3 mr-2" style={{ fontSize: '20px' }}>info_outline</i>
          <p>{notice}</p>
        </div>
      ))}
    </div>
  )
}