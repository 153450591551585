import React from 'react'
import { observer } from 'mobx-react'

import { deviceStore, airpodsStore } from 'mobileApp/store'
import { AirpodsLayout } from '../layout'

enum Code { Loading, Ready, Failed }

export const S05ReviewPolicy: React.FC = observer(props => {
  if (deviceStore.isPortrait) { } // 為了變換方向時可以重新 render
  const [status, setStatus] = React.useState({ code: Code.Loading, message: '' })

  React.useEffect(() => {
    airpodsStore.getApp()
      .then(() => setStatus({ code: Code.Ready, message: '' }))
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [])

  return (
    <AirpodsLayout title="檢視要保書" step={3}>
      {
        status.code !== Code.Loading ? status.code !== Code.Ready ?
          <FailedMsg msg={status.message} /> :
          <Viewer {...props} /> :
          <Loading />
      }
    </AirpodsLayout>
  )
})

const Loading: React.FC = () => (
  <div className="row">
    <div className="col-12 text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </div>
  </div>
)

const FailedMsg: React.FC<{ msg: String }> = ({ msg }) => (
  <div className="row"><div className="col-12 text-danger">{msg}</div></div>
)

const Viewer: React.FC = () => {
  const height = deviceStore.isPhone ? window.innerHeight - (52 + 16 + 74) : window.innerHeight - (56 + 88 + 74)
  const objectUrl = airpodsStore.app
  return (
    <>
      <section className="row">
        <div className="col-12">
          <img src={objectUrl} style={{ height: `${height}px` }} alt="要保書" />
        </div>
      </section>

      <section className="my-3 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={airpodsStore.navi.goBack}>上一步</button>
        <button type="button" className="btn btn-secondary" onClick={airpodsStore.navi.goNext}>下一步</button>
      </section>
    </>
  )
}