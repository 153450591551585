import React, { useState, useEffect, FC } from 'react'
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik'
import * as Yup from 'yup'

import { mdiStore, PhoneInfo, subjectStore, mdiProductCategory } from 'mobileApp/store'
import { MdiLayout } from 'mobileApp/mdi/layout'
import { GeneralBarcodeReader } from 'mobileApp/common/component/barcode'
import { checkImei } from 'share/util'

enum Code { Loading, Ready, Failed }

const receiptRegEx = /^[A-Za-z]{2}\d{8}$/

export const S02PhoneInfo = () => {
  const [status, setStatus] = useState({ code: Code.Loading, message: '' })

  useEffect(() => {
    subjectStore.getSubjects(mdiProductCategory)
      .then(() => { setStatus({ code: Code.Ready, message: '' })})
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [])

  return (
    <MdiLayout title="商品資訊" step={1}>
      {status.code !== Code.Loading ? status.code !== Code.Ready ?
        <FailedMsg msg={status.message} /> :
        <PhoneInfoFormik /> :
        <Loading />
      }
    </MdiLayout>
  )
}

const Loading = () => (
  <div className="row">
    <div className="col-12 text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </div>
  </div>
)

const FailedMsg: FC<{ msg: String }> = ({ msg }) => (
  <div className="row"><div className="col-12 text-danger">{msg}</div></div>
)

const PhoneInfoFormik = () => {
  return (
    <Formik
      initialValues={mdiStore.phoneInfo}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        const subjectPrice = subjectStore.getSubjectById(values.subjectId)[0].price
        setStatus()

        // 上傳前的將 發票號碼 的英文轉為大寫
        values.mbInvoiceNum = values.mbInvoiceNum.toUpperCase()

        if (values.sellPrice && (values.sellPrice < subjectPrice * 0.8 || values.sellPrice > subjectPrice * 1.2)) {
          setStatus('此商品未符合美商安達核保規範，很抱歉將無法進行投保作業！')
          setSubmitting(false)
        } else {
          mdiStore.phoneInfo = values
          mdiStore.navi.goNext()
        }
      }}
      validationSchema={Yup.object().shape({
        imei: Yup.string().required('必填欄位！').lowercase().test('IMEI檢核', '需為 15位或17位數字 或 第一個字為英文字母', checkImei),
        supNo: Yup.string().required('必填欄位！'),
        subjectId: Yup.string().required('必填欄位！'),
        sellPrice: Yup.number().required('必填欄位！'),
        mbInvoiceNum: Yup.string().required('必填欄位！').matches(receiptRegEx, '請輸入有效的發票號碼！'),
      })}
      component={(formikProps) => PhoneInfoForm(formikProps)}
    />
  )
}

const PhoneInfoForm = ({ values, errors, touched, status, isSubmitting, setFieldValue, handleChange, handleBlur }: FormikProps<PhoneInfo>) => {

  const handleSupNoChange = (e: any) => {
    handleChange(e)
    setFieldValue('subjectId', '')
  }

  const handleSubjectChange = (e: any) => {
    const subject = e.target.value && subjectStore.getSubjectById(e.target.value)[0]
    const price = subject ? subject.price : ''
    const importSource = subject ? subject.importSource : ''

    handleChange(e)
    setFieldValue('sellPrice', price)
    setFieldValue('importSource', importSource)
  }

  return (
    <Form>
      <section>
        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="imei">手機 IMEI / 平板 Serial No.</label>
            <Field type="text" name="imei" className={'form-control' + (errors.imei && touched.imei ? ' is-invalid' : '')} />
            <ErrorMessage name="imei" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label>&nbsp;</label>
            <button type="button" className="btn btn-secondary d-block"
              data-toggle="modal" data-target="#barcodeReader">掃描</button>
          </div>
          <GeneralBarcodeReader id="barcodeReader" cb={code => setFieldValue('imei', code)} />
        </div>

        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="supNo">廠牌</label>
            <select name="supNo" onChange={handleSupNoChange} onBlur={handleBlur} value={values.supNo}
              className={'form-control' + (errors.supNo && touched.supNo ? ' is-invalid' : '')}>
              <option value="">請選擇</option>
              {subjectStore.supNos.map((supNo, index) => <option key={index} value={supNo}>{supNo}</option>)}
            </select>
            <ErrorMessage name="supNo" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="subjectId">型號</label>
            <select name="subjectId" onChange={handleSubjectChange} onBlur={handleBlur} value={values.subjectId}
              className={'form-control' + (errors.subjectId && touched.subjectId ? ' is-invalid' : '')}>
              <option value="">請選擇</option>
              {subjectStore.getSubjectsBySupNo(values.supNo).map(subject => (<option key={subject.id} value={subject.id}>{subject.mara} - {subject.capacity}</option>))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="sellPrice">空機售價</label>
            <Field type="number" name="sellPrice" className={'form-control' + (errors.sellPrice && touched.sellPrice ? ' is-invalid' : '')} />
            <ErrorMessage name="sellPrice" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="mbInvoiceNum">發票號碼</label>
            <Field type="text" name="mbInvoiceNum" className={'form-control' + (errors.mbInvoiceNum && touched.mbInvoiceNum ? ' is-invalid' : '')} />
            <ErrorMessage name="mbInvoiceNum" component="div" className="invalid-feedback" />
          </div>
        </div>
      </section>

      <div className="text-danger">{status}</div>

      <section className="my-3 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={mdiStore.navi.goBack}>上一步</button>
        <button type="submit" disabled={isSubmitting} className="btn btn-secondary">下一步</button>
      </section>
    </Form>
  )
}