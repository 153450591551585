import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { deviceStore } from 'mobileApp/store'
import { PhoneHeader, TabletHeader } from '.'

interface LayoutProps {
  title: string
}

const Layout: React.FC<LayoutProps> = props => {
  return deviceStore.isPhone ? <PhoneLayout {...props} /> : <TabletLayout {...props} />
}

export default observer(Layout)

const PhoneLayout: React.FC<LayoutProps> = ({ title, children }) => (
  <>
    <PhoneHeader title={title} />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="mt-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  </>
)

const TitleBar = styled.h5`
  padding: 12px 28px;
  margin: 20px 0;
`
const ContentSection = styled.section`
  padding: 0 36px;
`

const TabletLayout: React.FC<LayoutProps> = ({ title, children }) => (
  <>
    <TabletHeader />
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-10 col-lg-8">
          <TitleBar className="text-white" style={{ backgroundColor: '#6c757d' }}>{title}</TitleBar>
          <ContentSection>{children}</ContentSection>
        </div>
      </div>
    </div>
  </>
)