import { ErrorMessage, Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { districtStore, airpodsStore, PolicyHolderInfo } from 'mobileApp/store'
import { checkAdultCriterion, checkTaiwanIndentity } from 'share/util'
import { AirpodsLayout } from '../layout'
// import { Debug } from "mobileApp/common/component/formik"

export const S03PolicyHolderInfo: React.FC = props => {
  return (
    <Formik
      initialValues={airpodsStore.policyHolderInfo}
      onSubmit={(values, { setStatus, setSubmitting }: FormikActions<PolicyHolderInfo>) => {
        setStatus()

        // 上傳前的必要處理
        values.identity = values.identity.toUpperCase()
        if (values.nationType === '1') values.nationName = ''

        airpodsStore.policyHolderInfo = values
        setSubmitting(false)
        airpodsStore.navi.goNext()
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('必填欄位！').matches(/^\D*$/, '姓名不可以有數字！'),
        birthday: Yup.string().required('必填欄位！').matches(/^(19|20)/, '請填入有效的西元年份！').test('年齡檢核', '要保人需年滿18歲', checkAdultCriterion),
        identity: Yup.string().required('必填欄位！').uppercase().test('身分證字號檢核', '不是有效的身分證字號！', checkTaiwanIndentity),
        // identity: Yup.string().when('nationType', {
        //   is: type => type === '1',
        //   then: Yup.string().required('必填欄位！').uppercase().test('身分證字號檢核', '不是有效的身分證字號！', checkTaiwanIndentity),
        //   otherwise: Yup.string().required('必填欄位！').uppercase().test('外來人口檢核', '不是有效的外來人口統一證號！', checkForeignIndentity),
        // }),
        phoneNo: Yup.string().required('必填欄位！').matches(/^09\d{8}$/, '手機號碼格式不正確！'),
        email: Yup.string().required('必填欄位！').email('email格式不正確！'),
        city: Yup.string().required('必填欄位！'),
        district: Yup.string().required('必填欄位！'),
        address: Yup.string().required('必填欄位！'),
        nationType: Yup.string().required('必填欄位！'),
        // nationName: Yup.string().when('nationType', {
        //   is: type => type === '2',
        //   then: Yup.string().required('必填欄位！'),
        //   otherwise: Yup.string(),
        // }),
        profession: Yup.string().required('必填欄位！'),
        purpose: Yup.string().required('必填欄位！'),
        phAttribute: Yup.string().required('必填欄位！'),
        poProductNo: Yup.string().required('必填欄位！').max(10, '請輸入小於10個字！'),
        phSaleSalaryNo: Yup.string().required('必填欄位！').matches(/^\d{0,20}$/, '請輸入小於20個數字！'),
      })}
      component={(formikProps) => PolicyHolderInfoForm(formikProps)}
    />
  )
}

const PolicyHolderInfoForm = ({ values, errors, touched, isSubmitting, handleChange, handleBlur, setFieldValue }: FormikProps<PolicyHolderInfo>) => {

  const handleCityChange = (e: any) => {
    handleChange(e)
    setFieldValue('district', '')
  }

  return (
    <AirpodsLayout title="要保人資訊" step={2}>
      <Form>

        <section className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="name">姓名</label>
            <Field type="text" name="name" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
            <ErrorMessage name="name" component="div" className="invalid-feedback" />
          </div>
        </section>

        <section className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="birthday">出生日期</label>
            <Field type="date" name="birthday" max="2099-12-31" className={'form-control' + (errors.birthday && touched.birthday ? ' is-invalid' : '')} />
            <ErrorMessage name="birthday" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="identity">身分證字號</label>
            <Field type="text" name="identity" className={'form-control' + (errors.identity && touched.identity ? ' is-invalid' : '')} />
            <ErrorMessage name="identity" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="phoneNo">手機號碼</label>
            <Field type="text" name="phoneNo" className={'form-control' + (errors.phoneNo && touched.phoneNo ? ' is-invalid' : '')} />
            <ErrorMessage name="phoneNo" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="email">Email</label>
            <Field type="email" name="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
            <ErrorMessage name="email" component="div" className="invalid-feedback" />
          </div>
        </section>

        <section className="row">
          <div className="col-12">
            <label>通訊地址</label>
          </div>

          <div className="form-group col-6 col-sm-3">
            <select name="city" onChange={handleCityChange} onBlur={handleBlur} value={values.city}
              className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')}>
              <option value="">請選擇</option>
              {districtStore.cities.map((city, index) => <option key={index} value={city}>{city}</option>)}
            </select>
            <ErrorMessage name="city" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-6 col-sm-3">
            <Field component="select" name="district" className={'form-control' + (errors.district && touched.district ? ' is-invalid' : '')}>
              <option value="">請選擇</option>
              {districtStore.getDistrictsByCity(values.city).map((district, index) => <option key={index} value={district}>{district}</option>)}
            </Field>
            <ErrorMessage name="district" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <Field type="text" name="address" className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')} />
            <ErrorMessage name="address" component="div" className="invalid-feedback" />
          </div>
        </section>

        <section className="row">
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="poProductNo" className="text-primary">投保商品貨號</label>
            <Field type="text" name="poProductNo" className={'form-control' + (errors.poProductNo && touched.poProductNo ? ' is-invalid' : '')} />
            <ErrorMessage name="poProductNo" component="div" className="invalid-feedback" />
          </div>

          <div className="form-group col-12 col-sm-6">
            <label htmlFor="phSaleSalaryNo" className="text-primary">銷售員薪號</label>
            <Field type="text" name="phSaleSalaryNo" className={'form-control' + (errors.phSaleSalaryNo && touched.phSaleSalaryNo ? ' is-invalid' : '')} />
            <ErrorMessage name="phSaleSalaryNo" component="div" className="invalid-feedback" />
          </div>
        </section>

        <section className="row">
          <div className="col-12 text-muted mt-4 mb-3">瞭解要保人及被保險人之需求及其適合度分析評估暨招攬人員報告書（財產保險）</div>

          <div className="col-12">
            <label htmlFor="profession">是否為以下職業？</label>
          </div>

          <div className="form-group col-5 col-md-3">
            <div className="custom-control custom-radio">
              <Field type="radio" name="profession" id="profession1" value="true" checked={values.profession === 'true'}
                className={'custom-control-input' + (errors.profession && touched.profession ? ' is-invalid' : '')} />
              <label htmlFor="profession1" className="custom-control-label" >是</label>
              <ErrorMessage name="profession" component="div" className="invalid-feedback" />
            </div>
          </div>

          <div className="form-group col-5 col-md-3">
            <div className="custom-control custom-radio">
              <Field type="radio" name="profession" id="profession2" value="false" checked={values.profession === 'false'}
                className={'custom-control-input' + (errors.profession && touched.profession ? ' is-invalid' : '')} />
              <label htmlFor="profession2" className="custom-control-label" >否</label>
            </div>
          </div>
        </section>

        <section className="pl-3 pb-3" style={{ color: '#6c757d' }}>
          <div className="d-flex mb-1"><span className="mr-1">＊</span>本人從事以下職業（律師、會計師、公證人，或是其合夥人或受雇人。軍火商、不動產經紀人。當鋪業、融資從業人員。寶石及貴金屬交易商。藝術品／骨董交易商、拍賣公司。基金會、協會／寺廟、教會從業人員。博弈產業／公司。）</div>
          <div className="d-flex mb-1"><span className="mr-1">＊</span>本人為國內外政治人物或與其有密切關聯之人。</div>
          <div className="d-flex"><span className="mr-1">＊</span>本人為金融監督管理委員會或外國政府組織所列之制裁對象。</div>
        </section>

        <section className="row">
          <div className="col-12">
            <label>國籍</label>
          </div>

          <div className="form-group col-5 col-md-3">
            <div className="custom-control custom-radio">
              <Field type="radio" name="nationType" id="nationType1" value="1" checked={values.nationType === '1'}
                className={'custom-control-input' + (errors.nationType && touched.nationType ? ' is-invalid' : '')} />
              <label htmlFor="nationType1" className="custom-control-label" >本國籍</label>
              <ErrorMessage name="nationType" component="div" className="invalid-feedback" />
            </div>
          </div>

          {/* <div className="form-group col-7 col-md-5">
            <div className="custom-control custom-radio">
              <Field type="radio" name="nationType" id="nationType2" value="2" checked={values.nationType === '2'}
                className={'custom-control-input' + (errors.nationType && touched.nationType ? ' is-invalid' : '')} />
              <label htmlFor="nationType2" className="custom-control-label" >外國籍</label>
            </div>
            <div className={'mt-2 ml-3' + (values.nationType === '2' ? '' : ' d-none')}>
              <Field type="text" name="nationName" placeholder="國家名稱"
                className={'form-control' + (errors.nationName && touched.nationName ? ' is-invalid' : '')} />
              <ErrorMessage name="nationName" component="div" className="invalid-feedback" />
            </div>
          </div> */}
        </section>

        <section className="row">
          <div className="col-12">
            <label htmlFor="purpose">投保目的</label>
          </div>

          <div className="form-group col-8 col-md-4">
            <div className="custom-control custom-radio">
              <Field type="radio" name="purpose" id="purpose1" value="true" checked={values.purpose === 'true'}
                className={'custom-control-input' + (errors.purpose && touched.purpose ? ' is-invalid' : '')} />
              <label htmlFor="purpose1" className="custom-control-label" >為個人之財產及利益做風險規劃</label>
              <ErrorMessage name="purpose" component="div" className="invalid-feedback" />
            </div>
          </div>

          <div className="form-group col-4 col-md-4">
            <div className="custom-control custom-radio">
              <Field type="radio" name="purpose" id="purpose2" value="false" checked={values.purpose === 'false'}
                className={'custom-control-input' + (errors.purpose && touched.purpose ? ' is-invalid' : '')} />
              <label htmlFor="purpose2" className="custom-control-label" >其他</label>
            </div>
          </div>
        </section>

        <section className="row">
          <div className="col-12">
            <label htmlFor="phAttribute">客戶屬性</label>
          </div>

          <div className="form-group col-12">
            <div className="custom-control custom-radio">
              <Field type="radio" name="phAttribute" id="phAttribute1" value="false" checked={values.phAttribute === 'false'}
                className={'custom-control-input' + (errors.phAttribute && touched.phAttribute ? ' is-invalid' : '')} />
              <label htmlFor="phAttribute1" className="custom-control-label">非專業客戶</label>
            </div>
          </div>

          <div className="form-group col-12">
            <div className="custom-control custom-radio">
              <Field type="radio" name="phAttribute" id="phAttribute2" value="true" checked={values.phAttribute === 'true'}
                className={'custom-control-input' + (errors.phAttribute && touched.phAttribute ? ' is-invalid' : '')} />
              <label htmlFor="phAttribute2" className="custom-control-label">
                專業客戶（依金融消費者保護法第四條第二項授權規定之專業投資機構：國內外之銀行業、證券業、期貨業、保險業（不包括保險代理人、保險經紀人及保險公證人）、基金管理公司及政府投資機構；
                國內外之政府基金、退休基金、共同基金、單位信託及金融服務業，依證券投資信託及顧問法、期貨交易法或信託業法經理之基金或接受金融消費者委任交付或信託移轉之委任投資資產；
                其他經主管機關認定之機構。為法人，且接受本公司提供保險商品或服務時，最近一期之財務報告總資產達新臺幣五千萬元以上。）
              </label>
              <ErrorMessage name="phAttribute" component="div" className="invalid-feedback" />
            </div>
          </div>
        </section>

        <section className="my-3 d-flex justify-content-between">
          <button type="button" className="btn btn-light" onClick={airpodsStore.navi.goBack}>上一步</button>
          <button type="submit" disabled={isSubmitting} className="btn btn-secondary">下一步</button>
        </section>
        {/* <Debug /> */}
      </Form>
    </AirpodsLayout >
  )
}