import React, { FC } from 'react'

import { SubjectData } from 'adminConsole/store'

export const InvalidSubjectData: FC<Props> = props => {
  return (
    <section className="my-4">
      <p className="mb-4">以下為未通過檢核的資料，請修正後重新匯入！</p>
      <SubjectTable {...props} />
    </section>
  )
}

type Props = { subjects: SubjectData[] }
const SubjectTable: FC<Props> = ({ subjects }) => {
  return (
    <table className="table table-hover" style={{ fontSize: '14px' }}>
      <TableHeader />
      <tbody>
        {subjects.map(subject => <TableRow key={subject.model} subject={subject} />)}
      </tbody>
    </table>
  )
}

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th scope="col">supNo</th>
        <th scope="col">model</th>
        <th scope="col">capacity</th>
        <th scope="col">mara</th>
        <th scope="col">price</th>
        <th scope="col">productNo</th>
        <th scope="col">productCategory</th>
        <th scope="col" style={{ width: '120px' }}>launchDay</th>
      </tr>
    </thead>
  )
}

type TRProps = { subject: SubjectData }
const TableRow: FC<TRProps> = ({ subject }) => {
  const { supNo, model, capacity, mara, price, productNo, productCategory, launchDay } = subject

  return (
    <tr>
      <td>{supNo}</td>
      <td>{model}</td>
      <td>{capacity}</td>
      <td>{mara}</td>
      <td>{price}</td>
      <td>{productNo}</td>
      <td>{productCategory}</td>
      <td>{launchDay}</td>
    </tr>
  )
}
