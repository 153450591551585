import React, { FC } from 'react'

import { PriceBandData } from 'adminConsole/store'

export const InvalidPriceBandData: FC<Props> = props => {
  return (
    <section className="my-4">
      <p className="mb-4">以下為未通過檢核的資料，請修正後重新匯入！</p>
      <PriceBandTable {...props} />
    </section>

  )
}

type Props = { priceBands: PriceBandData[] }
const PriceBandTable: FC<Props> = ({ priceBands }) => {
  return (
    <table className="table table-hover" style={{ fontSize: '14px' }}>
      <TableHeader />
      <tbody>
        {priceBands.map(priceBand => <TableRow key={priceBand.productType} priceBand={priceBand} />)}
      </tbody>
    </table>
  )
}

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th scope="col" className="px-2">dealerNo</th>
        <th scope="col" className="px-2">productCategory</th>
        <th scope="col" className="px-2">paymentType</th>
        <th scope="col" className="px-2">scopeType</th>
        <th scope="col" className="px-2">productType</th>
        <th scope="col" className="px-2">premium</th>
        <th scope="col" className="px-2">priceMin</th>
        <th scope="col" className="px-2">priceMax</th>
        <th scope="col" className="px-2">chgDeductible</th>
        <th scope="col" className="px-2">fixDeductible</th>
      </tr>
    </thead>
  )
}

type TRProps = { priceBand: PriceBandData }
const TableRow: FC<TRProps> = ({ priceBand }) => {
  const { dealerNo, productCategory, paymentType, scopeType, productType, premium, priceMin, priceMax, chgDeductible, fixDeductible } = priceBand

  return (
    <tr>
      <td className="px-2">{dealerNo}</td>
      <td className="px-2">{productCategory}</td>
      <td className="px-2">{paymentType}</td>
      <td className="px-2">{scopeType}</td>
      <td className="px-2">{productType}</td>
      <td className="px-2">{premium}</td>
      <td className="px-2">{priceMin}</td>
      <td className="px-2">{priceMax}</td>
      <td className="px-2">{chgDeductible}</td>
      <td className="px-2">{fixDeductible}</td>
    </tr>
  )
}
