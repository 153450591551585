import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'

import { authStore } from 'adminConsole/store';
import { Path } from 'adminConsole'
import { TitleBar } from 'adminConsole/layout'

interface ChgPwdRequest { password: string, newPassword: string, comfirmPassword: string }
enum Code { Loading, Ready, Failed }

export const PasswordChange: React.FC<RouteComponentProps> = (props) => {
  const [status, setStatus] = React.useState({ code: Code.Loading, message: '' })
  React.useEffect(() => {
    authStore.loadPwdRules()
      .then(() => setStatus({ code: Code.Ready, message: '' }))
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [])

  return (
    <>
      <TitleBar title="密碼變更" />
      {
        status.code !== Code.Loading ? status.code !== Code.Ready ?
          <FailedMsg msg={status.message} /> :
          <ChgPwdFormik {...props} /> :
          <Loading />
      }
    </>
  )
}

const Loading: React.FC = () => (
  <div className="row">
    <div className="col-12 text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </div>
  </div>
)

const FailedMsg: React.FC<{ msg: String }> = ({ msg }) => (
  <div className="row"><div className="col-12 text-danger">{msg}</div></div>
)

const ChgPwdFormik: React.FC<RouteComponentProps> = props => (
  <Formik
    initialValues={{
      password: '',
      newPassword: '',
      comfirmPassword: '',
    }}
    validationSchema={Yup.object().shape({
      password: Yup.string().required('必填欄位！'),
      newPassword: Yup.string().required('必填欄位！').min(authStore.pwdRules.min, `密碼長度不可少於${authStore.pwdRules.min}`),
      comfirmPassword: Yup.string().required('必填欄位！').oneOf([Yup.ref('newPassword')], "與新密碼不相符！"),
    })}
    onSubmit={({ password, newPassword, comfirmPassword }: ChgPwdRequest, { setStatus, setSubmitting }: FormikActions<ChgPwdRequest>) => {
      setStatus()
      authStore.changePassword(password, newPassword)
        .then(() => props.history.push(Path.Root))
        .catch(({ message }) => {
          setStatus(message)
          setSubmitting(false)
        })
    }}
    component={formikProps => ChgPwdForm(formikProps, props)}
  />
)

const ChgPwdForm = ({ errors, status, touched, isSubmitting }: FormikProps<ChgPwdRequest>, { history }: RouteComponentProps) => (
  <Form>
    <div className="row">
      <div className="form-group col-12 col-sm-6">
        <label htmlFor="password">密碼</label>
        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
        <ErrorMessage name="password" component="div" className="invalid-feedback" />
      </div>
    </div>

    <div className="row">
      <div className="form-group col-12 col-sm-6">
        <label htmlFor="newPassword">新密碼</label>
        <Field name="newPassword" type="password" className={'form-control' + (errors.newPassword && touched.newPassword ? ' is-invalid' : '')} />
        <ErrorMessage name="newPassword" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="comfirmPassword">新密碼確認</label>
        <Field name="comfirmPassword" type="password" className={'form-control' + (errors.comfirmPassword && touched.comfirmPassword ? ' is-invalid' : '')} />
        <ErrorMessage name="comfirmPassword" component="div" className="invalid-feedback" />
      </div>

      <div className="col-12 text-muted">
        密碼規則：
          <ul>
          {authStore.pwdRules.min !== 0 ? <li>長度不可少於 {authStore.pwdRules.min}</li> : null}
          {authStore.pwdRules.digit !== 0 ? <li>必須有 {authStore.pwdRules.digit} 數字</li> : null}
          {authStore.pwdRules.upper !== 0 ? <li>必須有 {authStore.pwdRules.upper} 大寫英文字母</li> : null}
          {authStore.pwdRules.lower !== 0 ? <li>必須有 {authStore.pwdRules.lower} 小寫英文字母</li> : null}
          {authStore.pwdRules.special !== 0 ? <li>必須有 {authStore.pwdRules.special} 特殊字元</li> : null}
        </ul>
      </div>
    </div>

    {status && <div className="text-danger">{status}</div>}

    <div className="row mt-3">
      <div className="col-12 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={() => history.goBack()}>取消</button>
        <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>送出</button>
      </div>
    </div>
  </Form>
)