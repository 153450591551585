import moment from 'moment'
import styled from 'styled-components'

import { Modal } from 'mobileApp/common/component/modal'
import { Clause1_1Y, Clause1_2Y, Clause1_2Y2, Clause1_36M2, Clause1_3Y, Clause2_I, Clause2_L, Clause2_P, Clause3_E, Clause3_G, ClauseInsurancePolicy, airpodsStore, authStore } from 'mobileApp/store'
import { AirpodsLayout } from '../layout'

export const S09PremiumInfo = () => {
  const { maintainCenter } = authStore.currUser
  const { paymentType, currentOption, paymentTypeDesc } = airpodsStore
  const { premium, chgDeductible } = currentOption

  const yearAmount = (paymentType === '3Y' || paymentType === 'M') && 3 || paymentType === '2Y' && 2 || 1

  return (
    <AirpodsLayout title="繳款確認" step={3}>
      <section className="mb-3">
        { (paymentType === 'M' || paymentType === '2Y') ? 
            <div>承保行動裝置無法正常使用或因不可預料之事故所致毀損</div> :
            <div>承保行動裝置因不可預料之事故所致毀損</div> }
        <div className="text-primary">（賠償方式僅限置換）</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">保險期間</div>
        <div className="col-12 col-sm-8">{`${moment().format('YYYY／M／D')} ～ ${moment().add(yearAmount, 'year').subtract(1, 'day').format('YYYY／M／D')}`}</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">保險期間累積最高賠償次數</div>
        <div className="col-5 col-sm-2 fs_caption text-muted">第一年<span className="h5 text-dark px-2 m-0">3</span>次</div>
        { (paymentType === 'M' || paymentType === '2Y' || paymentType === '3Y') && <div className="col-5 col-sm-2 fs_caption text-muted">第二年<span className="h5 text-dark px-2 m-0">3</span>次</div> }
        { (paymentType === 'M' || paymentType === '3Y') && <div className="col-5 col-sm-2 fs_caption text-muted">第三年<span className="h5 text-dark px-2 m-0">3</span>次</div> }
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">自負額</div>
        <div className="col-5 col-sm-4 fs_caption text-muted">置換<span className="h5 text-dark px-2 m-0">{chgDeductible}</span>元</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">指定維修中心</div>
        <div className="col-12 fs_body1 col-sm-8">{maintainCenter}</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-12 col-sm-4 text-primary">保險費</div>
        <div className="col-12 col-sm-8 fs_caption text-muted">新台幣<span className="h5 text-dark px-2 m-0">{premium}</span>元</div>
      </section>

      <section className="row align-items-center mb-3">
        <div className="col-sm-4 text-primary">繳款方式</div>
        <div className="col-sm-8">{paymentTypeDesc}</div>
      </section>

      <section className="row mb-3">
        <div className="col-sm-4 text-primary">本保險契約適用附加條款</div>
        <div className="col-sm-8 text-muted fs_caption mt-3 mt-sm-0">
          { 
            paymentType === 'M' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_36M2">安達產物行動裝置保險（分期交付申型）</ClauseBtn> :
            paymentType === '1Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_1Y">安達產物行動裝置保險（一次交付寅型）</ClauseBtn> :
            paymentType === '2Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_2Y2">安達產物行動裝置保險（一次交付未型）</ClauseBtn> :
            paymentType === '3Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause1_3Y">安達產物行動裝置保險（一次交付亥型）</ClauseBtn> : null
          }
          { 
            paymentType === 'M' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_2Y2">安達產物行動裝置竊盜損失附加條款（L）</ClauseBtn> :
            paymentType === '1Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_1Y">安達產物行動裝置竊盜損失附加條款（一次交付I）</ClauseBtn> :
            paymentType === '2Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_2Y2">安達產物行動裝置竊盜損失附加條款（L）</ClauseBtn> :
            paymentType === '3Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_2Y3">安達產物行動裝置保險竊盜損失附加條款（P）</ClauseBtn> : null
          }
          { 
            paymentType === 'M' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_E">安達產物行動裝置保險電池維修置換附加條款（E）</ClauseBtn> :
            paymentType === '2Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_E">安達產物行動裝置保險電池維修置換附加條款（E）</ClauseBtn> :
            paymentType === '3Y' ? <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause2_G">安達產物行動裝置保險電池維修置換附加條款（G）</ClauseBtn> : null
          }
        </div>
      </section>

      <section className="row mb-3">
        <div className="col-sm-4 text-primary">財產保險投保人須知</div>
        <div className="col-sm-8 text-muted fs_caption mt-3 mt-sm-0">
          <ClauseBtn type="button" className="btn" data-toggle="modal" data-target="#clause_insurance_policy">安達產物財產保險投保人須知</ClauseBtn>
        </div>
      </section>

      {
        paymentType === 'M' ? <Modal id="clause1_36M2" title="安達產物行動裝置保險（分期交付申型）" contentFn={Clause1_36M2} /> :
        paymentType === '1Y' ? <Modal id="clause1_1Y" title="安達產物行動裝置保險（一次交付寅型）" contentFn={Clause1_1Y} /> :
        paymentType === '2Y' ? <Modal id="clause1_2Y2" title="安達產物行動裝置保險（一次交付未型）" contentFn={Clause1_2Y2} /> :
        paymentType === '3Y' ? <Modal id="clause1_3Y" title="安達產物行動裝置保險（一次交付亥型）" contentFn={Clause1_3Y} /> : null
      }

      {
        paymentType === 'M' ? <Modal id="clause2_2Y2" title="安達產物行動裝置竊盜損失附加條款（L）" contentFn={Clause2_L} /> :
        paymentType === '1Y' ? <Modal id="clause2_1Y" title="安達產物行動裝置竊盜損失附加條款（一次交付I）" contentFn={Clause2_I} /> :
        paymentType === '2Y' ? <Modal id="clause2_2Y2" title="安達產物行動裝置竊盜損失附加條款（L）" contentFn={Clause2_L} /> :
        paymentType === '3Y' ? <Modal id="clause2_2Y3" title="安達產物行動裝置保險竊盜損失附加條款（P）" contentFn={Clause2_P} /> : null
      }

      {
        paymentType === 'M' ? <Modal id="clause2_E" title="安達產物行動裝置保險電池維修置換附加條款（E）" contentFn={Clause3_E} /> :
        paymentType === '2Y' ? <Modal id="clause2_E" title="安達產物行動裝置保險電池維修置換附加條款（E）" contentFn={Clause3_E} /> :
        paymentType === '3Y' ? <Modal id="clause2_G" title="安達產物行動裝置保險電池維修置換附加條款（G）" contentFn={Clause3_G} /> : null
      }

      <Modal id="clause_insurance_policy" title="安達產物財產保險投保人須知" contentFn={ClauseInsurancePolicy} />

      <section className="my-3 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={airpodsStore.navi.goBack}>上一步</button>
        <button type="button" className="btn btn-secondary" onClick={airpodsStore.navi.goNext}>下一步</button>
      </section>
    </AirpodsLayout>
  )
}

const ClauseBtn = styled.button`
  display: block;
  color: #6c757d;
  padding: 0px;
  margin-bottom: 1rem;
  text-decoration: underline;
  text-align: left;
`