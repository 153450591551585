import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { deviceStore } from 'mobileApp/store'
import { PhoneHeader, TabletHeader, ProgressIndicator } from '../../common/layout'

const piTitles = ['同意書', '商品資訊', '要保人資訊', '保險內容', '線上支付', '投保完成']

interface LayoutProps {
  title: string,
  step: number
}

const Layout: React.FC<LayoutProps> = props => {
  return deviceStore.isPhone ? <PhoneLayout {...props} /> : <TabletLayout {...props} />
}

export default observer(Layout)

const PhoneLayout: React.FC<LayoutProps> = ({ title, step, children }) => (
  <>
    <PhoneHeader title={title} />
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12">
          <ProgressIndicator titles={piTitles} step={step} />
          <div className="mt-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  </>
)

const TitleBar = styled.h5`
  padding: 12px 28px;
  margin: 20px 0;
`
const ContentSection = styled.section`
  padding: 0 36px;
`

const TabletLayout: React.FC<LayoutProps> = ({ title, step, children }) => (
  <>
    <TabletHeader />
    <div className="container-fluid">
      <div className="row">
        <div className="col-2">
          <ProgressIndicator titles={piTitles} step={step} />
        </div>

        <div className="col-sm-10 col-lg-9 col-xl-8 offset-xl-1">
          <TitleBar className="text-white" style={{ backgroundColor: '#6c757d' }}>{title}</TitleBar>
          <ContentSection>{children}</ContentSection>
        </div>
      </div>
    </div>
  </>
)