import React from 'react'
import { FormikProps, Field, ErrorMessage } from 'formik'

import { Store } from 'adminConsole/store'

interface Props { id: string, stores: Store[], formikProps: FormikProps<any> }

export const StoreField: React.FC<Props> = ({ id, stores, formikProps }) => {
  const { errors, touched } = formikProps

  return (
    <div className="form-group">
      <label htmlFor={id}>門市</label>
      <Field component="select" name={id} className={'form-control' + (errors[id] && touched[id] ? ' is-invalid' : '')}>
        <option value="0">請選擇</option>
        {stores.map(store => <option key={store.id} value={store.id}>{store.name}</option>)}
      </Field>
      <ErrorMessage name={id} component="div" className="invalid-feedback" />
    </div>
  )
}