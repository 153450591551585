import React from 'react'
import toastr from 'toastr'

enum Code { Idle, Loading, Failed }

interface Props { loadDataFn: Function, query: string | any, filename: string }

export const ExportButton: React.FC<Props> = ({ loadDataFn, query, filename }) => {
  const [status, setStatus] = React.useState({ code: Code.Idle, message: '' })

  React.useEffect(() => {
    setStatus({ code: Code.Idle, message: '' })
  }, [query])

  const handleExport = () => {
    setStatus({ code: Code.Loading, message: '' })
    loadDataFn(query, filename)
      .then(() => { setStatus({ code: Code.Idle, message: '' }) })
      .catch(({ message }: any) => {
        setStatus({ code: Code.Failed, message })
        toastr.error(message)
      })
  }

  return (
    <>
      {
        status.code !== Code.Loading ? status.code !== Code.Failed ?
          <IdleButton onClick={handleExport} /> :
          <FailedButton /> :
          <LoadingButton />
      }
    </>
  )
}

const LoadingButton: React.FC = () => {
  return (
    <button type="button" className="btn btn-secondary">
      <div className="spinner-border spinner-border-sm" />
    </button>
  )
}

const FailedButton: React.FC = () => {
  return (
    <button type="button" className="btn btn-secondary">
      <i className="material-icons align-bottom">error_outline</i>
    </button>
  )
}

const IdleButton: React.FC<{ onClick: Function }> = ({ onClick }) => {
  return (
    <button type="button" className="btn btn-secondary" onClick={() => onClick()}>
      <i className="material-icons align-bottom">save_alt</i>
    </button>
  )
}