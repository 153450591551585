import { service, authStore } from '.'

const currectAnswers: { [key: string]: string } = {
  q1: '3', q2: '1', q3: '3', q4: '1', q5: '3',
  q6: '2', q7: '3', q8: '3', q9: '2', q10: '1',
}

class Store {
  private _myScore = 0
  private _passScore = 7

  get myScore() { return this._myScore }
  get isPass() { return this._myScore >= this._passScore }

  markPaper = async (myAnswers: { [key: string]: string }) => {
    this._myScore = Object.keys(myAnswers)
      .filter(key => myAnswers[key] === currectAnswers[key])
      .reduce(acc => acc + 1, 0)

    if (!this.isPass) return 200

    const res = await service.uploadScore(this._myScore)
    if (res.status === 200) {
      authStore.updateQuestionnaire(this.isPass)
      return Promise.resolve()
    }
    else return Promise.reject()
  }
}

export const questionnaireStore = new Store()