import { read, utils } from 'xlsx'

export const parseExcelToJSON = async (file: File) => {
  const data = await file.arrayBuffer()
  const wb = read(data)

  const totalJSONData = wb.SheetNames.reduce((total, next) => {
    const sheetData = utils.sheet_to_json(wb.Sheets[next], { raw: false }) 
    return total.concat(sheetData as any)
  }, [])

  return totalJSONData
}