import React from 'react'
import styled from 'styled-components'

interface Props {
  value: string
  onChange: Function
}

export const SearchBox: React.FC<Props> = ({ onChange, value }) => (
  <Bar>
    <input type="text" name="keyword" className="form-control" placeholder="Search...." value={value} onChange={e => onChange(e.target.value)} />
    <i className="material-icons text-muted">search</i>
  </Bar>
)

const Bar = styled.div`
  position: relative;

  i {
    position: absolute;
    top: 9px;
    right: 8px;
  }
`