import { observable, action, makeObservable } from 'mobx'

const isPhoneDevice = () => (window.screen.width < 576 || window.screen.height < 576) ? true : false

const isPortraitMode = () => {
  // android 可以透過 screen.orientation 偵測
  if (window.screen.orientation) return /portrait/i.test(window.screen.orientation.type)
  else return window.matchMedia("(orientation: portrait)").matches
}

class Store {
  @observable private _isPhone = isPhoneDevice()
  @observable private _isPortrait = isPortraitMode()

  constructor() { makeObservable(this) }

  get isPhone() { return this._isPhone }
  get isPortrait() { return this._isPortrait }

  get width() {
    const { innerWidth, innerHeight } = window
    if (this.isPortrait) return innerWidth < innerHeight ? innerWidth : innerHeight
    else return innerWidth > innerHeight ? innerWidth : innerHeight
  }

  get height() {
    const { innerWidth, innerHeight } = window
    if (this.isPortrait) return innerHeight > innerWidth ? innerHeight : innerWidth
    else return innerHeight < innerWidth ? innerHeight : innerWidth
  }

  @action detectDevice = () => {
    this._isPhone = isPhoneDevice()
    this._isPortrait = isPortraitMode()
  }
}

export const deviceStore = new Store()