import React from 'react'
import { Route } from 'react-router'

import { DealerList, DealerEdit, Store } from '.'

export const Dealer: React.FC = () => {
  const [keyword, setKeyword] = React.useState('')

  return (
    <>
      <Route path="/dealer" exact render={props => <DealerList keyword={keyword} keywordOnChange={setKeyword} {...props} />} />
      <Route path="/dealer/:id" exact component={DealerEdit} />
      <Route path="/dealer/:id/store" component={Store} />
    </>
  )
}