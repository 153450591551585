import { Moment } from 'moment'
import { service } from '.'

export interface DailyReportItem {
  id: number,
  dealerId: number, dealerName: string,
  storeId: number, storeName: string,
  ymd: string, caseCount: number
}

class DailyReportStore {
  private _items: DailyReportItem[] = []

  get items() { return this._items }

  loadAll = async (date: Moment) => {
    const res = await service.getDailyReports(date.format('YYYYMMDD'))
    if (res.status === 200) {
      this._items = await res.json()
      return Promise.resolve()
    }
    else if(res.status === 401) { }
    else return Promise.reject({ message: `無法取得每日進件資訊！ 錯誤代碼：${res.status}` })
  }
}

export const dailyReportStore = new DailyReportStore()