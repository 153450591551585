import React, { useState, useEffect, FC } from 'react'

import { salesStore, dealerStore, Dealer } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { DealerSelector, ExportButton } from 'adminConsole/component'

enum Code { Loading, Ready, Failed }

export const Export = () => {
  const [status, setStatus] = useState({ code: Code.Loading, message: '' })
  const [dealerId, setDealerId] = useState(0)

  useEffect(() => {
    Promise.all([
      dealerStore.loadAll(),
      salesStore.loadAll(),
    ]).then(() => setStatus({ code: Code.Ready, message: '' }))
      .catch(({ message }) => setStatus({ code: Code.Failed, message }))
  }, [])

  const handleDealerChange = (dealerId: number) => setDealerId(dealerId)

  const { dealers } = dealerStore

  const currDealer = dealerId !== 0 && dealers.find(dealer => dealer.id === dealerId)
  const currDealerName = currDealer ? ` - ${currDealer.name}` : ''

  return (
    <>
      <TitleBar title="資料匯出" />
      <section className="row mb-4">
        <div className="col-12 text-muted mb-3">業務員測驗成績下載</div>
        <>
          {status.code !== Code.Loading ? status.code !== Code.Ready ?
            <FailedMsg msg={status.message} /> :
            <ExportRow dealers={dealers} dealerId={dealerId} currDealerName={currDealerName} handleDealerChange={handleDealerChange} /> :
            <Loading />
          }
        </>
      </section>
    </>
  )
}

const Loading = () => (
  <div className="row">
    <div className="col-12 text-primary">
      載入中...<div className="spinner-border spinner-border-sm ml-2" />
    </div>
  </div>
)

const FailedMsg: FC<{ msg: String }> = ({ msg }) => (
  <div className="row"><div className="col-12 text-danger">{msg}</div></div>
)

interface Props { dealers: Dealer[], dealerId: number, currDealerName: string, handleDealerChange: Function }
const ExportRow: FC<Props> = ({ dealers, dealerId, currDealerName, handleDealerChange }) => {
  return (
    <>
      <div className="col-3">
        <DealerSelector dealers={dealers} value={dealerId} onChange={handleDealerChange} />
      </div>

      <div className="col-3">
        <ExportButton loadDataFn={salesStore.getExamResult} query={dealerId} filename={`業務員測驗${currDealerName}.csv`} />
      </div>
    </>
  )
}
