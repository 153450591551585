import React from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import { observer } from 'mobx-react'

import { ProtectedRoute } from '.'
import { authStore, Role } from 'adminConsole/store'
import {
  Login, PasswordReset, PasswordChange,
  PolicySearch,
  Sales, User, Dealer,
  PolicyReview,
  ReportDaily, ReportMonthly,
  Export, ExcelImport
} from './page'
import { Layout } from 'adminConsole/layout'

export enum Path {
  Root = '/',
  Login = '/login',
  PasswordChange = '/password/change',
  PasswordReset = '/password/reset',

  PolicySearchList = '/policy/search',
  SalesList = '/sales',
  UserList = '/user',
  DealerList = '/dealer',

  PolicyReviewList = '/policy/review',
  ReportDaily = '/report/daily',
  ReportMonthly = '/report/monthly',

  Export = '/export',
  Import = '/import',
}

export const AdminConsole: React.FC = observer(() => {
  const { isLogin, myRole } = authStore
  if (!isLogin) return <PublicRouter />
  if ([Role.Admin].includes(myRole)) return <AdminRouter />
  if ([Role.SenYoung, Role.Chubb].includes(myRole)) return <UserRouter />
  return <div className="text-danger">登入者權限異常！</div>
})

const PublicRouter = () => (
  <Router basename="/admin">
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/password/reset" component={PasswordReset} />

      <Redirect to="/login" />
    </Switch>
  </Router>
)

const AdminRouter = () => (
  <Router basename="/admin">
    <Layout>
      <Switch>
        <ProtectedRoute path="/policy/search" component={PolicySearch} />

        <ProtectedRoute path="/sales" component={Sales} />
        <ProtectedRoute path="/user" component={User} />
        <ProtectedRoute path="/dealer" component={Dealer} />
        <ProtectedRoute path="/export" component={Export} />
        <ProtectedRoute path="/import" component={ExcelImport} />

        <ProtectedRoute path="/password/change" component={PasswordChange} />
        <Redirect to="/policy/search" />
      </Switch>
    </Layout>
  </Router>

)

const UserRouter = () => (
  <Router basename="/admin">
    <Layout>
      <Switch>
        <ProtectedRoute path="/policy/review" component={PolicyReview} />

        <ProtectedRoute path="/report/daily" component={ReportDaily} />
        <ProtectedRoute path="/report/monthly" exact component={ReportMonthly} />

        <ProtectedRoute path="/password/change" component={PasswordChange} />
        <Redirect to="/policy/review" />
      </Switch>
    </Layout>
  </Router>
)