import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik, Field, Form, ErrorMessage, FormikProps, FormikActions } from 'formik'
import * as Yup from 'yup'
import toastr from 'toastr'

import { storeStore, Store } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
// import { Debug } from 'share/component/formik'

enum Status { Loading, Ready, Failed }
interface Params {
  dealerId: string
  storeId: string
}

export const StoreEdit: React.FC<RouteComponentProps<Params>> = props => {
  const dealerId = parseInt(props.match.params.dealerId)
  const storeId = parseInt(props.match.params.storeId)
  const [status, setStatus] = React.useState(Status.Loading)
  const { goBack } = props.history

  React.useEffect(() => {
    storeStore.loadOne(storeId, dealerId)
      .then(() => setStatus(Status.Ready))
      .catch(({ message }) => {
        toastr.error(message)
        setStatus(Status.Failed)
      })
  }, [storeId, dealerId])

  return (
    <>
      <TitleBar title="門市編輯" isLoading={status === Status.Loading} />
      <main className="my-3 px-3">
        {
          status === Status.Failed ?
            <button type="button" className="btn btn-light" onClick={() => goBack()}>返回</button> :
            ''
        }
        {
          status === Status.Ready ?
            <Formik
              initialValues={storeStore.store}
              validationSchema={Yup.object().shape({
                id: Yup.number(),
                dealerId: Yup.number(),
                code: Yup.string().required('必填欄位！').max(15, '門市代碼長度不可超過15位'),
                name: Yup.string().required('必填欄位！'),
                status: Yup.boolean().required('必填欄位！'),
              })}
              onSubmit={(values: Store, { setStatus, setSubmitting }: FormikActions<Store>) => {
                setStatus()
                storeStore.save(values)
                  .then(goBack)
                  .catch(({ message }) => {
                    setStatus(message)
                    setSubmitting(false)
                  })
              }}
              component={formikProps => StoreForm(formikProps, goBack)}
            /> : ''
        }
      </main>
    </>
  )
}

const StoreForm = ({ errors, status, touched, isSubmitting }: FormikProps<Store>, goBack: Function) => (
  <Form>
    <div className="row">
      <div className="form-group col-12 col-sm-6">
        <label htmlFor="name">門市名稱</label>
        <Field type="text" name="name" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
        <ErrorMessage name="name" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="code">門市代碼</label>
        <Field type="text" name="code" className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')} />
        <ErrorMessage name="code" component="div" className="invalid-feedback" />
      </div>

      <div className="form-group col-12 col-sm-6">
        <label htmlFor="status">狀態</label>
        <Field component="select" name="status" className={'form-control' + (errors.status && touched.status ? ' is-invalid' : '')}>
          <option value="true">啟用</option>
          <option value="false">停用</option>
        </Field>
        <ErrorMessage name="status" component="div" className="invalid-feedback" />
      </div>

      <div className="col-12 text-danger">{status}</div>
    </div>

    <div className="row mt-3">
      <div className="col-12 d-flex justify-content-between">
        <button type="button" className="btn btn-light" onClick={() => goBack()}>取消</button>
        <button type="submit" className="btn btn-secondary" disabled={isSubmitting}>儲存</button>
      </div>
    </div>
    {/* <Debug /> */}
  </Form>
)