import React from 'react'

import { deviceStore } from 'mobileApp/store'
import { TabletProgressIndicator } from '.'

interface Props {
  titles: Array<string>
  step: number
}

const Indicator: React.FC<Props> = ({ titles, step }) => (
  // deviceStore.isPhone ? <PhoneProgressIndicator titles={titles} step={step} /> : <TabletProgressIndicator titles={titles} step={step} />
  deviceStore.isPhone ? null : <TabletProgressIndicator titles={titles} step={step} />
)

export default Indicator