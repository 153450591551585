import React from 'react'

import { Dealer } from 'adminConsole/store'

interface Props {
  dealers: Dealer[]
  value: number
  onChange: Function
}

export const DealerSelector: React.FC<Props> = ({ dealers, value, onChange }) => {
  return (
    <select className="custom-select" value={value} onChange={e => onChange(parseInt(e.target.value))}>
      <option value="0">請選擇經銷商</option>
      {dealers.map(dealer => <option key={dealer.id} value={dealer.id}>{dealer.name}</option>)}
    </select>
  )
}