import { service } from '.'

// enum ProductCategory { phone, accessories, laptop }

export interface Premium { 
  id: number, productCategory: string, productType: string, paymentType: string, scopeType: string, 
  priceMin: number, priceMax: number, premium: string, fixDeductible: string, chgDeductible: string, 
  dealerNo?: string, createTime?: string, updateTime?: string,
}

// 一次交付／躉繳：single premium
// 分期交付：regular premium
// chgDeductible：置換自付額
// fixDeductible：維修自付額

class PremiumStore {
  private _premiums: Premium[] = []

  getPremiums = async (productType: string) => {
    const res = await service.getPremiums(productType)

    if (res.status === 200) {
      this._premiums = await res.json()
      return Promise.resolve()
    }
    else return Promise.reject({ message: `無法取得保險費用級距！  錯誤代碼：${res.status}`})
  }

  getPremiumsForLaptop = (targetProductCategory: string, price: string | number, supNo?: string) => {
    return this._premiums.filter(({ productCategory, scopeType, priceMin, priceMax }) => {
      return supNo === 'Apple' ?
        targetProductCategory === productCategory && scopeType === 'Apple' && (priceMin <= Number(price)) && (priceMax >= Number(price)) :
        targetProductCategory === productCategory && scopeType === 'NonApple' && (priceMin <= Number(price)) && (priceMax >= Number(price))
    })
  }

  getPremiumsForPhoneAndAccessories = (targetProductCategory: string, price: string | number) => {
    return this._premiums.filter(({ productCategory, priceMin, priceMax }) => {
      return targetProductCategory === productCategory && (priceMin <= Number(price)) && (priceMax >= Number(price))
    })
  }

  getPremiumsByProductCategoryAndPrice = (targetProductCategory: string, price: string | number, supNo?: string) => {
    // if laptop, need to filter supNo is Apple or not
    if (targetProductCategory === 'laptop') return this.getPremiumsForLaptop(targetProductCategory, price, supNo)
    else return this.getPremiumsForPhoneAndAccessories(targetProductCategory, price)
  }
}

export const premiumStore = new PremiumStore()