import React from 'react'

interface Props { totalElements: number, pageSize: number, currPage: number, onChange: Function }

export const Pagination: React.FC<Props> = ({ totalElements, pageSize, currPage, onChange }) => {
  const totalPages = Math.ceil(totalElements / pageSize)
  const pages = Array.from({ length: totalPages }, (v, k) => k + 1)
  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={'page-item' + (currPage === 1 ? ' disabled' : '')}>
          <div className="page-link" onClick={() => onChange(currPage - 1)}>上一頁</div>
        </li>
        {
          pages.map(page => (
            <li key={page} className={'page-item' + (currPage === page ? ' active' : '')}>
              <div className="page-link" onClick={() => onChange(page)}>{page}</div>
            </li>
          ))
        }
        <li className={'page-item' + (currPage === totalPages ? ' disabled' : '')}>
          <div className="page-link" onClick={() => onChange(currPage + 1)}>下一頁</div>
        </li>
      </ul>
    </nav>
  )
}
