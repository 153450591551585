import React from 'react'
import { FieldAttributes, connect, getIn, Field, ErrorMessage } from 'formik'

const MyField: React.FC<FieldAttributes<any>> = ({ formik, name, label, children, ...rest }) => {
  const error = getIn(formik.errors, name)
  const touched = getIn(formik.touched, name)

  return (
    <>
      {label ? <label htmlFor={name}>{label}</label> : ''}
      <Field name={name} className={'form-control' + (error && touched ? ' is-invalid' : '')} {...rest}>
        {children}
      </Field>
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </>
  )
}

export default connect(MyField)