import React from 'react'
import { RouteComponentProps } from 'react-router'
import moment from 'moment'
import toastr from 'toastr'

import { policyStore, StatusType, dealerStore, storeStore, Policy } from 'adminConsole/store'
import { TitleBar } from 'adminConsole/layout'
import { DealerSelector, StoreSelector, SearchBox, RadioBox, Pagination } from 'adminConsole/component'
import { Path } from 'adminConsole'

interface Props extends RouteComponentProps {
  dealerId: number
  dealerOnChange: Function
  storeId: number
  storeOnChange: Function
  keyword: string
  keywordOnChange: Function
  poStatus: StatusType
  poStatusOnChange: Function
  currPage: number
  currPageOnChange: Function
}

export const PolicyReviewList: React.FC<Props> = ({ dealerId, dealerOnChange, storeId, storeOnChange,
  keyword, keywordOnChange, poStatus, poStatusOnChange, currPage, currPageOnChange, history }) => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(true)
    Promise.all([
      dealerStore.loadAll(),
      storeStore.loadAll(),
      policyStore.loadByStatus(poStatus),
    ]).then()
      .catch(({ message }) => toastr.error(message))
      .finally(() => setIsLoading(false))
  }, [poStatus])

  const dealers = dealerStore.findByValid()
  const stores = storeStore.findByDealerIdAndValid(dealerId)
  const totalPolicies = policyStore.policies
    .filter(policy => dealerId === 0 ? true : policy.dealerId === dealerId)
    .filter(policy => storeId === 0 ? true : policy.storeId === storeId)
    .filter(policy =>
      policy.salesName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.no.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.phName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.syApproveName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.stSyApprove.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.stPay.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      policy.syMemo.toLowerCase().indexOf(keyword.toLowerCase()) > -1
    )

  const pageSize = 10
  const policies = totalPolicies.filter((p, i) => i + 1 > pageSize * (currPage - 1) && i + 1 <= pageSize * currPage)

  const handlePolicyClick = (policyId: number, policyNo: string, stCcAuth: string) => {
    const haveStCcAuth = stCcAuth !== ''
    return history.push(`${Path.PolicyReviewList}/${policyId}?policyNo=${policyNo}&haveStCcAuth=${haveStCcAuth}`)
  }

  return (
    <>
      <TitleBar title="保單審核" isLoading={isLoading} />
      <section className="form-row mb-4">
        <div className="col-3">
          <DealerSelector dealers={dealers} value={dealerId} onChange={dealerOnChange} />
        </div>
        <div className="col-3">
          <StoreSelector stores={stores} value={storeId} onChange={storeOnChange} />
        </div>
        <div className="col-3">
          <SearchBox value={keyword} onChange={keywordOnChange} />
        </div>
        <div className="col-3">
          <RadioBox id="poStatusPending" value={StatusType.Pending} checked={poStatus === StatusType.Pending}
            label="未審核" onChange={poStatusOnChange} />

          <RadioBox id="poStatusApproved" value={StatusType.Approved} checked={poStatus === StatusType.Approved}
            label="已審核" onChange={poStatusOnChange} />
        </div>
      </section>

      <section>
        {poStatus === StatusType.Pending ? <PolicyPending policies={policies} handlePolicyClick={handlePolicyClick} /> : <PolicyApproved policies={policies} handlePolicyClick={handlePolicyClick} />}
      </section>

      <section className="mt-4">
        <Pagination totalElements={totalPolicies.length} pageSize={pageSize} currPage={currPage} onChange={currPageOnChange} />
      </section>
    </>
  )
}

const PolicyPending: React.FC<{ policies: Policy[], handlePolicyClick: Function }> = ({ policies, handlePolicyClick }) => {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">日期</th>
          <th scope="col">業務員姓名</th>
          <th scope="col">保單編號</th>
          <th scope="col">客戶姓名</th>
          <th scope="col">保費</th>
          <th scope="col">授權書</th>
          <th scope="col">備註</th>
        </tr>
      </thead>
      <tbody>
        {policies.map(policy => {
          const { id, no, stPay, salesName, phName, poPremium, stCcAuth, syMemo } = policy
          const formatDateYMD = (date: string) => moment(date, 'YYYYMMDD').format('YYYY/MM/DD')
          return (
            <tr key={id} onClick={() => handlePolicyClick(id, no, stCcAuth)}>
              <td>{formatDateYMD(stPay)}</td>
              <td>{salesName}</td>
              <td>{no}</td>
              <td>{phName}</td>
              <td>{poPremium}</td>
              <td className="text-center">{stCcAuth ? <i className="material-icons text-primary">task</i> : <i className="material-icons text-danger">upload_file</i>}</td>
              <td style={{ width: '30%' }}>{syMemo}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const PolicyApproved: React.FC<{ policies: Policy[], handlePolicyClick: Function }> = ({ policies, handlePolicyClick }) => {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">日期</th>
          <th scope="col">業務員姓名</th>
          <th scope="col">保單編號</th>
          <th scope="col">客戶姓名</th>
          <th scope="col">保費</th>
          <th scope="col">審核人員</th>
          <th scope="col">審核日期</th>
        </tr>
      </thead>
      <tbody>
        {policies.map(policy => {
          const formatDateYMD = (date: string) => moment(date, 'YYYYMMDD').format('YYYY/MM/DD')
          return (
            <tr key={policy.id} onClick={() => handlePolicyClick(policy.id, policy.no)}>
              <td>{formatDateYMD(policy.stPay)}</td>
              <td>{policy.salesName}</td>
              <td>{policy.no}</td>
              <td>{policy.phName}</td>
              <td>{policy.poPremium}</td>
              <td>{policy.syApproveName}</td>
              <td>{policy.stSyApprove ? formatDateYMD(policy.stSyApprove) : ''}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}