import { service } from '.'

export interface Store { id: number, name: string, code: string, status: boolean, dealerId: number }
export const emptyStore = { id: 0, name: '', code: '', status: true, dealerId: 0 }

class StoreStore {
  private _stores: Store[] = []
  private _store: Store = { ...emptyStore }

  get stores() { return this._stores }
  get store() { return this._store }

  findByDealerId = (dealerId: number) => this._stores.filter(store => store.dealerId === dealerId)
  findByDealerIdAndValid = (dealerId: number) => this.findByDealerId(dealerId).filter(dealer => dealer.status === true)

  loadAll = async () => {
    const res = await service.getStores()
    if (res.status === 200) {
      this._stores = await res.json()
      return Promise.resolve()
    }
    else if(res.status === 401) { }
    else return Promise.reject({ message: `無法取得門市資訊！ 錯誤代碼：${res.status}` })
  }

  loadOne = async (id: number, dealerId: number) => {
    if (id === 0) {
      this._store = { ...emptyStore, dealerId }
      return Promise.resolve()
    }

    const res = await service.getStore(id)
    if (res.status === 200) {
      this._store = await res.json()
      return Promise.resolve()
    }

    return Promise.reject({ message: `無法取得門市資訊！ 錯誤代碼：${res.status}` })
  }

  save = async (store: Store) => {
    const res = await (store.id === 0 ?
      service.addStore(store.dealerId, store.name, store.code) :
      service.updateStore(store.id, store.dealerId, store.name, store.code, store.status))

    if (res.status === 200) return Promise.resolve()
    else if (res.status === 400) return Promise.reject({ message: '無法儲存！ 請檢查「名稱或代碼」是否重複？' })
    else return Promise.reject({ message: `無法儲存！　錯誤代碼：${res.status}` })
  }
}

export const storeStore = new StoreStore()