import { observable, action, makeObservable } from 'mobx'
import { service } from '.'

export enum Role { Empty = '', Admin = 'ROLE_ADMIN', SenYoung = 'ROLE_SENYOUNG', Chubb = 'ROLE_CHUBB' }

interface User { name: string, token: string, role: Role, chgPwd: boolean }
const emptyUser: User = { name: '', token: '', role: Role.Empty, chgPwd: false }
const emptyRoles = { upper: 0, lower: 0, digit: 0, min: 0, special: 0 }

class AuthStore {
  @observable private _isLogin = false
  @observable private _currUser = { ...emptyUser }
  private _pwdRules = emptyRoles
  private _userAccount = ''

  constructor() {
    makeObservable(this)
    const userString = localStorage.getItem('adminUser')
    const userAccount = localStorage.getItem('tkecAccount')
    if (userString && userString !== 'null') {
      this._isLogin = true
      this._currUser = JSON.parse(userString)
    }
    if (userAccount && userAccount !== 'null') this._userAccount = userAccount
  }

  get isLogin() { return this._isLogin }
  get currUser() { return this._currUser }
  get myRole() { return this._currUser.role }
  get pwdRules() { return this._pwdRules }

  get userAccount() { return this._userAccount }  // 如果有登入過並選擇記憶帳號 Email 的話，自動填入帳號 Email，不需重複填入

  @action login = async (account: string, password: string, isPersistAccount?: boolean) => {
    const res = await service.login(account, password)
    if (res.status === 200) {
      const user = await res.json()
      this._isLogin = true
      this._currUser = user
      localStorage.setItem('adminUser', JSON.stringify(user))

      if (isPersistAccount) {
        this._userAccount = account
        localStorage.setItem('tkecAccount', account)
      }
      return Promise.resolve()
    }
    else if (res.status === 401) return Promise.reject({ message: '帳號密碼錯誤！' })
    else if (res.status === 423) return Promise.reject({ message: '帳號已鎖定，請稍後再試！' })
    else return Promise.reject({ message: `無法登入！　錯誤代碼： ${res.status}` })
  }

  @action logout = () => {
    localStorage.removeItem('adminUser')
    this._isLogin = false
    this._currUser = { ...emptyUser }
  }

  @action loadPwdRules = async () => {
    const res = await service.getPwdRule()
    if (res.status === 200) {
      this._pwdRules = await res.json()
      return Promise.resolve()
    }
    return Promise.reject({ message: `無法取得密碼規則！ 錯誤代碼：${res.status}` })
  }

  @action changePassword = async (password: string, newPassword: string) => {
    const res = await service.chgPwd(password, newPassword)
    if (res.status === 200) return Promise.resolve()
    else if (res.status === 400) return Promise.reject({ message: '密碼不正確！' })
    else if (res.status === 409) return Promise.reject({ message: '「新密碼」不符合密碼規則！' })
    else return Promise.reject({ message: `發生錯誤！ 錯誤代碼：${res.status}` })
  }

  @action resetPassword = async (account: string, token: string) => {
    const res = await service.resetPwd(account, token)
    if (res.status === 200) return Promise.resolve()
    else if (res.status === 400) return Promise.reject({ message: '登入 Email 有誤！' })
    else return Promise.reject({ message: `發生錯誤！ 錯誤代碼：${res.status}` })
  }
}

export const authStore = new AuthStore()