import React from 'react'
import styled from 'styled-components'

import { AdminHeader, SideNavbar } from '.'

export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-2">
            <SideNavbar />
          </div>

          <div className="col-9 p-0">
            <main className="mx-auto mt-4">
              {children}
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

const Bar = styled.div`
  padding: 12px 20px;
  margin: 16px 0px;
`

interface Props {
  title: string,
  isLoading?: boolean,
  subTitle?: string,
}

export const TitleBar: React.FC<Props> = ({ title, isLoading = false, subTitle }) => (
  <Bar className="text-white d-flex justify-content-between align-items-center" style={{ backgroundColor: '#6c757d' }}>
    <div className="h5 m-0 flex-grow-1">{title}</div>
    {
      isLoading ?
        <span className="spinner-border spinner-border-sm align-bottom m-1" /> :
        <div className="fs_caption align-self-end">{subTitle}</div>
    }
  </Bar>
)