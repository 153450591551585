import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProtectedRoute } from '../common'
import { Path, Login, Home, PasswordReset, PasswordChange, UploadFail } from './page'
import { NotFound } from '../common/page'

const Module: React.FC = () => {
  return (
    <Switch>
      <Route path={Path.Login} component={Login} />
      <ProtectedRoute path={Path.Home} component={Home} />
      <Route path={Path.PasswordReset} component={PasswordReset} />
      <ProtectedRoute path={Path.PasswordChange} component={PasswordChange} />
      <Route path={Path.UploadFail} component={UploadFail} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Module