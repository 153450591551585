import { Moment } from 'moment'
import { service } from '.'

export interface Policy {
  id: number,
  dealerId: number, storeId: number,
  phName: string, salesName: string,
  stPay: string, stSyApprove: string, syApproveName: string, syMemo: string,
  poPremium: string, no: string, stCcAuth: string,
}

export interface Review { memo: string, approve: boolean }
const emptyReview = { memo: '', approve: false }

export enum StatusType { Pending, Approved }

class PolicyStore {
  private _policies: Policy[] = []
  private _pdf: any
  private _review: Review = { ...emptyReview }

  get policies() { return this._policies }
  get pdf() { return this._pdf }
  get review() { return this._review }

  loadAll = async () => {
    const res = await service.getPoliciesTop2000()
    if (res.status === 200) {
      this._policies = (await res.json()).content
      return Promise.resolve()
    }
    else if(res.status === 401) { }
    else return Promise.reject({ message: `無法取得要保書資訊！ 錯誤代碼：${res.status}` })
  }

  loadByStatus = async (statusType: StatusType) => {
    const res = await (statusType === StatusType.Pending ? service.getUnreviewedPolicyTop2000() : service.getReviewedPoliciesTop2000())
    if (res.status === 200) {
      this._policies = (await res.json()).content
      return Promise.resolve()
    }
    return Promise.reject({ message: `無法取得要保書資訊！ 錯誤代碼：${res.status}` })
  }

  loadPDF = async (id: number) => {
    const res = await service.getPolicyPDF(id)
    if (res.status === 200) {
      this._pdf = new Uint8Array(await res.arrayBuffer())
      return Promise.resolve()
    }
    return Promise.reject({ message: `無法取得要保書 PDF！ 錯誤代碼：${res.status}` })
  }

  loadReview = async (id: number) => {
    const res = await service.getPolicyMemo(id)
    if (res.status === 200) {
      this._review = await res.json()
      return Promise.resolve()
    }
    return Promise.reject({ message: `無法取得要保書 Memo！ 錯誤代碼：${res.status}` })
  }

  saveMemo = async (id: number, memo: string) => {
    const res = await service.updatePolicyMemo(id, memo)
    if (res.status === 200) return Promise.resolve()
    else return Promise.reject({ message: `無法儲存 Memo！ 錯誤代碼：${res.status}` })
  }

  approvePolicy = async (id: number, memo: string) => {
    const res = await service.approvePolicy(id, memo)
    if (res.status === 200) return Promise.resolve()
    if (res.status === 424) {
      const msg = (await res.json()).msg
      return Promise.reject({ message: `無法核可要保書！ 錯誤代碼：${res.status} ${msg}` })
    }
    else return Promise.reject({ message: `無法核可要保書！ 錯誤代碼：${res.status}` })
  }

  // 每日要保書資訊
  loadDailyPolicyInfo = async (date: Moment, filename: string) => {
    const res = await service.getDailyPolicy(date.format('YYYYMMDD'))
    if (res.status === 200) {
      const buffer = await res.arrayBuffer()
      const url = URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      link.click()
      Promise.resolve()
    }
    else if (res.status === 204) return Promise.reject({ message: `無當日要保書資訊！` })
    else return Promise.reject({ message: `無法取得當日要保書資訊！ 錯誤代碼：${res.status}` })
  }

  // 每月要保書資訊
  loadMonthlyPolicyInfo = async (month: string, filename: string) => {
    const res = await service.getMonthlyPolicy(month)
    if (res.status === 200) {
      const buffer = await res.arrayBuffer()
      const url = URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      link.click()
      Promise.resolve()
    }
    else if (res.status === 204) return Promise.reject({ message: `無當月要保書資訊！` })
    else return Promise.reject({ message: `無法取得當月要保書資訊！ 錯誤代碼：${res.status}` })
  }

  downloadPolicyConsentZip = async (id: number, policyNo: string) => {
    const res = await service.getPolicyConsentZip(id)
    if (res.status === 200) {
      const buffer = await res.arrayBuffer()
      const url = URL.createObjectURL(new Blob([buffer]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${policyNo}.zip`)
      link.click()
      Promise.resolve()
    } else return Promise.reject({ message: `無法下載要保書和同意書！ 錯誤代碼：${res.status}` })
  }
}

export const policyStore = new PolicyStore()