import React from 'react'
import styled from 'styled-components'

export const NotFound: React.FC = () => (
  <div className="vh-100 d-flex flex-column align-items-center justify-content-center text-primary">
    <Title className="text-primary">OOPS...</Title>
    <SubTitle className="text-muted">404 - The page cannot be found.</SubTitle>
  </div>
)

const Title = styled.div`
  font-size: 60px;
  @media(max-width: 576px) { font-size: 40px }
`

const SubTitle = styled.div`
  font-size: 20px;
`